import Loader from '@/components/Loader';
import { fadeIn, fadeOut } from '@/utils/Constants';
import { fetchCollectionDoc } from '@/utils/FirebaseMiddleware';
import Bugsnag from '@bugsnag/js';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

const LegalPage = () => {
  const { pathname } = useLocation();
  const [content, setContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const path = useMemo(() => {
    if (pathname.includes('privacy-policy')) {
      return 'privacyPolicy';
    }
    if (pathname.includes('terms-of-use')) {
      return 'termsOfUse';
    }
    return 'cancellationPolicy';
  }, [pathname]);

  useEffect(() => {
    const fetchLegalPage = async () => {
      try {
        setLoading(true);
        const res = await fetchCollectionDoc('legal', path);
        if (res) {
          setContent(
            res.content.replaceAll('<img', '<img style="display: block; max-width: 100%"')
          );
        }
        setLoading(false);
      } catch (error) {
        Bugsnag.notify(new Error(error as string));
        toast.error((error as any).message);
      }
    };

    fetchLegalPage();
  }, [path]);

  const description = () => {
    switch (path) {
      case "privacyPolicy":
        return "Read the Yoga on Sofa app privacy policy. Learn about the types of information collected, how it is used, shared, and protected. Understand your opt-out rights, data retention practices, and how to manage your information."
      case "termsOfUse":
        return "Read the terms of use for the Yoga on Sofa mobile app provided by Prisify - FZCO. Learn about our subscription services, billing, account registration, prohibited conduct, and more. Your use of the app constitutes acceptance of these terms."
      case "cancellationPolicy":
        return "Review our Yoga on Sofa cancellation policy. Cancel anytime with no refunds for used services. Refunds available if you cancel within 14 days without accessing content. Learn more about our terms and conditions."
      default:
        return ""
    }
  }

  if (loading) {
    return (
      <LoaderContainer $isLoaded={Boolean(content?.length)}>
        <Helmet>
          <meta name="description" content={description()} />
        </Helmet>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      <Helmet>
        <meta name="description" content={description()} />
      </Helmet>
      {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
    </Container>
  );
};

export default LegalPage;

const LoaderContainer = styled.div<{ $isLoaded: boolean }>`
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  animation: ${($isLoaded) => ($isLoaded ? fadeOut : fadeIn)} 0.5s linear;
  transition: visibility 0.5s linear;
`;

const Container = styled.div`
  color: rgb(33, 43, 54);
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
`;