import { useMemo } from 'react';
import Slider, { Settings } from 'react-slick';
import styled from 'styled-components';
import useWindowSize from '@/utils/Hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import CustomTypography from './Typography';
import { colors } from '@/utils/Constants';
import Circle from './Circle';
import { CheckedAccent, Checked, CheckedNature } from '@/assets';

const ads = ['messages.minimizeBackPain', 'messages.sayGoodbye', 'messages.getStarted'];

const settings: Settings = {
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 2,
  slidesToScroll: 1,
  vertical: false,
  arrows: false,
};

type Props = {
  isLight?: boolean;
};

const Ads = ({ isLight }: Props) => {
  const { t } = useTranslation();
  const width = useWindowSize();

  const checkLogo = useMemo(() => {
    if (isLight) {
      return Checked;
    }
    if (isLight === undefined) {
      return CheckedNature;
    }
    return CheckedAccent;
  }, [isLight]);

  const renderAds = useMemo(() => {
    const content = ads.map((ad, index) => (
      <Ad key={index}>
        <Circle
          selectedBgColor={isLight ? colors.accent : colors.sand}
          isSelected={true}
          icon={checkLogo}
          width={width < 1024 && width > 600 ? '18px' : '24px'}
          height={width < 1024 && width > 600 ? '18px' : '24px'}
          iconSize={width < 1024 && width > 600 ? '16px' : '24px'}
        />

        <div id="text">
          <CustomTypography
            title={t(ad)}
            color={isLight ? colors.dark : colors.sand}
            type="bodyMMedium"
            style={{ fontSize: width < 1024 && width > 600 ? '11px' : '14px' }}
          />
        </div>
      </Ad>
    ));
    if (width >= 1024) {
      return content;
    }
    if (width <= 1024 && width > 600) {
      return (
        <Carousel {...settings} autoplay={true} autoplaySpeed={4000}>
          {content}
        </Carousel>
      );
    }
    return null;
  }, [isLight, width, t]);

  return width > 600 ? <Container $smallSize={width < 1024}>{renderAds}</Container> : null;
};

export default Ads;

const Container = styled.div<{ $smallSize: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ $smallSize }) => `16px ${$smallSize ? '0px' : '32px'}`};
`;

const Ad = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const Carousel = styled(Slider)`
  width: 100%;
  #text {
    margin-left: 8px;
  }
  .slick-slide {
    text-align: center;
  }
  div > div > div > div {
    display: inline-flex;
    vertical-align: middle;
    text-align: center;
  }
`;
