import React, { useEffect, useState } from 'react';
import { Config, ConfigContextType } from '../Types';
import { toast } from 'react-toastify';
import { fetchCollection, fetchCollectionDoc } from '../FirebaseMiddleware';
import Bugsnag from '@bugsnag/js';

type Props = {
  children: React.ReactNode;
};

export const ConfigContext = React.createContext<ConfigContextType>({
  legal: {
    privacyPolicyUrlWeb: '',
    termsUrlWeb: '',
    cancellationPolicyUrl: '',
  },
  faq: [
    {
      content: '',
      createdAt: null,
      position: 0,
      title: '',
      updatedAt: null,
    },
  ],

  setConfig: () => {},
});

const ConfigContextProvider = ({ children }: Props) => {
  const [config, setConfig] = useState<Config>({
    legal: {
      privacyPolicyUrlWeb: '',
      termsUrlWeb: '',
      cancellationPolicyUrl: '',
    },
    faq: [
      {
        content: '',
        createdAt: null,
        position: 0,
        title: '',
        updatedAt: null,
      },
    ],
  });

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const res = await fetchCollectionDoc('config', 'config');
        const faq = await fetchCollection('faq');
        setConfig(res as Config);
        setConfig((prev) => ({ ...prev, faq }));
      } catch (error) {
        Bugsnag.notify(new Error(error as string));
        toast.error((error as any).message);
      }
    };
    fetchConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ legal: config.legal, faq: config.faq, setConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContextProvider;
