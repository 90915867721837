import { styled } from 'styled-components';

type Props = {
  bgColor: string;
  style?: React.CSSProperties;
  isDashed?: boolean;
  fullWidth?: boolean;
};

const Line = ({ bgColor, style, isDashed, fullWidth }: Props) => {
  return <Container $bgColor={bgColor} $isDashed={isDashed} $fullWidth={fullWidth} style={style} />;
};

export default Line;

const Container = styled.div<{ $bgColor: string; $isDashed?: boolean; $fullWidth?: boolean }>`
  width: ${(props) => (props.$fullWidth ? '100%' : 'auto')};
  height: 1px;
  background-color: ${(props) => props.$bgColor};
  border: ${(props) => (props.$isDashed ? '1px dashed' : 'none')};
`;
