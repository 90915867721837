import { t } from 'i18next';
import { styled } from 'styled-components';
import CustomTypography, { TYPES } from './Typography';
import { Logo } from '@/assets';
import { colors } from '@/utils/Constants';

type Props = {
  width?: string;
  height?: string;
  isColumn?: boolean;
  textType?: keyof typeof TYPES;
  style?: React.CSSProperties;
};

const LogoContainer = ({ width, height, isColumn, textType, style }: Props) => {
  return (
    <Container $isColumn={isColumn} style={style}>
      <img src={Logo} alt="logo" width={width ? width : '56px'} height={height ? height : '56px'} />
      <CustomTypography
        color={colors.sand}
        type={textType ? textType : 'headingS'}
        title={t('titles.yogaOnSofa')}
      />
    </Container>
  );
};

export default LogoContainer;

const Container = styled.div<{ $isColumn?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.$isColumn ? 'column' : 'row')};
  align-items: center;
  gap: 16px;
`;
