import { quiz_WomanMid, quiz_ManMid, ArrowRight } from '@/assets';
import { from } from '@/utils/Constants';
import { QuizPage } from '@/utils/Types';
import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../Button';
import CustomTypography from '../Typography';
import useIsPhone from '@/utils/Hooks/usePhone';

const PhysicalComparisonPage = ({ answers, onSelect }: QuizPage) => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();

  const textValue = useMemo(() => {
    const targetWeight = parseInt(answers.targetWeight, 10);
    const currentWeight = parseInt(answers.currentWeight, 10);
    const target = Math.max(1, Math.abs(currentWeight - targetWeight));
    const measurement =
      answers['measurementSystemMetric'] === 'metric'
        ? t('titles.kgs_lower')
        : t('titles.lbs_lower');
    if (currentWeight === targetWeight) {
      return t('titles.gainedNeutral');
    }
    if (currentWeight > targetWeight) {
      return t('titles.lost', { target, measurement });
    }
    return t('titles.gained', { target, measurement });
  }, [answers, t]);

  const onContinue = () => {
    onSelect('', '');
  };

  return (
    <Stack flexDirection="column" justifyContent="center" alignSelf="center">
      <CustomTypography
        type={isMobile ? 'headingM' : 'headingL'}
        title={t('titles.worriedAboutResults')}
        style={{ textAlign: 'center', lineHeight: isMobile ? '32px' : '40px', marginBottom: '8px' }}
      />
      <CustomTypography
        type="bodyMedium"
        title={textValue}
        style={{ textAlign: 'center', marginTop: '8px' }}
      />
      <Stack
        flexDirection="row"
        justifyContent="center"
        gap="10px"
        marginTop={isMobile ? '24px' : '40px'}
        marginBottom="24px"
      >
        <img src={from[answers.currentBody]?.[answers.gender === 'non-binary' ? 'female' : answers.gender]} width={142} height={229} />
        <img src={ArrowRight} alt="ArrowRight" />
        <img
          src={answers.gender === 'male' ? quiz_ManMid : quiz_WomanMid}
          width={142}
          height={229}
        />
      </Stack>
      <CustomTypography
        type="bodyMedium"
        title={t('titles.seeResults')}
        style={{ textAlign: 'center', marginBottom: '32px' }}
      />
      <CustomButton
        type="filled"
        onClick={onContinue}
        name="buttons.continue"
        style={{ marginBottom: '24px' }}
      />
    </Stack>
  );
};

export default PhysicalComparisonPage;
