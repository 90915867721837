import { Female, Male } from '@/assets';
import { colors } from '@/utils/Constants';
import { QuizPage } from '@/utils/Types';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomTypography from '../Typography';
import YogaCover from '../YogaCover';
import useWindowSize from '@/utils/Hooks/useWindowSize';

const GenderPage = ({ params, onSelect }: QuizPage) => {
  const width = useWindowSize();

  const { t } = useTranslation();
  const handleSelect = (answer: string) => {
    onSelect(params.id, answer);
  };
  return (
    !!width && (
      <Stack height="100%" flex={1}>
        <YogaCover />
        <Content>
          <CustomTypography
            type="headingL"
            title={t(params.question)}
            style={{
              marginBottom: width < 768 ? '20px' : '40px',
              fontSize: width < 768 ? '24px' : '32px',
            }}
          />
          <Stack flexDirection="row" gap="16px" marginBottom={width < 768 ? '20px' : '16px'}>
            <Card onClick={() => handleSelect(params.answers[0].id)}>
              <IconWrapper $bgColor={colors.sandBag}>
                <img src={Male} width="44px" height="44px" />
              </IconWrapper>
              {params.answers[0].title && (
                <CustomTypography
                  type="bodySemibold"
                  title={t(params.answers[0].title)}
                  style={{ textAlign: 'center', padding: '0 8px' }}
                />
              )}
            </Card>
            <Card onClick={() => handleSelect(params.answers[1].id)}>
              <IconWrapper $bgColor={colors.accent}>
                <img src={Female} width="44px" height="44px" />
              </IconWrapper>
              {params.answers[1].title && (
                <CustomTypography
                  type="bodySemibold"
                  title={t(params.answers[1].title)}
                  style={{ textAlign: 'center', padding: '0 8px' }}
                />
              )}
            </Card>
          </Stack>
          <Link onClick={() => handleSelect(params.answers[2].id)}>
            {params.answers[2].title && (
              <CustomTypography
                type="bodyTextLink"
                title={t(params.answers[2].title)}
                color={colors.accentDark}
                style={{ textDecoration: 'underline', textAlign: 'center' }}
              />
            )}
          </Link>
        </Content>
      </Stack>
    )
  );
};

export default GenderPage;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  background-color: ${colors.sand};
`;

const Card = styled.div`
  width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border: 1px solid ${colors.sandDark};
  background-color: ${colors.white};
  border-radius: 24px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    transition: 0.3s;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const IconWrapper = styled.div<{ $bgColor: string }>`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: 24px;
  margin-bottom: 16px;
`;

const Link = styled.div`
  cursor: pointer;
  width: 60%;
  -webkit-tap-highlight-color: transparent;
`;
