import { colors, planCards } from '@/utils/Constants';
import { Stack } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import VerticalLine from '@/components/VerticalLine';
import { SandGift, Illustration3 } from '@/assets';
import { CustomTypography } from '@/components';
import { styled } from 'styled-components';
import { ConfigContext } from '@/utils/Contexts/ConfigContext';
import { useContext } from 'react';
import useIsPhone from '@/utils/Hooks/usePhone';
import Header from './Header';

type Props = {
  planId: string | undefined;
};

const MonthPlan = ({ planId }: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();
  const { legal } = useContext(ConfigContext);
  const plan = planCards.find((plan) => plan.id === planId);

  return (
    <>
      <Header color={colors.sand} />
      <Stack width="100%" height="100%" flexDirection="row" justifyContent="center">
        <Content>
          {!isMobile && <img src={Illustration3} />}
          <Stack mt="24px" mb={isMobile ? '32px' : 0} flex={1}>
            <div
              style={{
                fontSize: '16px',
                lineHeight: '24px',
                fontFamily: 'GeneralSans-Medium',
                color: colors.sand,
              }}
            >
              <Trans
                i18nKey={plan?.durationStringKey}
                components={
                  {
                    duration: plan?.duration,
                  } as any
                }
              />
            </div>
            <CustomTypography
              title={'$' + (plan?.oldPrice || '') || ''}
              color={colors.catchySand}
              type="smallNumber"
              style={{ marginTop: '4px', fontSize: '36px', textDecoration: 'line-through' }}
            />
            <CustomTypography
              title={'$' + (plan?.newPrice || '') || ''}
              color={colors.sand}
              type="smallNumber"
              style={{ marginBottom: '24px' }}
            />
            <Line />
            <Stack mt="24px" flexDirection="row" alignItems="center" gap="6px" mb="24px">
              <img src={SandGift} />
              <CustomTypography
                title={t('titles.freeDiet')}
                color={colors.sand}
                type="bodyMMedium"
              />
            </Stack>
            <Line />
          </Stack>
          <Stack flexDirection="row" justifyContent="center" gap="24px">
            <div
              style={{
                fontSize: '16px',
                color: colors.sand,
                fontFamily: 'GeneralSans-Regular',
              }}
            >
              <Trans
                i18nKey={'titles.poweredByStripe'}
                components={
                  {
                    strong: (
                      <span
                        style={{
                          color: colors.sand,
                          fontFamily: 'GeneralSans-Semibold',
                          fontSize: 16,
                        }}
                      ></span>
                    ),
                    stripe: t('titles.stripe'),
                  } as any
                }
              />
            </div>
            <VerticalLine bgColor={colors.sand} height="100%" />
            <TextWrapper target="_blank" href={legal.termsUrlWeb}>
              <CustomTypography title={t('titles.terms')} color={colors.sand} type="bodyRegular" />
            </TextWrapper>
            <TextWrapper target="_blank" href={legal.privacyPolicyUrlWeb}>
              <CustomTypography
                title={t('titles.privacy')}
                color={colors.sand}
                type="bodyRegular"
              />
            </TextWrapper>
          </Stack>
        </Content>
      </Stack>
    </>
  );
};

export default MonthPlan;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 320px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border: 1px dashed ${colors.lightShadow};
`;

const TextWrapper = styled.a`
  cursor: pointer;
  text-decoration: none;
  :hover {
    transition: 0.3s;
    text-decoration: underline;
  }
`;
