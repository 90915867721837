import { Stack } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomTypography from '../Typography';
import Line from '../Line';
import { ROUTES, colors, planCards } from '@/utils/Constants';
import { generatePath, useNavigate } from 'react-router-dom';
import useLocalStorage from '@/utils/Hooks/useLocalStorage';
import Toast from '../Toast';
import Header from './Header';
import { PayPalButtons, FUNDING, PayPalButtonsComponentProps } from '@paypal/react-paypal-js';
import { doc, onSnapshot } from 'firebase/firestore';
import { Elements } from '@stripe/react-stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { getCurrentUser } from '@/utils/AuthMiddleware';
import Bugsnag from '@bugsnag/js';
import { db } from '@/utils/firebaseInit';
import { trackMetaPixel } from '@/utils/Tracking';

type Props = {
  planId: string;
  setLoading: (loading: boolean) => void;
};

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string);

const PaymentForm = ({ planId, setLoading }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const functions = getFunctions();

  const [user, setUser] = useState<User | null>(null);
  const auth = getAuth();

  const [stripe, setStripe] = useState<Stripe | null>(null);

  const { email, paid } = useLocalStorage();
  const plan = planCards.find((plan) => plan.id === planId);
  const unsubscribeRef = useRef<(() => void) | null>(null);

  const [paymentError, setPaymentError] = useState<string | null>(null);

  const trackConversion = () => {
    const value = parseFloat(plan?.newPrice || '0');
    const title = t(plan?.durationStringKey || '', { duration: plan?.duration });
    const id = plan?.id;
    trackMetaPixel(true, 'Purchase', {
      value,
      currency: 'USD',
      content_ids: [id],
      content_name: title,
      content_type: 'product',
    });
    trackMetaPixel(true, 'Subscribe', {value, currency: 'USD'});
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authenticatedUser) => {
      if (authenticatedUser) {
        // User is signed in.
        setUser(authenticatedUser);
      } else {
        // User is signed out.
        setUser(null);
      }
    });
    return () => unsubscribe(); // Cleanup subscription
  }, []);

  const priceContainer = useMemo(() => {
    return (
      <Stack flexDirection="column" justifyContent="center" alignItems="center">
        <div
          style={{
            fontSize: '16px',
            lineHeight: '24px',
            fontFamily: 'GeneralSans-Medium',
            color: colors.dark,
          }}
        >
          <Trans
            i18nKey={plan?.durationStringKey}
            components={
              {
                duration: plan?.duration,
              } as any
            }
          />
        </div>
        <Stack flexDirection="row" gap="8px">
          <CustomTypography
            title={'$' + (plan?.oldPrice || '') || ''}
            color={colors.error}
            type="headingSMedium"
            style={{ textDecoration: 'line-through' }}
          />
          <CustomTypography
            title={'$' + (plan?.newPrice || '') || ''}
            color={colors.dark}
            type="headingS"
          />
        </Stack>
      </Stack>
    );
  }, []);

  useEffect(() => {
    // Load the Stripe object asynchronously and set it in state
    const loadStripeAsync = async () => {
      const stripe = await stripePromise;
      setStripe(stripe);
    };

    loadStripeAsync();
  }, []);

  const onSubmit = async (stripe: Stripe, elements: StripeElements) => {
    setPaymentError(null);

    try {
      await elements.submit();

      const callable = httpsCallable<{}, { data: { clientSecret: string; type: string } }>(
        functions,
        'stripe-createSubscription'
      );
      const result = await callable({ email, priceId: plan?.stripePlanId });
      // console.log('createSubscription result', result);
      const { type, clientSecret } = result.data.data;

      const currentUrl = window.location.href;
      const returnUrl = new URL(currentUrl);
      returnUrl.searchParams.set('paid', 'true');

      const confirmIntent = type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

      // Confirm the Intent using the details collected by the Payment Element
      const { error } = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: {
          return_url: returnUrl.toString(),
        },
      });
      if (error?.type !== 'validation_error') {
        console.log('payment error', error);
        setPaymentError(error.message || t('errors.payment'));
      }
    } catch (error) {
      console.log(error);
      Bugsnag.notify(new Error(error as string));
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isPaid = queryParams.get('paid');
    if (isPaid === 'true') {
      console.log('Stripe payment was successful.');
      if (localStorage.getItem('paid') !== 'true') { 
        // check if it was not yet marked as paid, so conversion gets tracked only once
        trackConversion();
      }
      localStorage.setItem('paid', 'true');
    }
  }, [location]);

  const listenForSubscriptionChange = (uid: string, setLoading: (loading: boolean) => void) => {
    const subscriberRef = doc(db, 'users', uid);
    return onSnapshot(
      subscriberRef,
      (doc) => {
        if (doc.exists()) {
          // console.log("Current data: ", doc.data());
          const subscriptionPayPal = doc.data().subscriptionPayPal;
          const subscriptionStripe = doc.data().subscriptionStripe;
          if (subscriptionPayPal || subscriptionStripe) {
            // console.log("subscriptionPayPal: ", subscriptionPayPal);
            // console.log("subscriptionStripe: ", subscriptionStripe);
            setLoading(false); // Set loading to false when the field appears
            const url = generatePath(ROUTES.REGISTRATION, {
              planId,
            });
            navigate(url);
          }
        } else {
          console.log('No such document!');
          setLoading(false);
        }
      },
      (error) => {
        console.error('Error fetching document: ', error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (paid) {
      const uid = user?.uid;
      if (uid) {
        setLoading(true);
        const unsubscribe = listenForSubscriptionChange(uid, setLoading);
        return () => unsubscribe(); // Cleanup function to unsubscribe when component unmounts or paid changes
      }
    }
  }, [paid, setLoading, user]);

  const createSubscription: PayPalButtonsComponentProps['createSubscription'] = (_, actions) => {
    // console.log('createSubscription', data);
    return actions.subscription.create({
      plan_id: plan?.paypalPlanId,
      custom_id: getCurrentUser()?.uid,
    });
  };

  const onApprove: PayPalButtonsComponentProps['onApprove'] = async (_) => {
    // console.log('onApprove', data);
    setLoading(true);
    localStorage.setItem('paid', 'true');

    const uid = getCurrentUser()?.uid;
    if (!uid) {
      alert('No user logged in');
      setLoading(false);
      return;
    }

    trackConversion();

    unsubscribeRef.current = listenForSubscriptionChange(uid, setLoading);
  };

  useEffect(() => {
    // Cleanup function to unsubscribe when component unmounts or uid changes
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, []);

  const onCancel = () => {
    setLoading(false);
    console.log('Subscription cancelled.');
  };

  const onError = (error: Record<string, any>) => {
    setLoading(false);
    console.error('PayPal Button Error:', error);
    setPaymentError(error.message);
  };

  return (
    <Container
      sx={{
        '@media (min-width: 768px)': {
          maxWidth: 420,
          width: '100%',
        },
        '@media (max-width: 768px)': {
          width: '100%',
        },
      }}
    >
      <Stack
        sx={{
          '@media (min-width: 768px)': {
            display: 'none',
          },
          '@media (max-width: 768px)': {
            display: 'flex',
          },
        }}
      >
        <Header color={colors.dark} content={priceContainer} />
      </Stack>
      <Stack gap="16px" height="100%" flex={1}>
        <PayPalButtons
          style={{ shape: 'pill', height: 55 }}
          fundingSource={FUNDING.PAYPAL}
          createSubscription={createSubscription}
          onApprove={onApprove}
          onCancel={onCancel}
          onError={onError}
        />
        <Stack flexDirection="row" alignItems="center">
          <Line bgColor={colors.sandDark} style={{ flex: 1 }} />
          <CustomTypography
            title={t('titles.payCard')}
            type="bodyMRegular"
            style={{ paddingLeft: '16px', paddingRight: '16px' }}
          />
          <Line bgColor={colors.sandDark} style={{ flex: 1 }} />
        </Stack>
        <Form>
          {stripe && (
            <Elements
              stripe={stripe}
              options={{
                mode: 'subscription',
                amount: Math.round(parseFloat(plan?.newPrice || '0') * 100),
                currency: 'usd',
                appearance: {
                  variables: {
                    borderRadius: '20px',
                    fontSizeBase: '14px',
                    colorText: colors.dark,
                    colorTextPlaceholder: colors.shadow,
                  },
                  rules: {
                    '.Input': {
                      paddingLeft: '22px',
                      paddingRight: '22px',
                      paddingTop: '19px',
                      paddingBottom: '19px',
                      borderColor: colors.sandDark,
                    },
                  },
                },
              }}
            >
              <StripeCheckoutForm onSubmit={onSubmit} />
            </Elements>
          )}
          {paymentError && (
            <Toast
              title={paymentError}
              fontType="bodyMRegular"
              color={colors.white}
              type="filled"
            />
          )}
        </Form>
      </Stack>
    </Container>
  );
};

export default PaymentForm;

const Container = styled(Stack)`
  padding: 20px;
  @media (max-width: 768px) {
    min-height: 100vh;
  }
`;

const Form = styled(Stack)`
  gap: 12px;
  flex: 1;
`;
