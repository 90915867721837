import { styled } from 'styled-components';
import { Stack } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { BUTTON_TYPES, colors, planCards } from '@/utils/Constants';
import { Gift } from '@/assets';
import CustomTypography from './Typography';
import CustomButton from './Button';
import useIsPhone from '@/utils/Hooks/usePhone';

type Props = {
  id: string;
  onGetMyPlan: (id: string) => void;
  type: keyof typeof BUTTON_TYPES;
};

const PlanCard = ({ id, onGetMyPlan, type }: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();

  const parseHTML = (html: string) => ({ __html: html });

  const plan = planCards.find((plan) => plan.id === id);

  return (
    <Container $borderColor={plan?.borderColor || ''} onClick={() => onGetMyPlan(id)}>
      <Stack flexDirection="row" gap="4px" mb={isMobile ? '8px' : '16px'}>
        <Stack flexDirection="column" flex={2}>
          <div
            style={{
              fontSize: isMobile ? 18 : 32,
              lineHeight: isMobile ? '24px' : '40px',
              fontFamily: 'GeneralSans-Semibold',
              marginBottom: '4px',
            }}
          >
            <Trans
              i18nKey={plan?.durationStringKey}
              components={
                {
                  duration: plan?.duration,
                } as any
              }
            />
          </div>

          <Stack flexDirection="row" gap="8px" alignItems="center">
            <div
              style={{
                color: colors.error,
                textDecoration: 'line-through',
                fontSize: '18px',
                fontFamily: 'GeneralSans-Semibold',
              }}
            >
              ${plan?.oldPrice}
            </div>
            <div
              style={{ fontSize: isMobile ? '18px' : '24px', fontFamily: 'GeneralSans-Semibold' }}
            >
              ${plan?.newPrice}
            </div>
          </Stack>
        </Stack>
        {isMobile && (
          <CustomButton
            name={t('buttons.getMyPlan')}
            type={type}
            style={{ width: '100%', flex: 2 }}
          />
        )}
      </Stack>
      <Line />
      <Stack flexDirection="row" mt={isMobile ? '4px' : '16px'} mb={isMobile ? '4px' : '16px'}>
        <div
          style={{ fontSize: '16px', color: colors.dark, fontFamily: 'GeneralSans-Regular' }}
          dangerouslySetInnerHTML={parseHTML(
            t('titles.pricePerDay', {
              price: plan?.newPlanPrice,
              oldPrice: plan?.oldPlanPrice,
              unit: '$',
            })
          )}
        ></div>
      </Stack>
      <Line />
      <Stack flexDirection="row" alignItems="center" mt={isMobile ? '4px' : '16px'} gap="6px">
        <img src={Gift} />
        <CustomTypography title={t('titles.freeDiet')} type="bodyMMedium" color={colors.dark} />
      </Stack>
      {!isMobile && (
        <CustomButton
          name={t('buttons.getMyPlan')}
          style={{ width: '100%', marginTop: '24px' }}
          type={type}
        />
      )}
    </Container>
  );
};

export default PlanCard;

const Container = styled.div<{ $borderColor: string }>`
  border: 2px solid ${({ $borderColor }) => $borderColor};
  border-radius: 24px;
  padding: 32px 24px;
  background-color: ${colors.white};
  width: 100%;
  @media (max-width: 768px) {
    padding: 16px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border: 1px dashed ${colors.lightShadow};
`;
