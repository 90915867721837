import { colors } from '@/utils/Constants';
import React, { ChangeEvent, useEffect } from 'react';
import { styled } from 'styled-components';

type Props = {
  measurement: 'ft' | 'cm' | 'kg' | 'lbs' | 'in' | 'age';
  onChange: (e: any, unitType: 'cm' | 'ft' | 'in' | 'kg' | 'lbs' | 'age') => void;
  inputValue: string;
  error?: string;
  setFocused?: (value: boolean) => void;
  focused: boolean;
  handleTags: (
    evt: any,
    measurement: 'ft' | 'cm' | 'kg' | 'lbs' | 'in' | 'age',
    inputValue?: string
  ) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  validate?: (inputValue: string, unitType: 'cm' | 'ft' | 'in' | 'kg' | 'lbs' | 'age') => boolean;
};

const InputCounter = ({
  measurement,
  inputValue,
  onChange,
  error,
  setFocused,
  handleTags,
  inputRef,
  focused,
  validate,
}: Props) => {
  useEffect(() => {
    if (inputValue && !focused && validate) {
      validate(inputValue, measurement);
    }
  }, [focused, inputValue, measurement]);

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    if (measurement === 'ft' && e.target.value.length >= 2) {
      e.preventDefault();
      return;
    }
    if ((measurement === 'in' || measurement === 'age') && e.target.value.length > 2) {
      e.preventDefault();
      return;
    }
    onChange(e, measurement);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const regExp = new RegExp(/\D/, 'g');

    if (regExp.test(event.key) || event.key.length > 3) {
      event.preventDefault();
    }
  };

  return (
    <Controls>
      <TextInput
        error={error}
        ref={inputRef}
        inputMode="numeric"
        onKeyDown={(e) => handleTags(e, measurement, inputValue)}
        onKeyPress={handleKeyPress}
        type="number"
        value={inputValue}
        onWheel={(e) => e.currentTarget.blur()}
        onChange={onChangeText}
        maxLength={3}
        placeholder={measurement === 'age' ? '--' : '0'}
        style={{ maxWidth: measurement === 'ft' || measurement === 'in' ? '100px' : '150px' }}
        onFocus={() => {
          if (setFocused) {
            setFocused(true);
          }
        }}
        onBlur={() => {
          if (setFocused) {
            setFocused(false);
          }
        }}
      />
    </Controls>
  );
};

export default InputCounter;

const TextInput = styled.input<{ error?: string }>`
  max-width: 100px;
  font-size: 88px;
  width: 100%;
  text-align: center;
  color: ${({ error }) => (error ? colors.error : colors.sand)};
  background-color: transparent;
  border: none;
  border-bottom: 2px solid ${colors.accentDark};
  border-radius: 0;
  font-family: GeneralSans-Medium;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  &::placeholder {
    color: ${colors.sand};
    font-family: GeneralSans-Medium;
    font-size: 88px;
    text-align: center;
    opacity: 0.3;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 768px) {
    max-width: 180px;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  @media (max-width: 320px) {
    gap: 6px;
  }
`;
