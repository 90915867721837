import { BackgroundNature, BackgroundNatureMobileLight } from '@/assets';
import SubHeader from '@/components/SubHeader';
import { colors, fadeIn, fadeOut } from '@/utils/Constants';
import useIsPhone from '@/utils/Hooks/usePhone';
import { styled } from 'styled-components';
import NatureBackground from './NatureBackground';
import useWindowSize from '@/utils/Hooks/useWindowSize';

type Props = {
  children: React.ReactNode;
  maxWidth: string;
  style?: React.CSSProperties;
};

const Wrapper = ({ children, maxWidth, style }: Props) => {
  const isMobile = useIsPhone();
  const width = useWindowSize();

  return (
    <Container $isLoaded={Boolean(width)} style={style}>
      <SubHeader showRate={false} />
      {isMobile && <NatureBackground backgroundImage={BackgroundNatureMobileLight} />}
      <Content $maxWidth={maxWidth}>{children}</Content>
      {!isMobile && (
        <img src={BackgroundNature} width="100%" style={{ position: 'absolute', bottom: 0 }} />
      )}
    </Container>
  );
};

export default Wrapper;

const Container = styled.div<{ $isLoaded: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background-color: ${colors.sand};
  visibility: ${($isLoaded) => (!$isLoaded ? 'hidden' : 'visible')};
  animation: ${($isLoaded) => (!$isLoaded ? fadeOut : fadeIn)} 0.5s linear;
  transition: visibility 0.5s linear;
  @media (min-width: 768px) {
    min-height: 100vh;
  }
`;

const Content = styled.div<{ $maxWidth: string }>`
  position: relative;
  justify-content: center;
  z-index: 4;
  display: flex;
  max-width: ${({ $maxWidth }) => $maxWidth};
  position: relative;
  width: 100%;
`;
