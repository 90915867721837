import { Logo } from '@/assets';
import { colors } from '@/utils/Constants';
import { t } from 'i18next';
import styled from 'styled-components';
import CustomTypography from './Typography';
import ExcellentTrustpilot from './ExcellentTrustpilot';
import useIsPhone from '@/utils/Hooks/usePhone';

// uncomment and add component in UI, when bringing back the language picker
// import SelectLanguage from './SelectLanguage';

type Props = {
  isLight?: boolean;
  showRate?: boolean;
};

const SubHeader = ({ isLight, showRate = true }: Props) => {
  const isMobile = useIsPhone();

  return (
    <Container $isLight={isLight}>
      <LeftSection $showRate={showRate}>
        <LogoContainer $isMobile={isMobile} $showRate={showRate}>
          <img src={Logo} alt="logo" width="56px" height="56px" />
          <CustomTypography
            color={isLight ? colors.dark : colors.sand}
            type="headingS"
            title={t('titles.yogaOnSofa')}
            style={{ fontSize: isMobile ? '14px' : '18px' }}
          />
        </LogoContainer>
      </LeftSection>
      {showRate && !isMobile && (
        <>
          <CenterSection>
            <Rate $isMobile={isMobile}>
              <ExcellentTrustpilot isLight={isLight} />
            </Rate>
          </CenterSection>
          <RightSection />
        </>
      )}
    </Container>
  );
};

export default SubHeader;

const Container = styled.div<{ $isLight?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  border-bottom: ${({ $isLight }) => `1px solid ${$isLight ? colors.sandDark : colors.sand}`};
  @media (max-width: 768px) {
    padding: 12px 20px;
  }
`;

const LeftSection = styled.div<{ $showRate: boolean }>`
  display: flex;
  flex: ${({ $showRate }) => ($showRate ? '1' : 'unset')};
  justify-content: flex-start;
`;

const CenterSection = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

const RightSection = styled.div`
  display: flex;
  flex: 1;
`;

const LogoContainer = styled.div<{ $isMobile: boolean, $showRate: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ $isMobile }) => ($isMobile ? '12px' : '16px')};
  padding-right: ${({ $showRate }) => ($showRate ? '0' : '32')}px;
  max-width: 250px;

  ${({ $isMobile }) =>
    $isMobile &&
    `
    img {
      width: 40px;
      height: 40px;
    }
  `}
`;

const Rate = styled.div<{ $isMobile: boolean }>`
  display: ${({ $isMobile }) => ($isMobile ? 'none' : 'flex')};
  @media (max-width: 950px) {
    display: none;
  },
`;
