import { colors, planCards } from '@/utils/Constants';
import { useContext, useMemo, useState } from 'react';
import { css, keyframes, styled } from 'styled-components';
import CustomTypography from '../Typography';
import { Trans, useTranslation } from 'react-i18next';
import CutOut from '../CutOut';
import { ConfigContext } from '@/utils/Contexts/ConfigContext';
import { Stack } from '@mui/material';
import { Plus, Minus } from '@/assets';
import parse, { DOMNode, Element } from 'html-react-parser';
import Svg from '../Svg';
import ExcellentTrustpilot from '../ExcellentTrustpilot';
import CustomButton from '../Button';
import { scrollToView } from '@/utils/Helper';
import PulseWrapper from '../PulseWrapper';
import useIsPhone from '@/utils/Hooks/usePhone';
import NatureBackground from '../NatureBackground';

type Props = {
  title: string;
  content: string;
};

const isElement = (domNode: DOMNode): domNode is Element => {
  const isTag = domNode.type === 'tag';
  const hasAttributes = (domNode as Element).attribs !== undefined;

  return isTag && hasAttributes;
};

const Item = ({ title, content }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openItem = () => {
    if (isOpen === null) {
      setIsOpen(true);
      return;
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <QuestionRow onClick={openItem}>
      <Stack flexDirection="row" justifyContent="space-between" width="100%">
        <CustomTypography
          type="bodySemibold"
          title={title}
          style={{
            color: colors.dark,
          }}
        />
        <PlusIcon>
          <Svg
            svgImage={isOpen ? <Minus /> : <Plus />}
            width="24px"
            height="24px"
            fill={colors.accentDark}
          />
        </PlusIcon>
      </Stack>

      {isOpen && (
        <HtmlContent $isOpen={isOpen}>
          {parse(content, {
            replace: (domNode) => {
              if (isElement(domNode) && domNode.name === 'img') {
                return (
                  <img
                    src={domNode.attribs.src}
                    alt={domNode.attribs.alt}
                    width={'150px'}
                    height={domNode.attribs.height}
                  />
                );
              }

              return domNode;
            },
          })}
        </HtmlContent>
      )}
    </QuestionRow>
  );
};

type PropsFaq = {
  onGetMyPlan: (id: string) => void;
};

const Faq = ({ onGetMyPlan }: PropsFaq) => {
  const isMobile = useIsPhone();
  const { t } = useTranslation();
  const { faq, legal } = useContext(ConfigContext);

  const questions = useMemo(() => {
    return faq.map((question, index) => {
      return <Item key={index} {...question} />;
    });
  }, [faq]);

  return (
    <Container $isMobile={isMobile}>
      <CutOut bgColor={colors.accent} />
      <CustomTypography
        type={isMobile ? 'generalSansSmallSemibold' : 'generalSansMiddleSemibold'}
        title={t('titles.frequently')}
        style={{
          textAlign: 'center',
          lineHeight: isMobile ? '40px' : '56px',
          color: colors.dark,
        }}
      />
      <Content $isMobile={isMobile}>
        <Stack width="100%" gap="12px" pb={isMobile ? '56px' : '120px'}>
          {questions}
        </Stack>
        <CustomTypography
          type={isMobile ? 'generalSansSmallSemibold' : 'generalSansMiddleSemibold'}
          title={t('titles.moneyBack')}
          style={{
            textAlign: 'center',
            lineHeight: isMobile ? '40px' : '56px',
            paddingBottom: '32px',
            color: colors.dark,
          }}
        />
        <CustomTypography
          type="bodyMedium"
          title={t('titles.weBelieve')}
          style={{
            paddingBottom: '12px',
            color: colors.dark,
            zIndex: 6,
            position: 'relative',
            textAlign: 'center',
          }}
        />
        <div
          style={{
            fontSize: '16px',
            color: colors.dark,
            fontFamily: 'GeneralSans-Medium',
            textAlign: 'center',
            paddingBottom: isMobile ? '56px' : '40px',
            zIndex: 6,
          }}
        >
          <Trans
            i18nKey={'titles.findMore'}
            components={
              {
                lnk: (
                  <a
                    target="_blank"
                    href={legal.cancellationPolicyUrl}
                    style={{ color: colors.accentDark, marginLeft: '2px', marginRight: '2px' }}
                  ></a>
                ),
              } as any
            }
          />
        </div>
        <PulseWrapper
          isLight={false}
          style={isMobile ? { width: '100%', zIndex: 5 } : { zIndex: 5 }}
        >
          <CustomButton
            name={t('buttons.getMyPlan')}
            onClick={() => {
              onGetMyPlan(planCards[0].id);
              scrollToView('plansContainer');
            }}
            isLightTheme={false}
            style={isMobile ? { width: '100%', zIndex: 99 } : { width: '400px', zIndex: 99 }}
          />
        </PulseWrapper>
        <div style={{ marginTop: '32px', width: '100%', zIndex: 9 }}>
          <ExcellentTrustpilot isLight={true} border={true} />
        </div>
      </Content>
      <NatureBackground />
    </Container>
  );
};

export default Faq;

const Container = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  position: relative;
  background-color: ${colors.sand};
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  padding-top: ${({ $isMobile }) => ($isMobile ? '56px' : '72px')};
  margin-top: -40px;
`;

const Content = styled.div<{ $isMobile: boolean }>`
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  padding: ${({ $isMobile }) => ($isMobile ? '72px 20px 56px 20px' : '70px 20px 66px 20px')};
`;

const QuestionRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-color: ${colors.white};
  border: 1px solid ${colors.sandDark};
  border-radius: 24px;
  padding: 16px;
  gap: 8px;
  -webkit-tap-highlight-color: transparent;
`;

const PlusIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    transition: 0.3s;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0.8;
  }
`;

const opacityIn = keyframes`
    0% {
        opacity: 0;
        display: none;
        
    }
    100% {
        opacity: 1;
        display: flex;
        
    }
`;

const displayNone = keyframes`
0% {
  display: none;
}
100% {
  display: none;
}
`;

const HtmlContent = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: GeneralSans-Medium;
  font-size: 14px;
  font-weight: 500;
  animation: ${({ $isOpen }) =>
    css`
      ${$isOpen ? opacityIn : displayNone} 0.7s forwards;
    `};
`;
