import { colors } from '@/utils/Constants';
import { styled } from 'styled-components';
import CustomTypography, { TYPES } from './Typography';

type Props = {
  title: string;
  style?: React.CSSProperties;
  color?: string;
  fontType?: keyof typeof TYPES;
  type?: 'filled' | 'outlined';
};

const Toast = ({ title, style, color, fontType, type }: Props) => {
  const TYPES = {
    filled: {
      padding: '12px',
      borderRadius: '16px',
      backgroundColor: colors.error,
    },
    outlined: {},
  };

  return (
    <Container style={{ ...(type && TYPES[type]), ...style }}>
      <CustomTypography
        title={title}
        type={fontType || 'bodyMMedium'}
        color={color || colors.error}
      />
    </Container>
  );
};

export default Toast;

const Container = styled.div`
  width: 100%;
`;
