import { BUTTON_TYPES, colors } from '@/utils/Constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { CircularProgress } from '@mui/material';

type Props = {
  style?: any;
  name: string;
  onClick?: () => Promise<void> | void;
  isLightTheme?: boolean;
  disabled?: boolean;
  border?: string;
  type?: keyof typeof BUTTON_TYPES;
  showBMICard?: boolean;
};

const CustomButton = ({
  style,
  name,
  onClick,
  isLightTheme,
  disabled = false,
  type,
  showBMICard,
}: Props) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleClick = async () => {
    if (!onClick) return;
    setIsLoading(true);
    try {
      await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  const hoverStyle = {
    boxShadow: isHover ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '',
    opacity: isHover ? 0.8 : 1,
    transition: '0.3s',
  };

  return (
    <Button
      disabled={disabled || isLoading}
      $disabled={disabled || isLoading}
      $showBMICard={showBMICard}
      $isLightTheme={isLightTheme}
      style={{
        ...(type && BUTTON_TYPES[type]),
        ...hoverStyle,
        ...style,
      }}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isLoading ? (
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            verticalAlign: 'middle',
            color: colors.white,
          }}
          size={20}
          thickness={4}
        />
      ) : (
        t(name)
      )}
    </Button>
  );
};

export default CustomButton;

const Button = styled.button<{
  $isLightTheme?: boolean;
  $disabled?: boolean;
  $showBMICard?: boolean;
}>`
  background-color: ${({ $isLightTheme }) =>
    $isLightTheme ? colors.accentDark : colors.catchySand};
  font-size: 16px;
  font-family: GeneralSans-Semibold;
  text-transform: none;
  border-radius: 24px;
  border: none;
  padding: 16px;
  color: ${colors.white};
  cursor: pointer;
  line-height: 24px;
  -webkit-tap-highlight-color: transparent;
  transition: transform 1s;
  transform: ${({ $showBMICard }) => ($showBMICard ? 'translateY(50%)' : 'translateY(0)')};
`;
