import { colors } from '@/utils/Constants';
import { styled } from 'styled-components';
import LogoContainer from '../Logo';
import { useTranslation } from 'react-i18next';
import CustomTypography from '../Typography';
import { Stack } from '@mui/material';
import { ConfigContext } from '@/utils/Contexts/ConfigContext';
import { useContext } from 'react';
import Line from '../Line';
import useWindowSize from '@/utils/Hooks/useWindowSize';

const Footer = () => {
  const { t } = useTranslation();
  const { legal } = useContext(ConfigContext);
  const width = useWindowSize();

  return (
    <Container>
      {width > 1280 && <LogoContainer />}
      <Policies>
        <TextWrapper target="_blank" href={legal.termsUrlWeb}>
          <CustomTypography
            type="bodyMedium"
            color={colors.sand}
            title={t('titles.termsAndConditions')}
          />
        </TextWrapper>
        {width < 1280 ? <Line bgColor={colors.sand} fullWidth /> : <VerticalLine />}
        <TextWrapper target="_blank" href={legal.privacyPolicyUrlWeb}>
          <CustomTypography
            type="bodyMedium"
            color={colors.sand}
            title={t('titles.privacyAndCookies')}
          />
        </TextWrapper>
        {width < 1280 ? <Line bgColor={colors.sand} fullWidth /> : <VerticalLine />}
        <TextWrapper target="_blank" href={legal.cancellationPolicyUrl}>
          <CustomTypography
            type="bodyMedium"
            color={colors.sand}
            title={t('titles.cancellationsPolicy')}
          />
        </TextWrapper>
        {width < 1280 ? <Line bgColor={colors.sand} fullWidth /> : <VerticalLine />}
        <TextWrapper href="mailto:support@yogaonsofa.com">
          <CustomTypography color={colors.sand} type="bodyMedium" title={t('titles.contactUs')} />
        </TextWrapper>
      </Policies>
      {width < 1280 && <LogoContainer isColumn style={{ padding: '48px 0 32px 0' }} />}
      <Stack flexDirection="row" alignItems="center" justifyContent="center">
        <CustomTypography
          color={colors.sand}
          type="bodyMRegular"
          title={t('titles.reserver', { year: new Date().getFullYear() })}
        />
      </Stack>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.nature};
  padding: 40px 32px;
  @media (max-width: 1280px) {
    flex-direction: column;
    padding: 40px 20px 24px 20px;
  }
`;

const Policies = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${colors.sand};
`;

const TextWrapper = styled.a`
  cursor: pointer;
  text-decoration: none;
  :hover {
    transition: 0.3s;
    text-decoration: underline;
  }
`;
