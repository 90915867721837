export const kgsRange = [30, 150]; // In kg's
export const lbsRange = [66, 331]; // In lbs

export const cmsRange = [122, 242]; // In cm's
export const ftsRange = [4, 7];

export const cmsToFeet: Record<string, any> = {
  '0': '0\'0"',
  '1': '0\'0"',
  '2': '0\'1"',
  '3': '0\'1"',
  '4': '0\'2"',
  '5': '0\'2"',
  '6': '0\'2"',
  '7': '0\'3"',
  '8': '0\'3"',
  '9': '0\'4"',
  '10': '0\'4"',
  '11': '0\'4"',
  '12': '0\'5"',
  '13': '0\'5"',
  '14': '0\'6"',
  '15': '0\'6"',
  '16': '0\'6"',
  '17': '0\'7"',
  '18': '0\'7"',
  '19': '0\'7"',
  '20': '0\'8"',
  '21': '0\'8"',
  '22': '0\'9"',
  '23': '0\'9"',
  '24': '0\'9"',
  '25': '0\'10"',
  '26': '0\'10"',
  '27': '0\'11"',
  '28': '0\'11"',
  '29': '0\'11"',
  '30': '0\'11"',
  '31': '1\'0"',
  '32': '1\'1"',
  '33': '1\'1"',
  '34': '1\'1"',
  '35': '1\'2"',
  '36': '1\'2"',
  '37': '1\'3"',
  '38': '1\'3"',
  '39': '1\'3"',
  '40': '1\'4"',
  '41': '1\'4"',
  '42': '1\'5"',
  '43': '1\'5"',
  '44': '1\'5"',
  '45': '1\'6"',
  '46': '1\'6"',
  '47': '1\'7"',
  '48': '1\'7"',
  '49': '1\'7"',
  '50': '1\'8"',
  '51': '1\'8"',
  '52': '1\'8"',
  '53': '1\'9"',
  '54': '1\'9"',
  '55': '1\'10"',
  '56': '1\'10"',
  '57': '1\'10"',
  '58': '1\'11"',
  '59': '1\'11"',
  '60': '1\'11"',
  '61': '2\'0"',
  '62': '2\'0"',
  '63': '2\'1"',
  '64': '2\'1"',
  '65': '2\'2"',
  '66': '2\'2"',
  '67': '2\'2"',
  '68': '2\'3"',
  '69': '2\'3"',
  '70': '2\'4"',
  '71': '2\'4"',
  '72': '2\'4"',
  '73': '2\'5"',
  '74': '2\'5"',
  '75': '2\'6"',
  '76': '2\'6"',
  '77': '2\'6"',
  '78': '2\'7"',
  '79': '2\'7"',
  '80': '2\'7"',
  '81': '2\'8"',
  '82': '2\'8"',
  '83': '2\'9"',
  '84': '2\'9"',
  '85': '2\'9"',
  '86': '2\'10"',
  '87': '2\'10"',
  '88': '2\'11"',
  '89': '2\'11"',
  '90': '2\'11"',
  '91': '2\'11"',
  '92': '3\'0"',
  '93': '3\'1"',
  '94': '3\'1"',
  '95': '3\'1"',
  '96': '3\'2"',
  '97': '3\'2"',
  '98': '3\'3"',
  '99': '3\'3"',
  '100': '3\'3"',
  '101': '3\'4"',
  '102': '3\'4"',
  '103': '3\'5"',
  '104': '3\'5"',
  '105': '3\'5"',
  '106': '3\'6"',
  '107': '3\'6"',
  '108': '3\'7"',
  '109': '3\'7"',
  '110': '3\'7"',
  '111': '3\'8"',
  '112': '3\'8"',
  '113': '3\'8"',
  '114': '3\'9"',
  '115': '3\'9"',
  '116': '3\'10"',
  '117': '3\'10"',
  '118': '3\'10"',
  '119': '3\'11"',
  '120': '3\'11"',
  '121': '3\'11"',
  '122': '4\'0"',
  '123': '4\'0"',
  '124': '4\'1"',
  '125': '4\'1"',
  '126': '4\'2"',
  '127': '4\'2"',
  '128': '4\'2"',
  '129': '4\'3"',
  '130': '4\'3"',
  '131': '4\'4"',
  '132': '4\'4"',
  '133': '4\'4"',
  '134': '4\'5"',
  '135': '4\'5"',
  '136': '4\'6"',
  '137': '4\'6"',
  '138': '4\'6"',
  '139': '4\'7"',
  '140': '4\'7"',
  '141': '4\'8"',
  '142': '4\'8"',
  '143': '4\'8"',
  '144': '4\'9"',
  '145': '4\'9"',
  '146': '4\'9"',
  '147': '4\'10"',
  '148': '4\'10"',
  '149': '4\'11"',
  '150': '4\'11"',
  '151': '4\'11"',
  '152': '4\'11"',
  '153': '5\'0"',
  '154': '5\'1"',
  '155': '5\'1"',
  '156': '5\'1"',
  '157': '5\'2"',
  '158': '5\'2"',
  '159': '5\'3"',
  '160': '5\'3"',
  '161': '5\'3"',
  '162': '5\'4"',
  '163': '5\'4"',
  '164': '5\'5"',
  '165': '5\'5"',
  '166': '5\'5"',
  '167': '5\'6"',
  '168': '5\'6"',
  '169': '5\'7"',
  '170': '5\'7"',
  '171': '5\'7"',
  '172': '5\'8"',
  '173': '5\'8"',
  '174': '5\'9"',
  '175': '5\'9"',
  '176': '5\'9"',
  '177': '5\'10"',
  '178': '5\'10"',
  '179': '5\'10"',
  '180': '5\'11"',
  '181': '5\'11"',
  '182': '5\'11"',
  '183': '6\'0"',
  '184': '6\'0"',
  '185': '6\'1"',
  '186': '6\'1"',
  '187': '6\'2"',
  '188': '6\'2"',
  '189': '6\'2"',
  '190': '6\'3"',
  '191': '6\'3"',
  '192': '6\'4"',
  '193': '6\'4"',
  '194': '6\'4"',
  '195': '6\'5"',
  '196': '6\'5"',
  '197': '6\'6"',
  '198': '6\'6"',
  '199': '6\'6"',
  '200': '6\'7"',
  '201': '6\'7"',
  '202': '6\'8"',
  '203': '6\'8"',
  '204': '6\'8"',
  '205': '6\'9"',
  '206': '6\'9"',
  '207': '6\'9"',
  '208': '6\'10"',
  '209': '6\'10"',
  '210': '6\'11"',
  '211': '6\'11"',
  '212': '6\'11"',
  '213': '6\'11"',
  '214': '7\'0"',
  '215': '7\'1"',
  '216': '7\'1"',
  '217': '7\'1"',
  '218': '7\'2"',
  '219': '7\'2"',
  '220': '7\'3"',
  '221': '7\'3"',
  '222': '7\'3"',
  '223': '7\'4"',
  '224': '7\'4"',
  '225': '7\'5"',
  '226': '7\'5"',
  '227': '7\'5"',
  '228': '7\'6"',
  '229': '7\'6"',
  '230': '7\'7"',
  '231': '7\'7"',
  '232': '7\'7"',
  '233': '7\'8"',
  '234': '7\'8"',
  '235': '7\'9"',
  '236': '7\'9"',
  '237': '7\'9"',
  '238': '7\'10"',
  '239': '7\'10"',
  '240': '7\'10"',
  '241': '7\'11"',
  '242': '7\'11"',
  '243': '7\'11"',
  '244': '8\'0"',
  '245': '8\'0"',
  '246': '8\'1"',
  '247': '8\'1"',
  '248': '8\'2"',
  '249': '8\'2"',
  '250': '8\'2"',
};

export const ftsToCms: Record<string, any> = {
  '0\'0"': 0,
  '0\'1"': 3,
  '0\'2"': 5,
  '0\'3"': 8,
  '0\'4"': 10,
  '0\'5"': 13,
  '0\'6"': 15,
  '0\'7"': 18,
  '0\'8"': 20,
  '0\'9"': 23,
  '0\'10"': 25,
  '0\'11"': 28,
  '1\'0"': 30,
  '1\'1"': 33,
  '1\'2"': 36,
  '1\'3"': 38,
  '1\'4"': 41,
  '1\'5"': 43,
  '1\'6"': 46,
  '1\'7"': 48,
  '1\'8"': 51,
  '1\'9"': 53,
  '1\'10"': 56,
  '1\'11"': 58,
  '2\'0"': 61,
  '2\'1"': 64,
  '2\'2"': 66,
  '2\'3"': 69,
  '2\'4"': 71,
  '2\'5"': 74,
  '2\'6"': 76,
  '2\'7"': 79,
  '2\'8"': 81,
  '2\'9"': 84,
  '2\'10"': 86,
  '2\'11"': 89,
  '3\'0"': 91,
  '3\'1"': 94,
  '3\'2"': 97,
  '3\'3"': 99,
  '3\'4"': 102,
  '3\'5"': 104,
  '3\'6"': 107,
  '3\'7"': 109,
  '3\'8"': 112,
  '3\'9"': 114,
  '3\'10"': 117,
  '3\'11"': 119,
  '4\'0"': 122,
  '4\'1"': 124,
  '4\'2"': 127,
  '4\'3"': 130,
  '4\'4"': 132,
  '4\'5"': 135,
  '4\'6"': 137,
  '4\'7"': 140,
  '4\'8"': 142,
  '4\'9"': 145,
  '4\'10"': 147,
  '4\'11"': 150,
  '5\'0"': 152,
  '5\'1"': 155,
  '5\'2"': 157,
  '5\'3"': 160,
  '5\'4"': 163,
  '5\'5"': 165,
  '5\'6"': 168,
  '5\'7"': 170,
  '5\'8"': 173,
  '5\'9"': 175,
  '5\'10"': 178,
  '5\'11"': 180,
  '6\'0"': 183,
  '6\'1"': 185,
  '6\'2"': 188,
  '6\'3"': 191,
  '6\'4"': 193,
  '6\'5"': 196,
  '6\'6"': 198,
  '6\'7"': 201,
  '6\'8"': 203,
  '6\'9"': 206,
  '6\'10"': 208,
  '6\'11"': 211,
  '7\'0"': 213,
  '7\'1"': 216,
  '7\'2"': 218,
  '7\'3"': 221,
  '7\'4"': 224,
  '7\'5"': 226,
  '7\'6"': 229,
  '7\'7"': 231,
  '7\'8"': 234,
  '7\'9"': 236,
  '7\'10"': 239,
  '7\'11"': 241,
  '8\'0"': 244,
  '8\'1"': 246,
  '8\'2"': 249,
  '8\'3"': 251,
  '8\'4"': 254,
  '8\'5"': 257,
  '8\'6"': 259,
  '8\'7"': 262,
  '8\'8"': 264,
  '8\'9"': 267,
  '8\'10"': 269,
  '8\'11"': 272,
  '9\'0"': 274,
  '9\'1"': 277,
  '9\'2"': 279,
  '9\'3"': 282,
  '9\'4"': 284,
  '9\'5"': 287,
  '9\'6"': 290,
  '9\'7"': 292,
  '9\'8"': 295,
  '9\'9"': 297,
  '9\'10"': 300,
  '9\'11"': 302,
};

export const kgsToLbs: Record<string, any> = {
  '0': 0,
  '1': 2,
  '2': 4,
  '3': 7,
  '4': 9,
  '5': 11,
  '6': 13,
  '7': 15,
  '8': 18,
  '9': 20,
  '10': 22,
  '11': 24,
  '12': 26,
  '13': 29,
  '14': 31,
  '15': 33,
  '16': 35,
  '17': 37,
  '18': 40,
  '19': 42,
  '20': 44,
  '21': 46,
  '22': 49,
  '23': 51,
  '24': 53,
  '25': 55,
  '26': 57,
  '27': 60,
  '28': 62,
  '29': 64,
  '30': 66,
  '31': 68,
  '32': 71,
  '33': 73,
  '34': 75,
  '35': 77,
  '36': 79,
  '37': 82,
  '38': 84,
  '39': 86,
  '40': 88,
  '41': 90,
  '42': 93,
  '43': 95,
  '44': 97,
  '45': 99,
  '46': 101,
  '47': 104,
  '48': 106,
  '49': 108,
  '50': 110,
  '51': 112,
  '52': 115,
  '53': 117,
  '54': 119,
  '55': 121,
  '56': 123,
  '57': 126,
  '58': 128,
  '59': 130,
  '60': 132,
  '61': 134,
  '62': 137,
  '63': 139,
  '64': 141,
  '65': 143,
  '66': 146,
  '67': 148,
  '68': 150,
  '69': 152,
  '70': 154,
  '71': 157,
  '72': 159,
  '73': 161,
  '74': 163,
  '75': 165,
  '76': 168,
  '77': 170,
  '78': 172,
  '79': 174,
  '80': 176,
  '81': 179,
  '82': 181,
  '83': 183,
  '84': 185,
  '85': 187,
  '86': 190,
  '87': 192,
  '88': 194,
  '89': 196,
  '90': 198,
  '91': 201,
  '92': 203,
  '93': 205,
  '94': 207,
  '95': 209,
  '96': 212,
  '97': 214,
  '98': 216,
  '99': 218,
  '100': 220,
  '101': 223,
  '102': 225,
  '103': 227,
  '104': 229,
  '105': 231,
  '106': 234,
  '107': 236,
  '108': 238,
  '109': 240,
  '110': 243,
  '111': 245,
  '112': 247,
  '113': 249,
  '114': 251,
  '115': 254,
  '116': 256,
  '117': 258,
  '118': 260,
  '119': 262,
  '120': 265,
  '121': 267,
  '122': 269,
  '123': 271,
  '124': 273,
  '125': 276,
  '126': 278,
  '127': 280,
  '128': 282,
  '129': 284,
  '130': 287,
  '131': 289,
  '132': 291,
  '133': 293,
  '134': 295,
  '135': 298,
  '136': 300,
  '137': 302,
  '138': 304,
  '139': 306,
  '140': 309,
  '141': 311,
  '142': 313,
  '143': 315,
  '144': 317,
  '145': 320,
  '146': 322,
  '147': 324,
  '148': 326,
  '149': 328,
  '150': 331,
  '151': 333,
  '152': 335,
  '153': 337,
  '154': 340,
  '155': 342,
  '156': 344,
  '157': 346,
  '158': 348,
  '159': 351,
  '160': 353,
  '161': 355,
  '162': 357,
  '163': 359,
  '164': 362,
  '165': 364,
  '166': 366,
  '167': 368,
  '168': 370,
  '169': 373,
  '170': 375,
  '171': 377,
  '172': 379,
  '173': 381,
  '174': 384,
  '175': 386,
  '176': 388,
  '177': 390,
  '178': 392,
  '179': 395,
  '180': 397,
  '181': 399,
  '182': 401,
  '183': 403,
  '184': 406,
  '185': 408,
  '186': 410,
  '187': 412,
  '188': 414,
  '189': 417,
  '190': 419,
  '191': 421,
  '192': 423,
  '193': 425,
  '194': 428,
  '195': 430,
  '196': 432,
  '197': 434,
  '198': 437,
  '199': 439,
  '200': 441,
  '201': 443,
  '202': 445,
  '203': 448,
  '204': 450,
  '205': 452,
  '206': 454,
  '207': 456,
  '208': 459,
  '209': 461,
  '210': 463,
  '211': 465,
  '212': 467,
  '213': 470,
  '214': 472,
  '215': 474,
  '216': 476,
  '217': 478,
  '218': 481,
  '219': 483,
  '220': 485,
  '221': 487,
  '222': 489,
  '223': 492,
  '224': 494,
  '225': 496,
  '226': 498,
  '227': 500,
  '228': 503,
  '229': 505,
  '230': 507,
  '231': 509,
  '232': 511,
  '233': 514,
  '234': 516,
  '235': 518,
  '236': 520,
  '237': 522,
  '238': 525,
  '239': 527,
  '240': 529,
  '241': 531,
  '242': 534,
  '243': 536,
  '244': 538,
  '245': 540,
  '246': 542,
  '247': 545,
  '248': 547,
  '249': 549,
  '250': 551,
};

export const lbsToKgs: Record<string, any> = {
  '0': 0,
  '1': 0,
  '2': 1,
  '3': 1,
  '4': 2,
  '5': 2,
  '6': 3,
  '7': 3,
  '8': 4,
  '9': 4,
  '10': 5,
  '11': 5,
  '12': 5,
  '13': 6,
  '14': 6,
  '15': 7,
  '16': 7,
  '17': 8,
  '18': 8,
  '19': 9,
  '20': 9,
  '21': 10,
  '22': 10,
  '23': 10,
  '24': 11,
  '25': 11,
  '26': 12,
  '27': 12,
  '28': 13,
  '29': 13,
  '30': 14,
  '31': 14,
  '32': 15,
  '33': 15,
  '34': 15,
  '35': 16,
  '36': 16,
  '37': 17,
  '38': 17,
  '39': 18,
  '40': 18,
  '41': 19,
  '42': 19,
  '43': 20,
  '44': 20,
  '45': 20,
  '46': 21,
  '47': 21,
  '48': 22,
  '49': 22,
  '50': 23,
  '51': 23,
  '52': 24,
  '53': 24,
  '54': 24,
  '55': 25,
  '56': 25,
  '57': 26,
  '58': 26,
  '59': 27,
  '60': 27,
  '61': 28,
  '62': 28,
  '63': 29,
  '64': 29,
  '65': 29,
  '66': 30,
  '67': 30,
  '68': 31,
  '69': 31,
  '70': 32,
  '71': 32,
  '72': 33,
  '73': 33,
  '74': 34,
  '75': 34,
  '76': 34,
  '77': 35,
  '78': 35,
  '79': 36,
  '80': 36,
  '81': 37,
  '82': 37,
  '83': 38,
  '84': 38,
  '85': 39,
  '86': 39,
  '87': 39,
  '88': 40,
  '89': 40,
  '90': 41,
  '91': 41,
  '92': 42,
  '93': 42,
  '94': 43,
  '95': 43,
  '96': 44,
  '97': 44,
  '98': 44,
  '99': 45,
  '100': 45,
  '101': 46,
  '102': 46,
  '103': 47,
  '104': 47,
  '105': 48,
  '106': 48,
  '107': 49,
  '108': 49,
  '109': 49,
  '110': 50,
  '111': 50,
  '112': 51,
  '113': 51,
  '114': 52,
  '115': 52,
  '116': 53,
  '117': 53,
  '118': 54,
  '119': 54,
  '120': 54,
  '121': 55,
  '122': 55,
  '123': 56,
  '124': 56,
  '125': 57,
  '126': 57,
  '127': 58,
  '128': 58,
  '129': 59,
  '130': 59,
  '131': 59,
  '132': 60,
  '133': 60,
  '134': 61,
  '135': 61,
  '136': 62,
  '137': 62,
  '138': 63,
  '139': 63,
  '140': 64,
  '141': 64,
  '142': 64,
  '143': 65,
  '144': 65,
  '145': 66,
  '146': 66,
  '147': 67,
  '148': 67,
  '149': 68,
  '150': 68,
  '151': 68,
  '152': 69,
  '153': 69,
  '154': 70,
  '155': 70,
  '156': 71,
  '157': 71,
  '158': 72,
  '159': 72,
  '160': 73,
  '161': 73,
  '162': 73,
  '163': 74,
  '164': 74,
  '165': 75,
  '166': 75,
  '167': 76,
  '168': 76,
  '169': 77,
  '170': 77,
  '171': 78,
  '172': 78,
  '173': 78,
  '174': 79,
  '175': 79,
  '176': 80,
  '177': 80,
  '178': 81,
  '179': 81,
  '180': 82,
  '181': 82,
  '182': 83,
  '183': 83,
  '184': 83,
  '185': 84,
  '186': 84,
  '187': 85,
  '188': 85,
  '189': 86,
  '190': 86,
  '191': 87,
  '192': 87,
  '193': 88,
  '194': 88,
  '195': 88,
  '196': 89,
  '197': 89,
  '198': 90,
  '199': 90,
  '200': 91,
  '201': 91,
  '202': 92,
  '203': 92,
  '204': 93,
  '205': 93,
  '206': 93,
  '207': 94,
  '208': 94,
  '209': 95,
  '210': 95,
  '211': 96,
  '212': 96,
  '213': 97,
  '214': 97,
  '215': 98,
  '216': 98,
  '217': 98,
  '218': 99,
  '219': 99,
  '220': 100,
  '221': 100,
  '222': 101,
  '223': 101,
  '224': 102,
  '225': 102,
  '226': 103,
  '227': 103,
  '228': 103,
  '229': 104,
  '230': 104,
  '231': 105,
  '232': 105,
  '233': 106,
  '234': 106,
  '235': 107,
  '236': 107,
  '237': 108,
  '238': 108,
  '239': 108,
  '240': 109,
  '241': 109,
  '242': 110,
  '243': 110,
  '244': 111,
  '245': 111,
  '246': 112,
  '247': 112,
  '248': 112,
  '249': 113,
  '250': 113,
  '251': 114,
  '252': 114,
  '253': 115,
  '254': 115,
  '255': 116,
  '256': 116,
  '257': 117,
  '258': 117,
  '259': 117,
  '260': 118,
  '261': 118,
  '262': 119,
  '263': 119,
  '264': 120,
  '265': 120,
  '266': 121,
  '267': 121,
  '268': 122,
  '269': 122,
  '270': 122,
  '271': 123,
  '272': 123,
  '273': 124,
  '274': 124,
  '275': 125,
  '276': 125,
  '277': 126,
  '278': 126,
  '279': 127,
  '280': 127,
  '281': 127,
  '282': 128,
  '283': 128,
  '284': 129,
  '285': 129,
  '286': 130,
  '287': 130,
  '288': 131,
  '289': 131,
  '290': 132,
  '291': 132,
  '292': 132,
  '293': 133,
  '294': 133,
  '295': 134,
  '296': 134,
  '297': 135,
  '298': 135,
  '299': 136,
  '300': 136,
  '301': 137,
  '302': 137,
  '303': 137,
  '304': 138,
  '305': 138,
  '306': 139,
  '307': 139,
  '308': 140,
  '309': 140,
  '310': 141,
  '311': 141,
  '312': 142,
  '313': 142,
  '314': 142,
  '315': 143,
  '316': 143,
  '317': 144,
  '318': 144,
  '319': 145,
  '320': 145,
  '321': 146,
  '322': 146,
  '323': 147,
  '324': 147,
  '325': 147,
  '326': 148,
  '327': 148,
  '328': 149,
  '329': 149,
  '330': 150,
  '331': 150,
  '332': 151,
  '333': 151,
  '334': 152,
  '335': 152,
  '336': 152,
  '337': 153,
  '338': 153,
  '339': 154,
  '340': 154,
  '341': 155,
  '342': 155,
  '343': 156,
  '344': 156,
  '345': 156,
  '346': 157,
  '347': 157,
  '348': 158,
  '349': 158,
  '350': 159,
  '351': 159,
  '352': 160,
  '353': 160,
  '354': 161,
  '355': 161,
  '356': 161,
  '357': 162,
  '358': 162,
  '359': 163,
  '360': 163,
  '361': 164,
  '362': 164,
  '363': 165,
  '364': 165,
  '365': 166,
  '366': 166,
  '367': 166,
  '368': 167,
  '369': 167,
  '370': 168,
  '371': 168,
  '372': 169,
  '373': 169,
  '374': 170,
  '375': 170,
  '376': 171,
  '377': 171,
  '378': 171,
  '379': 172,
  '380': 172,
  '381': 173,
  '382': 173,
  '383': 174,
  '384': 174,
  '385': 175,
  '386': 175,
  '387': 176,
  '388': 176,
  '389': 176,
  '390': 177,
  '391': 177,
  '392': 178,
  '393': 178,
  '394': 179,
  '395': 179,
  '396': 180,
  '397': 180,
  '398': 181,
  '399': 181,
  '400': 181,
  '401': 182,
  '402': 182,
  '403': 183,
  '404': 183,
  '405': 184,
  '406': 184,
  '407': 185,
  '408': 185,
  '409': 186,
  '410': 186,
  '411': 186,
  '412': 187,
  '413': 187,
  '414': 188,
  '415': 188,
  '416': 189,
  '417': 189,
  '418': 190,
  '419': 190,
  '420': 191,
  '421': 191,
  '422': 191,
  '423': 192,
  '424': 192,
  '425': 193,
  '426': 193,
  '427': 194,
  '428': 194,
  '429': 195,
  '430': 195,
  '431': 195,
  '432': 196,
  '433': 196,
  '434': 197,
  '435': 197,
  '436': 198,
  '437': 198,
  '438': 199,
  '439': 199,
  '440': 200,
  '441': 200,
  '442': 200,
  '443': 201,
  '444': 201,
  '445': 202,
  '446': 202,
  '447': 203,
  '448': 203,
  '449': 204,
  '450': 204,
  '451': 205,
  '452': 205,
  '453': 205,
  '454': 206,
  '455': 206,
  '456': 207,
  '457': 207,
  '458': 208,
  '459': 208,
  '460': 209,
  '461': 209,
  '462': 210,
  '463': 210,
  '464': 210,
  '465': 211,
  '466': 211,
  '467': 212,
  '468': 212,
  '469': 213,
  '470': 213,
  '471': 214,
  '472': 214,
  '473': 215,
  '474': 215,
  '475': 215,
  '476': 216,
  '477': 216,
  '478': 217,
  '479': 217,
  '480': 218,
  '481': 218,
  '482': 219,
  '483': 219,
  '484': 220,
  '485': 220,
  '486': 220,
  '487': 221,
  '488': 221,
  '489': 222,
  '490': 222,
  '491': 223,
  '492': 223,
  '493': 224,
  '494': 224,
  '495': 225,
  '496': 225,
  '497': 225,
  '498': 226,
  '499': 226,
  '500': 227,
  '501': 227,
  '502': 228,
  '503': 228,
  '504': 229,
  '505': 229,
  '506': 230,
  '507': 230,
  '508': 230,
  '509': 231,
  '510': 231,
  '511': 232,
  '512': 232,
  '513': 233,
  '514': 233,
  '515': 234,
  '516': 234,
  '517': 235,
  '518': 235,
  '519': 235,
  '520': 236,
  '521': 236,
  '522': 237,
  '523': 237,
  '524': 238,
  '525': 238,
  '526': 239,
  '527': 239,
  '528': 239,
  '529': 240,
  '530': 240,
  '531': 241,
  '532': 241,
  '533': 242,
  '534': 242,
  '535': 243,
  '536': 243,
  '537': 244,
  '538': 244,
  '539': 244,
  '540': 245,
  '541': 245,
  '542': 246,
  '543': 246,
  '544': 247,
  '545': 247,
  '546': 248,
  '547': 248,
  '548': 249,
  '549': 249,
  '550': 249,
  '551': 250,
  '552': 250,
  '553': 251,
  '554': 251,
  '555': 252,
  '556': 252,
  '557': 253,
  '558': 253,
  '559': 254,
  '560': 254,
  '561': 254,
  '562': 255,
  '563': 255,
  '564': 256,
  '565': 256,
  '566': 257,
  '567': 257,
  '568': 258,
  '569': 258,
  '570': 259,
  '571': 259,
  '572': 259,
  '573': 260,
  '574': 260,
  '575': 261,
  '576': 261,
  '577': 262,
  '578': 262,
  '579': 263,
  '580': 263,
  '581': 264,
  '582': 264,
  '583': 264,
  '584': 265,
  '585': 265,
  '586': 266,
  '587': 266,
  '588': 267,
  '589': 267,
  '590': 268,
  '591': 268,
  '592': 269,
  '593': 269,
  '594': 269,
  '595': 270,
  '596': 270,
  '597': 271,
  '598': 271,
  '599': 272,
  '600': 272,
};
