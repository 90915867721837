import { colors } from '@/utils/Constants';
import useWindowSize from '@/utils/Hooks/useWindowSize';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

const Loader = (props: CircularProgressProps) => {
  const width = useWindowSize();
  return width ? (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={width < 768 ? 64 : 128}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: colors.accent,
          animationDuration: '1500ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={width < 768 ? 64 : 128}
        thickness={4}
        {...props}
      />
    </Box>
  ) : null;
};

export default Loader;
