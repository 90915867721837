import { benefits1, benefits2, colors, personalPrograms } from '@/utils/Constants';
import { useMemo } from 'react';
import { styled } from 'styled-components';
import CustomTypography from '../Typography';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Checked, MobilePhone } from '@/assets';
import { sourceSet } from '@/utils/Helper';
import CutOut from '../CutOut';
import BenefitCell from './BenefitCell';
import useIsPhone from '@/utils/Hooks/usePhone';

const PersonalProgram = () => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();

  const programList = useMemo(() => {
    return personalPrograms.map((program, index) => {
      return (
        <ProgramRow key={index}>
          <Circle>
            <img src={Checked} width="48px" />
          </Circle>
          <CustomTypography title={t(program.title)} type="headingS" color={colors.dark} />
        </ProgramRow>
      );
    });
  }, []);

  const benefitsList1 = useMemo(() => {
    return benefits1.map((benefit, index) => {
      return <BenefitCell key={index} {...benefit} />;
    });
  }, []);

  const benefitsList2 = useMemo(() => {
    return benefits2.map((benefit, index) => {
      return <BenefitCell key={index} {...benefit} />;
    });
  }, []);

  return (
    <Container>
      <CutOut bgColor={colors.accent} />
      <CustomTypography
        title={t('titles.yourPersonalProgram')}
        type={isMobile ? 'generalSansSmallSemibold' : 'generalSansMiddleSemibold'}
        style={{ textAlign: 'center', color: colors.dark, paddingBottom: '96px' }}
      />
      <Stack
        flexDirection={isMobile ? 'column-reverse' : 'row'}
        justifyContent="space-evenly"
        pb={isMobile ? '56px' : '120px'}
        gap={isMobile ? '56px' : 0}
      >
        <Stack gap="24px" justifyContent="center">
          {programList}
        </Stack>
        <Stack alignItems="center">
          <img src={MobilePhone} srcSet={sourceSet(MobilePhone)} width="300px" />
        </Stack>
      </Stack>
      <CustomTypography
        title={t('titles.discoverBenefits')}
        type={isMobile ? 'generalSansSmallSemibold' : 'generalSansMiddleSemibold'}
        style={{ textAlign: 'center', color: colors.dark, marginBottom: '32px' }}
      />
      <CustomTypography
        title={t('titles.expectFromYoga')}
        type="bodyMedium"
        style={{
          textAlign: 'center',
          color: colors.dark,
          marginBottom: isMobile ? '56px' : '72px',
        }}
      />
      {isMobile ? (
        <Stack gap="16px" pb="72px">
          {benefitsList1}
          {benefitsList2}
        </Stack>
      ) : (
        <Stack pb="128px" gap="24px" width="100%">
          <Stack
            ml="190px"
            mr="240px"
            gap="24px"
            flexDirection="row"
            sx={{
              '@media (max-width: 1150px)': {
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 0,
                marginRight: 0,
              },
            }}
          >
            {benefitsList1}
          </Stack>
          <Stack
            gap="24px"
            flexDirection="row"
            justifyContent="flex-end"
            ml="350px"
            mr="80px"
            sx={{
              '@media (max-width: 1150px)': {
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 0,
                marginRight: 0,
              },
            }}
          >
            {benefitsList2}
          </Stack>
        </Stack>
      )}
    </Container>
  );
};

export default PersonalProgram;

const Container = styled.div`
  width: 100%;
  background-color: ${colors.sand};
  position: relative;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  margin-top: -40px;
  padding: 96px 20px 0 20px;
  @media (max-width: 768px) {
    padding: 72px 20px 0 20px;
  }
`;

const ProgramRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const Circle = styled.div`
  width: 48px;
  height: 48px;
  background-color: ${colors.accent};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
