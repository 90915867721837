import { BmiChartArrow, BmiGraphic } from '@/assets';
import { sourceSet } from '@/utils/Helper';
import React from 'react';
import { css, keyframes, styled } from 'styled-components';

type Props = {
  style?: React.CSSProperties;
  rotationDegree: number;
};

const CakeGraphic = ({ style, rotationDegree }: Props) => {
  return (
    <Container style={style}>
      <ArrowContainer $rotationDegree={rotationDegree}>
        <img
          src={BmiChartArrow}
          alt="BmiChartArrow"
          width="210px"
          srcSet={sourceSet(BmiChartArrow)}
        />
      </ArrowContainer>
      <img src={BmiGraphic} alt="InfoGraph" srcSet={sourceSet(BmiGraphic)} />
    </Container>
  );
};

export default CakeGraphic;

const rotate = ($rotationDegree: number) => keyframes`
    0% {
        transform: rotate(-90deg);
    }
    100% {
        transform: rotate(${$rotationDegree}deg);
    }
`;

const Container = styled.div`
  position: relative;
`;

const ArrowContainer = styled.div<{ $rotationDegree: number }>`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  animation: ${({ $rotationDegree }) =>
    css`
      ${rotate($rotationDegree)} 4s forwards;
    `};
`;
