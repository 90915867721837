import { colors } from '@/utils/Constants';
import { Value } from '@/utils/Types';
import Calendar, { TileArgs } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { styled } from 'styled-components';

type Props = {
  setValue: (value: Value) => void;
  selectedDate: Value;
};
const CalendarComponent = ({ setValue, selectedDate }: Props) => {
  const language = localStorage.getItem('language') || 'en';

  const onHandleChange = (value: Value) => {
    setValue(value);
  };

  const tileDisabled = ({ date }: TileArgs) => {
    const now = new Date();
    return date < new Date(now.setDate(now.getDate() + 0));
  };

  const formatShortWeekday: (locale: string | undefined, date: Date) => string = (locale, date) => {
    if (!date) {
      return '';
    }
    const shortWeekdays = {
      en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      es: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    };

    let weekdays: string[] = shortWeekdays.en;
    if (locale === 'es') {
      weekdays = shortWeekdays.es;
    }
    return weekdays[date.getDay()];
  };

  return (
    <CalendarContainer>
      <Line />
      <Calendar
        onChange={onHandleChange}
        value={selectedDate}
        tileDisabled={tileDisabled}
        locale={language}
        formatShortWeekday={formatShortWeekday}
      />
    </CalendarContainer>
  );
};

export default CalendarComponent;

const CalendarContainer = styled.div`
  position: relative;
  .react-calendar {
    width: 100%;
    border-radius: 16px;
    border: 1px solid ${colors.sand};
    background-color: transparent;
    padding: 24px;
  }
  .react-calendar__tile {
    display: flex;
    justify-content: center;
    color: ${colors.sand};
    font-family: 'GeneralSans-Regular';
    font-size: 16px;
  }
  .react-calendar__tile--active {
    background: none;
    &:focus {
      background: none;
    }
  }
  .react-calendar__tile:disabled {
    background: transparent;
    border-radius: none;
    color: rgba(255, 255, 255, 0.5);
    :active {
      background: none;
      transition: 3.3s;
    }
  }
  .react-calendar__month-view__days__day--weekend {
    color: ${colors.sand};
    font-family: GeneralSans-Regular;
    font-size: 16px;
  }
  .react-calendar__month-view__weekdays__weekday {
    color: ${colors.sandAccent};
    font-family: GeneralSans-Semibold;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .react-calendar__tile--now {
    background-color: transparent;
  }
  .react-calendar__tile {
    color: ${colors.sand};
    font-family: 'GeneralSans-Regular';
    font-size: 16px;
    padding: 0;
  }
  button.react-calendar__tile {
    &:hover {
      background: none;
    }
  }
  .react-calendar__tile abbr {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 425px) {
      width: 40px;
      height: 40px;
    }
  }
  .react-calendar__month-view__days__day {
    height: 48px;
    width: 48px;
    @media (max-width: 425px) {
      width: 48px;
      height: 48px;
    }
  }
  abbr[title] {
    text-decoration: none;
  }
  .react-calendar__tile--range abbr {
    width: 48px;
    height: 48px;
    background-color: ${colors.accentDark};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 425px) {
      width: 40px;
      height: 40px;
    }
  }
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__label__labelText {
    color: ${colors.sand};
    font-family: GeneralSans-Semibold;
    font-size: 18px;
  }
  .react-calendar__navigation__label {
    pointer-events: none;
  }
  .react-calendar__navigation__arrow {
    color: ${colors.sand};
    font-size: 28px;
  }
  .react-calendar__navigation {
    margin-bottom: 44px;
  }
  button.react-calendar__navigation__next-button,
  button.react-calendar__navigation__prev-button {
    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
`;

const Line = styled.div`
  position: absolute;
  top: 84px;
  height: 1px;
  left: 24px;
  right: 24px;
  background-color: ${colors.sand};
`;
