import { colors } from '@/utils/Constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CustomTypography } from '.';

type Props = {
  title: string;
  style?: React.CSSProperties;
  icon?: string;
};

const Label = ({ title, style, icon }: Props) => {
  const { t } = useTranslation();

  return (
    <Container style={style}>
      {icon && <img src={icon} alt="icon" width="24px" height="24px" />}
      <CustomTypography title={t(title)} type="bodySemibold" color={colors.white} />
    </Container>
  );
};

export default Label;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 16px;
`;
