import { QuizPage } from '@/utils/Types';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import {
  cmsRange,
  cmsToFeet,
  ftsToCms,
  kgsRange,
  kgsToLbs,
  lbsRange,
  lbsToKgs,
  ftsRange,
} from 'utils/Measurements';
import { colors } from '@/utils/Constants';
import CustomButton from '../Button';
import CustomTypography from '../Typography';
import InputCounter from '../InputCounter';
import Toast from '../Toast';
import useIsPhone from '@/utils/Hooks/usePhone';
import BMICard from '../BMICard';
import FadeIn from 'react-fade-in';
import styled from 'styled-components';
import useWindowSize from '@/utils/Hooks/useWindowSize';

const MeasurementsPage = ({ params, onSelect, answers, setAnswers }: QuizPage) => {
  const isMobile = useIsPhone();
  const width = useWindowSize();
  const [focused, setFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const inputFtRef = useRef<HTMLInputElement>(null);

  const type = useMemo(() => {
    if (params.id.toLowerCase().includes('weight')) {
      return 'weight';
    }
    if (params.id.toLowerCase().includes('height')) {
      return 'height';
    }
    return 'age';
  }, [params.id]);

  const { t } = useTranslation();
  const [unit, setUnit] = useState<string>('');
  const [value, setValue] = useState<{ [k: string]: string }>({});
  const [error, setError] = useState<{ [k: string]: string }>({
    cm: '',
    ft: '',
    in: '',
    kg: '',
    lbs: '',
    age: '',
  });

  const question = useMemo(() => t(`questions.${params.id}`), [params.id, t]);

  useEffect(() => {
    if (inputFtRef.current && unit === 'ft') {
      inputFtRef.current.focus();
    } else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [params.id, unit]);

  useEffect(() => {
    setError((prev) => ({ ...prev, [unit]: '' }));
    if (!answers[params.id as keyof typeof answers] && !answers['measurementSystemMetric']) {
      setValue({ ['measurementSystemMetric']: 'imperial' });
      setUnit(type === 'height' ? 'ft' : 'lbs');
    } else {
      if (type === 'height') {
        setUnit(answers['measurementSystemMetric'] === 'imperial' ? 'ft' : 'cm');
        if (answers['measurementSystemMetric'] === 'imperial') {
          setValue({
            ft: (answers[params.id as keyof typeof answers] as string)
              ?.split(' ')[0]
              ?.split("'")[0],
            in: (answers[params.id as keyof typeof answers] as string)
              .split(' ')[0]
              .split("'")[1]
              .replace('"', ''),
            measurementSystemMetric: answers['measurementSystemMetric'],
          });
          return;
        }
        setValue({
          cm: answers[params.id as keyof typeof answers],
          measurementSystemMetric: answers['measurementSystemMetric'],
        });
      }
      if (type === 'weight') {
        setUnit(answers['measurementSystemMetric'] === 'imperial' ? 'lbs' : 'kg');
        setValue({
          [answers['measurementSystemMetric'] === 'imperial' ? 'lbs' : 'kg']:
            answers[params.id as keyof typeof answers],
          ['measurementSystemMetric']: answers['measurementSystemMetric'],
        });
      }
      if (type === 'age') {
        setUnit('age');
        setValue({
          age: answers[params.id as keyof typeof answers] || '',
          measurementSystemMetric: answers['measurementSystemMetric'],
        });
      }
    }
  }, [type, answers, params.id, t]);

  const handleValueChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    unitType: 'cm' | 'ft' | 'in' | 'kg' | 'lbs' | 'age'
  ) => {
    if (unitType === 'in' && error['in']) {
      setError((prev) => ({ ...prev, ['in']: '' }));
    } else if (unitType === 'ft' && error['ft']) {
      setError((prev) => ({ ...prev, ['ft']: '' }));
    } else {
      setError((prev) => ({ ...prev, [unitType]: '' }));
    }
    if (e.target.value !== '') {
      setValue((prev) => ({ ...prev, [unitType]: e.target.value }));
    } else {
      setValue((prev) => ({ ...prev, [unitType]: '' }));
    }
  };

  const validate = (inputValue: string, unitType: 'cm' | 'ft' | 'in' | 'kg' | 'lbs' | 'age') => {
    if (isNaN(Number(inputValue))) {
      setError((prev) => ({ ...prev, [unitType]: t('validation.invalidNumber') }));
      return false;
    }
    if (unit === 'ft') {
      if (unitType === 'in') {
        const currInche = parseInt(inputValue, 10);
        if (currInche > 11) {
          setError((prev) => ({ ...prev, [unitType]: t('validation.invalidInches') }));
          return false;
        }

        return true;
      }
      if (unitType === 'ft') {
        const currFeet = parseInt(inputValue, 10);
        const range = ftsRange;
        if (currFeet < range[0] || currFeet > range[1]) {
          setError((prev) => ({ ...prev, [unit]: t('validation.invalidFeetHeight') }));
          return false;
        }
        return true;
      }
    }
    const currValue = parseInt(inputValue, 10);
    if (unit === 'kg') {
      const range = kgsRange;
      if ((currValue < range[0] || currValue > range[1]) && !focused) {
        setError((prev) => ({ ...prev, [unit]: t('validation.invalidKgWeight') }));
        return false;
      }
    }
    if (unit === 'lbs') {
      const range = lbsRange;
      if ((currValue < range[0] || currValue > range[1]) && !focused) {
        setError((prev) => ({ ...prev, [unit]: t('validation.invalidLbsWeight') }));
        return false;
      }
    }
    if (unit === 'cm') {
      const range = cmsRange;
      if ((currValue < range[0] || currValue > range[1]) && !focused) {
        setError((prev) => ({ ...prev, [unit]: t('validation.invalidHeight') }));
        return false;
      }
    }

    if (
      typeof value[unit] === 'string' &&
      (value[unit]?.includes('.') || value[unit]?.includes(',') || value[unit]?.includes('-'))
    ) {
      setError((prev) => ({ ...prev, [unit]: t('validation.invalidNumber') }));
      return false;
    }

    setError((prev) => ({ ...prev, [unit]: '' }));
    return true;
  };

  const toggleMeasure = (measurement: 'cm' | 'ft' | 'kg' | 'lbs') => {
    if (measurement === 'ft' || measurement === 'lbs') {
      setValue((prev) => ({ ...prev, measurementSystemMetric: 'imperial' }));
    }
    if (measurement === 'cm' || measurement === 'kg') {
      setValue((prev) => ({ ...prev, measurementSystemMetric: 'metric' }));
    }
    if (unit === 'cm') {
      if (error.cm) {
        setValue((prev) => ({
          ...prev,
          ft: '',
          in: '',
          cm: prev.cm,
        }));
      } else {
        const feetValues = cmsToFeet[value?.cm]?.split("'");
        setValue((prev) => ({
          ...prev,
          ft: feetValues ? feetValues[0] : '',
          in: feetValues ? feetValues[1]?.replace('"', '') : '',
          cm: prev.cm,
        }));
      }
    }
    if (unit === 'ft') {
      if (error.ft || error.in) {
        setValue((prev) => ({
          ...prev,
          cm: '',
          ft: prev.ft,
          in: prev.in,
        }));
        setError((prev) => ({ ...prev, in: '' }));
      } else {
        setValue((prev) => ({
          ...prev,
          cm: ftsToCms[`${value?.ft ? value.ft : 0}'${value?.in ? value.in : 0}"`] || '',
          ft: prev.ft,
        }));
        setError((prev) => ({ ...prev, in: '' }));
      }
    }
    if (unit === 'kg') {
      if (error.kg) {
        setValue((prev) => ({
          ...prev,
          lbs: '',
          kg: prev.kg,
        }));
      } else {
        setValue((prev) => ({
          ...prev,
          lbs: kgsToLbs[value.kg] || '',
          kg: prev.kg,
        }));
      }
    }
    if (unit === 'lbs') {
      if (error.lbs) {
        setValue((prev) => ({
          ...prev,
          lbs: prev.lbs,
          kg: '',
        }));
      } else {
        setValue((prev) => ({
          ...prev,
          lbs: prev.lbs,
          kg: lbsToKgs[prev.lbs] || '',
        }));
      }
    }

    setUnit(measurement);
    setError((prev) => ({ ...prev, [unit]: '' }));
  };

  const onContinue = () => {
    if (!disabledContinue) {
      let unitValue = '';
      if (unit === 'ft') {
        unitValue = `${value.ft}'${value.in}"`;
      } else {
        unitValue = value[unit];
      }
      onSelect(params.id, unitValue);
      setAnswers((prev) => ({
        ...prev,
        measurementSystemMetric: value['measurementSystemMetric'],
      }));
      if (
        answers['measurementSystemMetric'] &&
        answers['measurementSystemMetric'] !== value['measurementSystemMetric']
      ) {
        const isFeet =
          typeof answers['currentHeight'] === 'string'
            ? answers['currentHeight']?.includes("'")
            : false;

        if (params.id === 'currentHeight') {
          setAnswers((prev) => ({
            ...prev,
            currentWeight:
              value['measurementSystemMetric'] === 'imperial'
                ? kgsToLbs[answers['currentWeight']]
                : lbsToKgs[answers['currentWeight']],
          }));
        }
        if (params.id === 'targetWeight') {
          setAnswers((prev) => ({
            ...prev,
            currentWeight:
              value['measurementSystemMetric'] === 'imperial'
                ? kgsToLbs[answers['currentWeight']]
                : lbsToKgs[answers['currentWeight']],
            measurementSystemMetric: value['measurementSystemMetric'],
          }));
        }
        if (params.id === 'currentWeight') {
          setAnswers((prev) => ({
            ...prev,
            targetWeight:
              value['measurementSystemMetric'] === 'imperial'
                ? kgsToLbs[answers['targetWeight']]
                : lbsToKgs[answers['targetWeight']],
            measurementSystemMetric: value['measurementSystemMetric'],
          }));
        }

        if (value['measurementSystemMetric'] === 'imperial' && !isFeet) {
          setAnswers((prev) => ({
            ...prev,
            currentHeight: cmsToFeet[answers['currentHeight']],
            measurementSystemMetric: value['measurementSystemMetric'],
          }));
          return;
        }
        if (value['measurementSystemMetric'] === 'metric' && isFeet) {
          setAnswers((prev) => ({
            ...prev,
            currentHeight: ftsToCms[answers['currentHeight']],
            measurementSystemMetric: value['measurementSystemMetric'],
          }));
          return;
        }
      } else {
        if (params.id === 'currentWeight') {
          setAnswers((prev) => ({
            ...prev,
            targetWeight: answers['targetWeight'],
            measurementSystemMetric: value['measurementSystemMetric'],
          }));
        }
      }
      setValue({});
    }
  };

  const mainInputValue = useMemo(() => {
    if (value[unit]) {
      return value[unit];
    }
    return '';
  }, [value, unit, params.id]);

  const mainInputUnitValue = useMemo(() => {
    if (type === 'age') {
      return 'age';
    }
    if (type === 'height') {
      return 'cm';
    }
    if (unit === 'kg') {
      return 'kg';
    }
    return 'lbs';
  }, [unit, type]);

  const mainInputUnitLabel = useMemo(() => {
    if (type === 'age') {
      return 'titles.years';
    }
    if (type === 'height') {
      return 'cm';
    }
    if (unit === 'kg') {
      return 'kg';
    }
    return 'lbs';
  }, [unit, type]);

  const showBMICard = useMemo(() => {
    const val = parseInt(value[unit], 10);
    if (unit === 'ft') {
      return parseInt(value.ft, 10) > 0 || parseInt(value.in, 10) > 0;
    }
    if (unit === 'lbs') {
      if (val > 331 || val < 66) {
        return false;
      }
    }
    if (unit === 'kg') {
      if (val > 150 || val < 30) {
        return false;
      }
    }
    return parseInt(value[unit], 10) > 0;
  }, [value, unit]);

  const handleTags = (
    evt: any,
    measurement: 'ft' | 'cm' | 'kg' | 'lbs' | 'in' | 'age',
    inputValue?: string
  ) => {
    if (inputValue && validate(inputValue, measurement)) {
      if (evt.key === 'Enter' && onContinue) {
        if (!disabledContinue) {
          onContinue();
        }
      }
    }
    if (
      measurement !== 'ft' &&
      measurement !== 'in' &&
      (evt.key === 'e' || evt.key === '.' || evt.key === ',' || evt.key === '-')
    ) {
      evt.preventDefault();
    }
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
      measurement !== 'ft' &&
      measurement !== 'in' &&
      (evt.key === 'e' || evt.key === '.' || evt.key === ',' || evt.key === '-')
    ) {
      evt.stopPropagation();
      evt.preventDefault();
    }
  };

  const renderContent = useMemo(() => {
    return (
      <Stack mt="40px" pb={showBMICard ? '40px' : '24px'}>
        {unit === 'ft' ? (
          <Stack flexDirection="row" gap="24px">
            <Stack flexDirection="row" alignItems="flex-end">
              <InputCounter
                error={error?.ft || ''}
                measurement="ft"
                onChange={handleValueChange}
                inputValue={value.ft}
                setFocused={setFocused}
                focused={focused}
                inputRef={inputFtRef}
                handleTags={handleTags}
                validate={validate}
              />
              <InputLabel>
                <CustomTypography
                  color={colors.accentDark}
                  type="headingL"
                  title="ft"
                  style={{ fontSize: width <= 340 ? '20px' : '32px' }}
                />
              </InputLabel>
            </Stack>
            <Stack flexDirection="row" alignItems="flex-end">
              <InputCounter
                error={error?.in || ''}
                measurement="in"
                onChange={handleValueChange}
                inputValue={value.in}
                setFocused={setFocused}
                focused={focused}
                inputRef={inputRef}
                handleTags={handleTags}
                validate={validate}
              />
              <InputLabel>
                <CustomTypography
                  color={colors.accentDark}
                  type="headingL"
                  title="in"
                  style={{ fontSize: width <= 340 ? '20px' : '32px' }}
                />
              </InputLabel>
            </Stack>
          </Stack>
        ) : (
          <Stack flexDirection="row" alignItems="flex-end">
            <InputCounter
              measurement={mainInputUnitValue}
              onChange={handleValueChange}
              inputValue={mainInputValue}
              focused={focused}
              setFocused={setFocused}
              error={error[unit]}
              handleTags={handleTags}
              inputRef={inputRef}
              validate={validate}
            />
            <InputLabel>
              <CustomTypography
                color={colors.accentDark}
                type="headingL"
                title={t(mainInputUnitLabel)}
                style={{ fontSize: width <= 340 ? '20px' : '32px' }}
              />
            </InputLabel>
          </Stack>
        )}
      </Stack>
    );
  }, [unit, value, error, t, params.id, handleValueChange, mainInputValue, type]);

  const continueButtonMarginTop = useMemo(() => {
    if (showBMICard) {
      return '16px';
    }
    if (error[unit]) {
      return '20px';
    }
    return '32px';
  }, [error, unit, showBMICard]);

  const disabledContinue = useMemo(() => {
    if (type === 'age' && (!value['age'] || parseInt(value['age'], 10) < 18)) {
      return true;
    }
    if (unit === 'cm' && (error[unit] || !value[unit] || (parseInt(value[unit]) < cmsRange[0] || parseInt(value[unit]) > cmsRange[1]))) {
      return true;
    }
    if (unit === 'kg' && (error[unit] || !value[unit] || (parseInt(value[unit]) < kgsRange[0] || parseInt(value[unit]) > kgsRange[1]))) {
      return true;
    }
    if (unit === 'lbs' && (error[unit] || !value[unit] || (parseInt(value[unit]) < lbsRange[0] || parseInt(value[unit]) > lbsRange[1]))) {
      return true;
    }
    if (unit === 'ft' && (!value.ft || !value.in || Boolean(error['ft']) || Boolean(error['in']) || (parseInt(value.ft) < ftsRange[0] || parseInt(value.ft) > ftsRange[1]) || (parseInt(value.in) < 0 || parseInt(value.in) > 11))) {      
      return true;
    }
    return false;
  }, [value, unit, type, focused, error]);

  const renderError = useMemo(() => {
    let string = '';
    if (error['ft'] && error['in']) {
      string = t('validation.invalidFeetAndInches');
    } else if (!error['ft'] && error['in']) {
      string = error['in'];
    } else if (error['in']) {
      string = error['in'];
    } else {
      string = error[unit];
    }
    if (string) {
      return (
        <Stack width="100%">
          <Toast title={string} style={{ marginTop: '32px' }} type="filled" color={colors.white} />
        </Stack>
      );
    }
  }, [error]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      flex={1}
      style={{ marginBottom: isMobile ? '40px' : '126px' }}
    >
      <CustomTypography
        title={question}
        type="headingL"
        color={colors.sand}
        style={{
          marginBottom: isMobile ? '24px' : '16px',
          textAlign: 'center',
          fontSize: isMobile ? '24px' : '32px',
        }}
      />
      <Stack alignItems="center" width="100%" pb={showBMICard ? '28px' : '0px'}>
        {type !== 'age' && (
          <Stack direction="row" gap="16px" alignItems="center" mb={isMobile ? '24px' : '0px'}>
            <Button
              sx={{
                ':hover': {
                  transition: '0.3s',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
              }}
              style={{
                width: 80,
                borderRadius: '14px',
                backgroundColor: unit === 'ft' || unit === 'lbs' ? colors.accentDark : colors.sand,
                color: unit === 'ft' || unit === 'lbs' ? colors.sand : colors.dark,
              }}
              onClick={() => toggleMeasure(type === 'height' ? 'ft' : 'lbs')}
            >
              <CustomTypography
                title={t(type === 'height' ? 'titles.ft' : 'titles.lbs').toUpperCase()}
                type="bodyMSemibold"
                color={unit === 'ft' || unit === 'lbs' ? colors.sand : colors.dark}
              />
            </Button>
            <Button
              sx={{
                ':hover': {
                  transition: '0.3s',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
              }}
              style={{
                width: 80,
                borderRadius: '14px',
                backgroundColor: unit === 'cm' || unit === 'kg' ? colors.accentDark : colors.sand,
                color: unit === 'cm' || unit === 'kg' ? colors.sand : colors.dark,
              }}
              onClick={() => toggleMeasure(type === 'height' ? 'cm' : 'kg')}
            >
              <CustomTypography
                title={t(type === 'height' ? 'titles.cm' : 'titles.kg').toUpperCase()}
                type="bodyMSemibold"
                color={unit === 'cm' || unit === 'kg' ? colors.sand : colors.dark}
              />
            </Button>
          </Stack>
        )}
        {renderContent}
        {showBMICard && (
          <Stack>
            <FadeIn>
              <BMICard question={params.id} inputValue={value[unit]} unit={unit} />
            </FadeIn>
          </Stack>
        )}
        {renderError}
        <CustomButton
          key={params.id}
          isLightTheme={false}
          onClick={onContinue}
          name="buttons.continue"
          showBMICard={showBMICard}
          style={{
            width: '100%',
            marginTop: continueButtonMarginTop,
            opacity: disabledContinue ? 0.5 : 1,
          }}
          disabled={disabledContinue}
        />
      </Stack>
    </Stack>
  );
};

export default MeasurementsPage;

const InputLabel = styled.div`
  width: auto;
  min-width: 30px;
  height: 121px;
  display: flex;
  align-items: flex-end;
  border-bottom: 2px solid #6660a7;
  padding-bottom: 16px;
  padding-left: 10px;
`;
