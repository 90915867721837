import { InputAdornment, Stack, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormLabel from '@mui/material/FormLabel';
import Toast from './Toast';
import { colors } from '@/utils/Constants';

type Props = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: () => void;
  value: string;
  error?: string;
  placeholderTitle?: string;
  type: string;
  style?: React.CSSProperties | undefined;
  formLabel?: string;
  disabled?: boolean;
  sx?: any;
  inputStyle?: React.CSSProperties;
  icon?: string;
};

const TextInput = ({
  onChange,
  value,
  error,
  placeholderTitle,
  type,
  style,
  formLabel,
  disabled,
  sx,
  inputStyle,
  icon,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Stack style={{ gap: 8 }}>
      {formLabel && (
        <FormLabel style={{ fontFamily: 'GeneralSans-Medium', fontSize: 14 }}>
          {t(formLabel)}
        </FormLabel>
      )}
      <TextField
        type={type}
        placeholder={t(placeholderTitle || '')}
        onChange={onChange}
        value={value}
        fullWidth
        disabled={disabled}
        style={style}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && onSubmit) {
            onSubmit();
          }
        }}
        InputLabelProps={{
          style: { color: 'red' },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={icon} />
            </InputAdornment>
          ),
          style: {
            color: error ? colors.error : colors.shadow,
            fontSize: 14,
            fontFamily: 'GeneralSans-Medium',
            borderColor: error && colors.error,
            minHeight: 56,
            ...inputStyle,
          },
        }}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: error ? colors.error : colors.sandDark,
          },
          '& .MuiInputBase-input': {
            color: colors.shadow,
            fontFamily: 'GeneralSans-Medium',
            fontSize: 14,
          },
          ...sx,
        }}
      />
      {error && <Toast title={t(error)} />}
    </Stack>
  );
};

export default TextInput;
