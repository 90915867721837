import { colors, savingsList, usersCards, planCards } from '@/utils/Constants';
import CutOut from '../CutOut';
import { styled } from 'styled-components';
import CustomTypography from '../Typography';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import UserCard from './UserCard';
import { Stack } from '@mui/material';
import CustomButton from '../Button';
import ExcellentTrustpilot from '../ExcellentTrustpilot';
import { Checked } from '@/assets';
import Line from '../Line';
import { scrollToView } from '@/utils/Helper';
import PulseWrapper from '../PulseWrapper';
import useIsPhone from '@/utils/Hooks/usePhone';
import VideoPlayer from '../VideoPlayer';
import CustomCarousel from '../Carousel';
import FadeIn from 'react-fade-in';

type Props = {
  onGetMyPlan: (id: string) => void;
};

const Users = ({ onGetMyPlan }: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();
  const [showMoreCards, setShowMoreCards] = useState<boolean>(false);

  const users = useMemo(() => {
    return usersCards.map((user, index) => {
      return <UserCard key={index} {...user} />;
    });
  }, []);

  const savings = useMemo(() => {
    return savingsList.map((saving, index) => {
      return (
        <Stack key={index} flexDirection="row" justifyContent="space-between" alignItems="center">
          <Stack flexDirection="row" gap="12px" pr="22px">
            <Circle>
              <img src={Checked} />
            </Circle>
            <CustomTypography color={colors.dark} type="bodyMRegular" title={t(saving.title)} />
          </Stack>
          <CustomTypography
            color={colors.error}
            type="bodyMSemibold"
            title={t(saving.price)}
            style={{ wordBreak: 'unset' }}
          />
        </Stack>
      );
    });
  }, [savingsList]);

  const usersMoreCards = useMemo(() => {
    return <UsersCardsAnimated>{users.slice(3, users.length)}</UsersCardsAnimated>;
  }, [showMoreCards]);

  const showMoreFn = () => {
    setShowMoreCards((prev) => !prev);
  };

  return (
    <Container>
      <CutOut bgColor={colors.nature} />
      <CustomTypography
        color={colors.dark}
        type={isMobile ? 'generalSansSmallSemibold' : 'generalSansMiddleSemibold'}
        title={t('titles.usersLoveYoga')}
        style={{
          textAlign: 'center',
          color: colors.dark,
          paddingBottom: isMobile ? '56px' : '96px',
        }}
      />
      {isMobile ? (
        <Stack gap="16px" width="100%" flexDirection="column">
          {users.slice(0, 3)}

          {showMoreCards && <FadeIn>{usersMoreCards}</FadeIn>}
        </Stack>
      ) : (
        <Stack gap="24px" pb="120px" flexDirection="column">
          <CustomCarousel items={usersCards} reversed={false} />
          <CustomCarousel
            items={usersCards}
            reversed={true}
            autoPlayInterval={700}
            animationDuration={3500}
          />
        </Stack>
      )}
      {isMobile &&
        (!showMoreCards ? (
          <div onClick={showMoreFn}>
            <CustomTypography
              color={colors.accentDark}
              type="headingS"
              title={t('buttons.showMore')}
              style={{
                textAlign: 'center',
                paddingBottom: '56px',
                marginTop: '24px',
              }}
            />
          </div>
        ) : (
          <div
            style={{
              paddingBottom: '56px',
              marginTop: '24px',
            }}
          />
        ))}
      <CustomTypography
        color={colors.dark}
        type={isMobile ? 'generalSansSmallSemibold' : 'generalSansMiddleSemibold'}
        title={t('titles.usersHaveToSay')}
        style={{
          textAlign: 'center',
          paddingBottom: '40px',
        }}
      />
      <Content>
        <VideoPlayer url="https://firebasestorage.googleapis.com/v0/b/yoga-on-sofa.appspot.com/o/web%2Freview.mp4?alt=media&token=e1895cec-2f03-4b51-9891-d378c8eed4ce" />
        <PulseWrapper
          isLight={false}
          style={isMobile ? { width: '100%', marginTop: '56px' } : { marginTop: '72px' }}
        >
          <CustomButton
            name={t('buttons.getMyPlan')}
            onClick={() => {
              onGetMyPlan(planCards[0].id);
              scrollToView('plansContainer');
            }}
            isLightTheme={false}
            style={isMobile ? { width: '100%' } : { width: '400px' }}
          />
        </PulseWrapper>
        <WrapperTrustpilot>
          <ExcellentTrustpilot isLight={true} border={true} />
        </WrapperTrustpilot>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap="56px"
          paddingLeft="20px"
          paddingRight="20px"
          sx={{
            '@media (max-width: 850px)': {
              flexDirection: 'column',
              gap: '32px',
            },
          }}
        >
          <Stack gap={isMobile ? '32px' : '16px'} alignItems="center">
            <CustomTypography
              color={colors.dark}
              type="generalSansSemi"
              title="$6,569.00"
              style={{
                fontSize: isMobile ? '56px' : '96px',
                wordBreak: 'unset',
              }}
            />
            <CustomTypography
              color={colors.dark}
              type={isMobile ? 'headingM' : 'generalSansSmallSemibold'}
              title={t('titles.muchSave')}
            />
          </Stack>
          <SavingsCard>
            {savings}
            <Line bgColor={colors.sandDark} style={{ marginTop: '8px' }} />
            <Stack flexDirection="row" justifyContent="space-between" mt="4px">
              <CustomTypography
                color={colors.dark}
                type="bodyMRegular"
                title={t('titles.totalValue')}
              />
              <CustomTypography
                color={colors.error}
                type="bodyMSemibold"
                title="$6,599.00"
                style={{ textAlign: 'center', wordBreak: 'unset' }}
              />
            </Stack>
            <Stack flexDirection="row" justifyContent="space-between">
              <CustomTypography
                color={colors.dark}
                type="bodyMRegular"
                title={t('titles.monthlyPlan')}
              />
              <CustomTypography color={colors.success} type="bodyMSemibold" title="$30.00" />
            </Stack>
            <Line bgColor={colors.sandDark} style={{ marginTop: '4px' }} />
            <Stack flexDirection="row" justifyContent="space-between" mt="4px">
              <CustomTypography color={colors.dark} type="headingM" title={t('titles.savings')} />
              <CustomTypography color={colors.success} type="headingM" title="$6,569.00" />
            </Stack>
          </SavingsCard>
        </Stack>
      </Content>
    </Container>
  );
};

export default Users;

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: ${colors.sand};
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  margin-top: -40px;
  padding: 96px 0 120px 0;
  @media (max-width: 768px) {
    padding: 72px 20px 72px 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
`;

const SavingsCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  background-color: ${colors.white};
  border: 1px solid ${colors.sandDark};
  padding: 48px 32px;
  border-radius: 40px;
  gap: 12px;
  @media (max-width: 768px) {
    padding: 32px 20px;
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${colors.sandAccent};
`;

const UsersCardsAnimated = styled(Stack)`
  gap: 16px;
  width: 100%;
  flex-direction: column;
`;

const WrapperTrustpilot = styled.div`
  max-width: 640px;
  width: 100%;
  padding-bottom: 96px;
  margin-top: 32px;
  @media (max-width: 768px) {
    padding-bottom: 56px;
    margin-top: 24px;
  }
`;
