import { Routes, Route } from 'react-router-dom';
import ErrorPage from './errorPage';
import HomePage from '@/pages/Home/HomePage';
import PromotionsPage from '@/pages/Promotions/PromotionsPage';
import NotFoundPage from '@/pages/NotFound/NotFoundPage';
import { ROUTES } from '../Constants';
import PaymentPage from '@/pages/Payment/PaymentPage';
import RegistrationPage from '@/pages/Registration/RegistrationPage';
import GetAppPage from '@/pages/GetApp/GetAppPage';
import ProtectRouter from './redirect';
import useLocalStorage from '../Hooks/useLocalStorage';
import DownloadApp from '@/pages/DownloadApp/DownloadApp';
import LegalPage from '@/pages/Legal/LegalPage';

const Routers = () => {
  const { paid, checkTerms, completedQuiz } = useLocalStorage();

  return (
    <Routes>
      <Route
        path={ROUTES.HOME}
        element={
          <ProtectRouter condition={!completedQuiz} redirectTo={ROUTES.PROMOTIONS}>
            <HomePage />
          </ProtectRouter>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path={ROUTES.REGISTRATION}
        element={
          <ProtectRouter condition={!!paid} redirectTo={ROUTES.PROMOTIONS}>
            <RegistrationPage />
          </ProtectRouter>
        }
        errorElement={<ErrorPage />}
      />
      <Route path="get-our-app">
        <Route path={ROUTES.GET_OUR_APP} element={<GetAppPage />} errorElement={<ErrorPage />} />
      </Route>
      <Route path="promotions">
        <Route path={ROUTES.PROMOTIONS} element={<PromotionsPage />} />
        <Route
          path={ROUTES.PAYMENT}
          element={
            <ProtectRouter condition={checkTerms} redirectTo={ROUTES.PROMOTIONS}>
              <PaymentPage />
            </ProtectRouter>
          }
        />
      </Route>
      <Route path={ROUTES.DOWNLOAD_APP} element={<DownloadApp />} errorElement={<ErrorPage />} />
      <Route path={ROUTES.PRIVACY_POLICY} element={<LegalPage />} errorElement={<ErrorPage />} />
      <Route path={ROUTES.TERMS_OF_USE} element={<LegalPage />} errorElement={<ErrorPage />} />
      <Route
        path={ROUTES.CANCELLATION_POLICY}
        element={<LegalPage />}
        errorElement={<ErrorPage />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Routers;
