import Wrapper from '@/components/Wrapper';
import { styled } from 'styled-components';
import { CustomTypography } from '@/components';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { MobilePhone, GooglePlay, AppleStore } from '@/assets';
import { sourceSet } from '@/utils/Helper';
import QRCode from 'react-qr-code';
import useIsPhone from '@/utils/Hooks/usePhone';
import { useEffect } from 'react';

const GetAppPage = () => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const downloadAppStore = () => {
    window.open('https://apps.apple.com/us/app/yoga-on-sofa/id6476162578', '_blank');
  };

  const downloadGooglePlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.yogaonsofa.app', '_blank');
  };
  return (
    <Wrapper maxWidth="760px" style={{ minHeight: '100vh' }}>
      <Content>
        <Stack style={{ maxWidth: '400px' }}>
          <CustomTypography
            type={isMobile ? 'generalSansSmallSemibold' : 'generalSansMiddleSemibold'}
            title={t('titles.getOurApp')}
            style={{
              lineHeight: isMobile ? '40px' : '56px',
              paddingBottom: isMobile ? '16px' : '24px',
              textAlign: isMobile ? 'center' : 'unset',
            }}
          />
          <CustomTypography
            type="bodyMedium"
            title={t(isMobile ? 'titles.readyStartMobile' : 'titles.readyStart')}
            style={{ textAlign: isMobile ? 'center' : 'unset' }}
          />
          <Stack flexDirection="row" alignItems="center" gap="24px" mt={isMobile ? '32px' : '56px'}>
            {!isMobile && (
              <div style={{ width: '200px', height: '200px' }}>
                <QRCode
                  size={256}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={'https://yoga-on-sofa-quiz.web.app/download-app'}
                  viewBox={`0 0 256 256`}
                />
              </div>
            )}
            <Stack
              gap="16px"
              width={isMobile ? '100%' : 0}
              flexDirection={isMobile ? 'row' : 'column'}
              justifyContent={isMobile ? 'center' : 'unset'}
            >
              <StoreContainer onClick={downloadAppStore}>
                <img src={AppleStore} />
              </StoreContainer>
              <StoreContainer onClick={downloadGooglePlay}>
                <img src={GooglePlay} />
              </StoreContainer>
            </Stack>
          </Stack>
        </Stack>
        {!isMobile && (
          <div>
            <img src={MobilePhone} srcSet={sourceSet(MobilePhone)} />
          </div>
        )}
      </Content>
    </Wrapper>
  );
};

export default GetAppPage;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 60px;
  padding: 56px 0 147px 0;
  @media (max-width: 760px) {
    padding: 0 20px 22px 20px;
    flex-direction: column;
  }
`;

const StoreContainer = styled.div`
  cursor: pointer;
`;
