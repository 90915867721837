import { Checked } from '@/assets';
import { colors } from '@/utils/Constants';
import styled from 'styled-components';

type Props = {
  isSelected: boolean;
  selectedBgColor: string;
  isAnimate?: boolean;
  width?: string;
  height?: string;
  iconSize?: string;
  icon?: string;
};

const Circle = ({
  isSelected,
  selectedBgColor,
  isAnimate,
  width,
  height,
  iconSize,
  icon,
}: Props) => {
  return (
    <Container
      $isSelected={isSelected}
      $selectedBgColor={selectedBgColor}
      $isAnimate={isAnimate}
      width={width}
      height={height}
    >
      {isSelected && <img src={icon || Checked} alt="Checked" width={iconSize} height={iconSize} />}
    </Container>
  );
};

export default Circle;

const Container = styled.div<{
  $isSelected: boolean;
  $selectedBgColor: string;
  $isAnimate?: boolean;
  width?: string;
  height?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || `40px`};
  height: ${({ height }) => height || `40px`};
  border-radius: 50%;
  background-color: ${({ $isSelected, $selectedBgColor }) =>
    $isSelected ? $selectedBgColor : colors.white};
  border: ${({ $isSelected }) => `2px solid ${$isSelected ? 'none' : colors.nature}`};
  transition: background-color 1s;
`;
