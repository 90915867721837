import { Stack } from '@mui/material';
import Slider from 'react-slick';
import { colors } from '@/utils/Constants';
import { useMemo, useState } from 'react';
import InfoItem from './InfoItem';
import { useTranslation } from 'react-i18next';
import { Calories, Health, MagicStar, Muscle, Weight } from '@/assets';
import { styled } from 'styled-components';

type Props = {
  answers: Record<string, any>;
};

export default function SlickSlider({ answers }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation();

  const bmiWeight = answers.currentWeight;
  const bmiHeight = answers.currentHeight;

  const bodyFat = useMemo(() => {
    const bmi = (bmiWeight / (bmiHeight * bmiHeight)) * 10000;
    let bodyFat = t('titles.low');
    if (bmi < 18.5) {
      bodyFat = t('titles.low');
    } else if (bmi < 25) {
      bodyFat = t('titles.normal');
    } else if (bmi < 35) {
      bodyFat = t('titles.high');
    } else {
      bodyFat = t('titles.dangerouslyHigh');
    }
    return bodyFat;
  }, [bmiHeight, bmiWeight, t]);

  const muscleDensity = useMemo(() => {
    let muscleDensity = t('titles.low');
    if (answers.exerciseFrequency === 'daily') {
      muscleDensity = t('titles.high');
    } else if (answers.exerciseFrequency === 'fewTimesAWeek') {
      muscleDensity = t('titles.medium');
    }
    return muscleDensity;
  }, [answers.exerciseFrequency, t]);

  const wallPilatesExperience = useMemo(() => {
    let wallPilatesExperience = t('titles.beginner');
    if (answers.triedApp === 'practiceRegularly') {
      wallPilatesExperience = t('titles.advanced');
    } else if (answers.triedApp === 'triedIt') {
      wallPilatesExperience = t('titles.intermediate');
    }
    return wallPilatesExperience;
  }, [answers.triedApp, t]);

  const harmonalBalance = useMemo(() => {
    let hormonalBalance = t('titles.poor');
    if (answers.energyLevels === 'highSteady') {
      hormonalBalance = t('titles.fullyBalanced');
    } else if (['draggingBeforeMeals', 'postLunchSlump'].includes(answers.energyLevels)) {
      hormonalBalance = t('titles.intermediate');
    }
    return hormonalBalance;
  }, [answers.energyLevels, t]);

  const metabolism = useMemo(() => {
    let metabolism = t('titles.slow');
    if (answers.fitnessExperience === 'hardToLoseWeight') {
      metabolism = t('titles.slow');
    } else if (answers.fitnessExperience === 'gainLoseEasily') {
      metabolism = t('titles.normal');
    } else if (answers.fitnessExperience === 'troubleGainingWeight') {
      metabolism = t('titles.fast');
    }
    return metabolism;
  }, [answers.fitnessExperience, t]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <Stack pl="10px" pr="10px">
      <Carousel
        {...settings}
        autoplay={true}
        autoplaySpeed={4000}
        afterChange={(e) => setCurrentIndex(e)}
      >
        <Stack>
          <InfoItem
            title={t('titles.bodyFat')}
            value={bodyFat}
            color={colors.error}
            image={Weight}
          />
          <InfoItem
            title={t('titles.muscleDensity')}
            value={muscleDensity}
            color={colors.error}
            image={Muscle}
          />
          <InfoItem
            title={t('titles.wallPilatesExperience')}
            value={wallPilatesExperience}
            color={colors.error}
            image={MagicStar}
          />
          <InfoItem
            title={t('titles.hormonalBalance')}
            value={harmonalBalance}
            color={colors.error}
            image={Calories}
          />
          <InfoItem
            title={t('titles.metabolism')}
            value={metabolism}
            color={colors.error}
            image={Health}
          />
        </Stack>
        <Stack>
          <InfoItem
            title={t('titles.bodyFat')}
            value={t('titles.normal')}
            color={colors.success}
            image={Weight}
          />
          <InfoItem
            title={t('titles.muscleDensity')}
            value={t('titles.high')}
            color={colors.success}
            image={Muscle}
          />
          <InfoItem
            title={t('titles.wallPilatesExperience')}
            value={t('titles.advanced')}
            color={colors.success}
            image={MagicStar}
          />
          <InfoItem
            title={t('titles.hormonalBalance')}
            value={t('titles.fullyBalanced')}
            color={colors.success}
            image={Calories}
          />
          <InfoItem
            title={t('titles.metabolism')}
            value={t('titles.normal')}
            color={colors.success}
            image={Health}
          />
        </Stack>
      </Carousel>
      <Stack
        style={{ gap: '4px', flexDirection: 'row', justifyContent: 'center', marginTop: '16px' }}
      >
        <FlatButton $bgColor={currentIndex === 0 ? colors.accent : colors.sandAccent} />
        <FlatButton $bgColor={currentIndex === 0 ? colors.sandAccent : colors.accent} />
      </Stack>
    </Stack>
  );
}

const FlatButton = styled.div<{ $bgColor: string }>`
  width: 56px;
  height: 4px;
  border-radius: 8px;
  background-color: ${({ $bgColor }) => $bgColor};
`;

const Carousel = styled(Slider)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
