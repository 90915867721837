import { BackgroundNatureMobile, BackgroundNature, YogaGirl3 } from '@/assets';
import { sourceSet } from '@/utils/Helper';
import useIsPhone from '@/utils/Hooks/usePhone';

type Props = {
  backgroundImage?: string;
};

const NatureBackground = ({ backgroundImage }: Props) => {
  const isMobile = useIsPhone();

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        marginBottom: isMobile ? '-3px' : 0,
      }}
    >
      <img
        src={backgroundImage || (isMobile ? BackgroundNatureMobile : BackgroundNature)}
        width="100%"
        style={{ marginTop: isMobile ? 0 : '-20.5vw' }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '3%',
          left: 0,
          right: 0,
        }}
      >
        <img
          src={YogaGirl3}
          srcSet={sourceSet(YogaGirl3)}
          style={{ width: isMobile ? '49vw' : '19.79vw' }}
        />
      </div>
    </div>
  );
};

export default NatureBackground;
