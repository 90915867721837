import { Star } from '@/assets';
import { colors } from '@/utils/Constants';
import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { styled } from 'styled-components';
import CustomTypography from '../Typography';
import { useTranslation } from 'react-i18next';
import { sourceSet } from '@/utils/Helper';

type Props = {
  name: string;
  photo: string;
  description: string;
};

const UserCard = ({ name, photo, description }: Props) => {
  const { t } = useTranslation();

  const stars = useMemo(() => {
    return Array.from({ length: 5 }, (_, index) => {
      return <img key={index} src={Star} height="24px" width="24px" />;
    });
  }, []);

  return (
    <Container>
      <Stack flexDirection="row" gap="16px">
        <Avatar src={photo} srcSet={sourceSet(photo)} />
        <Stack flexDirection="row" gap="5px" alignItems="center">
          {stars}
        </Stack>
      </Stack>
      <Stack gap="8px" mt="8px">
        <CustomTypography color={colors.dark} type="bodySemibold" title={name} />
        <CustomTypography color={colors.dark} type="bodyMRegular" title={t(description)} />
      </Stack>
    </Container>
  );
};

export default UserCard;

const Container = styled.div`
  width: 100%;
  max-width: 320px;
  background-color: ${colors.white};
  border-radius: 24px;
  border: 1px solid ${colors.sandDark};
  padding: 32px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Avatar = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
`;
