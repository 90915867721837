import { useEffect } from 'react';

const DownloadApp = () => {
  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.yogaonsofa.app';
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.location.href = 'https://apps.apple.com/us/app/yoga-on-sofa/id6476162578';
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.yogaonsofa.app';
    }
  }, []);

  return null;
};

export default DownloadApp;
