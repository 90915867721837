import RemoteConfigProvider from './utils/Contexts/RemoteConfigContext';
import Routers from './utils/Routes/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'locale';
import ConfigContextProvider from './utils/Contexts/ConfigContext';
import i18n from '@/utils/Locale';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { logout } from './utils/AuthMiddleware';
import Bugsnag from '@bugsnag/js';
import { trackMetaPixel } from './utils/Tracking';

function App() {

  const checkLastVisit = () => {
    const lastVisit = localStorage.getItem('lastVisit');
    const now = new Date().getTime();
    const oneDayInMs = 24 * 60 * 60 * 1000; // One day in milliseconds
  
    if (lastVisit && (now - parseInt(lastVisit) > oneDayInMs)) {
      localStorage.clear(); // Clear all local storage
      logout().then(() => {
        console.log("User has been logged out due to inactivity.");
        // Redirect to login page or update state as needed
        window.location.reload();
      }).catch((error) => {
        console.error("Error logging out:", error);
        Bugsnag.notify(error);
      });
    } else {
      // Update last visit time since user is active
      updateLastVisit();
    }
  };
  
  const updateLastVisit = () => {
    const now = new Date().getTime(); // Get current time in milliseconds
    localStorage.setItem('lastVisit', now.toString());
  };

  const location = useLocation();

  const initialOptions = {
    clientId: import.meta.env.VITE_PAYPAL_CLIENT_ID,
    vault: true,
    currency: 'USD',
    intent: 'subscription',
  };

  useEffect(() => {
    checkLastVisit();

    const searchParams = new URLSearchParams(location.search);
    const queryLanguage = searchParams.get('lang');
    const language = localStorage.getItem('language');

    if (language) {
      localStorage.setItem('language', language);
      i18n.changeLanguage(language);
    } else if (queryLanguage) {
      localStorage.setItem('language', queryLanguage);
      i18n.changeLanguage(queryLanguage);
    } else if (!language) {
      localStorage.setItem('language', 'en');
    }
  }, []);

  useEffect(() => {
    trackMetaPixel(true, 'PageView');
    Bugsnag.leaveBreadcrumb('Page View', { type: 'navigation', metadata: { path: location.pathname, search: location.search } });
  }, [location]);
  
  return (
    <RemoteConfigProvider>
      <ConfigContextProvider>
        <PayPalScriptProvider options={initialOptions}>
          <ToastContainer />
          <Routers />
        </PayPalScriptProvider>
      </ConfigContextProvider>
    </RemoteConfigProvider>
  );
}

export default App;