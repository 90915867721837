import { Check, Checked, LockBlack, Visa, MasterCard, Amex, PayPal } from '@/assets';
import { checkPoints, colors, fadeIn, fadeOut, planCards } from '@/utils/Constants';
import useLocalStorage from '@/utils/Hooks/useLocalStorage';
import { Stack } from '@mui/material';
import { useContext, useMemo, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ExcellentTrustpilot from '../ExcellentTrustpilot';
import Label from '../Label';
import PlanCard from '../PlanCard';
import CustomTypography from '../Typography';
import { ConfigContext } from '@/utils/Contexts/ConfigContext';
import styled, { css, keyframes } from 'styled-components';
import Toast from '../Toast';
import useIsPhone from '@/utils/Hooks/usePhone';
import useWindowSize from '@/utils/Hooks/useWindowSize';

type Props = {
  onGetMyPlan: (planId: string) => void;
  checkAgreement: () => void;
  checkedTerms: boolean;
  errorTerms: boolean;
  shakeCheckbox: boolean;
};

const PersonalPlan = ({
  onGetMyPlan,
  checkAgreement,
  checkedTerms,
  errorTerms,
  shakeCheckbox,
}: Props) => {
  const { t } = useTranslation();
  const { legal } = useContext(ConfigContext);
  const isMobile = useIsPhone();
  const width = useWindowSize();
  const { measurement, weight, gainLoseWeight, gainWeight } = useLocalStorage();
  const checkboxRef = useRef(null);

  const payCardsImages = useMemo(() => {
    return (
      <Stack flexDirection="row" gap="4px">
        <img src={Visa} />
        <img src={MasterCard} />
        <img src={Amex} />
        <img src={PayPal} />
      </Stack>
    );
  }, []);

  return (
    <Stack
      width="100%"
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent="center"
      pl="20px"
      pr="20px"
    >
      {isMobile && (
        <Stack mt="8px">
          <ExcellentTrustpilot isLight={true} border={true} justTrustpilot={isMobile} />
        </Stack>
      )}
      <Content>
        {!isMobile && <ExcellentTrustpilot isLight={true} border={true} />}
        <CustomTypography
          color={colors.dark}
          type="generalSans"
          title={
            !weight || weight === 0
              ? t('titles.boostFitness')
              : t('titles.losePersonal', {
                  gainWeight: gainLoseWeight,
                  weight: Math.abs(weight),
                  measurement,
                })
          }
          style={
            isMobile
              ? { textAlign: 'center', marginTop: '0px', marginBottom: '8px', fontSize: '32px' }
              : { textAlign: 'center', marginTop: '24px', marginBottom: '16px' }
          }
        />
        <Wrapper style={{ marginBottom: '8px' }}>
          <Trans
            i18nKey={t('titles.loseWeight', {
              weight: gainWeight ? t('titles.gainWeight') : t('answers.loseWeight'),
            })}
            components={{
              bold: <a style={{ color: colors.dark, fontFamily: 'GeneralSans-Bold' }}></a>,
            }}
          />
        </Wrapper>
        {!isMobile && (
          <Stack gap="12px" pb="40px">
            {checkPoints.map(({ title }) => (
              <Stack flexDirection="row" key={title} gap="12px">
                <img src={Check} alt="check" />
                <CustomTypography title={t(title)} type="bodySemibold" color={colors.dark} />
              </Stack>
            ))}
          </Stack>
        )}
        <Stack flexDirection="row" paddingBottom="56px">
          <Stack width="100%">
            <CardsContainer $isLoaded={Boolean(width)} id="plansContainer">
              {planCards.map((plan, index) => (
                <div key={index} style={{ position: 'relative', width: '100%' }}>
                  {plan?.labelTitle && (
                    <div style={{ position: 'absolute', top: -17, left: 16 }}>
                      <Label
                        title={plan.labelTitle}
                        style={{
                          backgroundColor: plan.borderColor,
                          padding: '4px 12px',
                          minHeight: '32px',
                        }}
                        icon={plan.icon}
                      />
                    </div>
                  )}
                  <PlanCard onGetMyPlan={onGetMyPlan} {...plan} />
                </div>
              ))}
            </CardsContainer>
            {isMobile && (
              <Stack alignItems="center" mt="16px">
                {payCardsImages}
              </Stack>
            )}
            <Stack gap="24px" mt="24px">
              <CheckboxContainer ref={checkboxRef} $isShake={shakeCheckbox} id="checkbox">
                <div style={{ cursor: 'pointer' }} onClick={checkAgreement}>
                  {checkedTerms ? (
                    <CheckboxChecked>
                      <img src={Checked} />
                    </CheckboxChecked>
                  ) : (
                    <CheckboxUnchecked />
                  )}
                </div>
                <div style={{ fontFamily: 'GeneralSans-Medium', fontSize: 14 }}>
                  <Trans
                    i18nKey={'titles.agreeTerms'}
                    components={
                      {
                        linkTerms: (
                          <a
                            href={legal.termsUrlWeb}
                            target="_blank"
                            style={{ color: colors.accentDark }}
                          ></a>
                        ),
                        linkPrivacy: (
                          <a
                            href={legal.privacyPolicyUrlWeb}
                            target="_blank"
                            style={{ color: colors.accentDark }}
                          ></a>
                        ),
                        lnkCancellation: (
                          <a
                            href={legal.cancellationPolicyUrl}
                            target="_blank"
                            style={{ color: colors.accentDark }}
                          ></a>
                        ),
                        privacyPolicy: t('titles.privacyPolicy'),
                        termsAndCondition: t('titles.termsAndCondition'),
                        cancellationsPolicy: t('titles.cancellationsPolicy'),
                      } as any
                    }
                  />
                </div>
              </CheckboxContainer>
              {errorTerms && (
                <Toast title={t('errors.checkConditions')} style={{ marginTop: '-12px' }} />
              )}
              <Stack flexDirection="row" alignItems="center" gap="8px">
                <img src={LockBlack} />
                <CustomTypography
                  title={t('titles.guaranteed')}
                  type="bodyMRegular"
                  color={colors.dark}
                />
                {!isMobile && payCardsImages}
              </Stack>

              <CustomTypography
                title={t('titles.avoidDisruption')}
                type="bodySRegular"
                color={colors.dark}
              />
            </Stack>
          </Stack>
        </Stack>
      </Content>
    </Stack>
  );
};

export default PersonalPlan;

const Wrapper = styled.div`
  padding-bottom: 24px;
  text-align: center;
  font-family: 'GeneralSans-Regular';
`;

const Content = styled.div`
  max-width: 960px;
  padding: 16px 0px;
  @media (max-width: 768px) {
    padding: 12px 0px 0px 0px;
  }
`;

const CardsContainer = styled.div<{ $isLoaded: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 24px;
  visibility: ${($isLoaded) => (!$isLoaded ? 'hidden' : 'visible')};
  animation: ${($isLoaded) => (!$isLoaded ? fadeOut : fadeIn)} 0.8s linear;
  transition: visibility 0.8s linear;
  @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    },
    @media (max-width: 768px) {
      gap:20px;
    },
`;

const CheckboxUnchecked = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid ${colors.dark};
  border-radius: 8px;
`;

const CheckboxChecked = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.dark};
  background-color: ${colors.dark};
  border-radius: 8px;
`;

const CheckboxContainer = styled.div<{ $isShake: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  animation: ${(props) => (props.$isShake ? shakeAnimation : '')};
`;

const shake = keyframes`
  20% {
    transform: translateX(10px);
  }
  40% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(-10px);
  }
`;

const shakeAnimation = css`
  ${shake} 0.3s
`;
