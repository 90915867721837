import { ArrowLeft } from '@/assets';
import { Stack } from '@mui/material';
import LogoContainer from '../Logo';
import Svg from '../Svg';
import { useNavigate } from 'react-router-dom';
import useIsPhone from '@/utils/Hooks/usePhone';
import { styled } from 'styled-components';

type Props = {
  color: string;
  content?: JSX.Element;
};

const Header = ({ color, content }: Props) => {
  const navigate = useNavigate();
  const isMobile = useIsPhone();

  const backToPromotions = () => {
    localStorage.setItem('prevPathname', location.pathname);
    navigate(-1);
  };

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      mb={isMobile ? '16px' : '80px'}
      sx={{
        '@media (min-width: 768px)': {
          marginBottom: '80px',
        },
        '@media (max-width: 768px)': {
          marginBottom: '16px',
        },
      }}
    >
      <ArrowBack onClick={backToPromotions}>
        <Svg svgImage={<ArrowLeft />} fill={color} height="24px" width="24px" />
      </ArrowBack>
      {content || <LogoContainer isColumn={isMobile} />}
      <div style={{ width: '24px', height: '24px' }} />
    </Stack>
  );
};

export default Header;

const ArrowBack = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  &:hover {
    transition: 0.3s;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0.8;
  }
`;
