import { Processing, Progress, QuizPages } from '@/components';
import { RemoteConfigContext } from '@/utils/Contexts/RemoteConfigContext';
import { Page } from '@/utils/Types';
import { QuizQuestions } from '@/utils/QuizConfig';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { colors, fadeIn, fadeOut } from '@/utils/Constants';
import { Trans, useTranslation } from 'react-i18next';
import { IconButton, Stack, Typography } from '@mui/material';
import useCountdownTimer from '@/utils/Hooks/useCountdownTimer';
import { ArrowLeft } from '@/assets';
import SecureInfo from '@/components/SecureInfo';
import Svg from '@/components/Svg';
import SubHeader from '@/components/SubHeader';
import useIsPhone from '@/utils/Hooks/usePhone';
import Loader from '@/components/Loader';
import useLocalStorage from '@/utils/Hooks/useLocalStorage';
import { trackMetaPixel } from '@/utils/Tracking';
import Ads from '@/components/Ads';
import AsSeenOn from '@/components/AsSeenOn';
import useWindowSize from '@/utils/Hooks/useWindowSize';

const HomePage = () => {
  const { t } = useTranslation();
  const { minutes, seconds } = useCountdownTimer();
  const localStorageCurrentPage = localStorage.getItem('currentPage');
  const localStorageData = localStorage.getItem('quizStorage');
  const isMobile = useIsPhone();
  const width = useWindowSize();
  const { finishedQuiz } = useLocalStorage();

  const remoteConfigValues = useContext(RemoteConfigContext);

  const [pages, setPages] = useState<Page[]>([]);
  const [currentPage, setCurrentPage] = useState(
    localStorageCurrentPage ? parseInt(localStorageCurrentPage, 10) : 0
  );
  const [answers, setAnswers] = useState<{ key: string; value: string } | {}>(
    localStorageData ? JSON.parse(localStorageData) : {}
  );

  useEffect(() => {
    trackMetaPixel(false, `Quiz_Step_${currentPage + 1}`);
  }, [currentPage]);

  useEffect(() => {
    if (
      isMobile && pages && pages[currentPage] &&
      (pages[currentPage]?.params.id === 'currentHeight' ||
        pages[currentPage]?.params.id === 'currentWeight' ||
        pages[currentPage]?.params.id === 'targetWeight' ||
        pages[currentPage]?.params.id === 'currentAge' ||
        pages[currentPage]?.params.id === 'name')
    ) {
      return;
    }
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 300);
  }, [currentPage, isMobile, pages]);

  useEffect(() => {
    const value = remoteConfigValues?.quizOrder
      .map((item: string) => {
        // @ts-ignore
        let component = QuizQuestions[item] ? QuizPages.answers : QuizPages[item];
        let params: any = { id: item };
        if (QuizQuestions[item]) {
          params = {
            id: item,
            ...QuizQuestions[item],
          };
        } else if (!component) {
          if (['currentHeight', 'currentWeight', 'targetWeight', 'currentAge'].includes(item)) {
            component = QuizPages.measurements;
          } else if (['name'].includes(item)) {
            component = QuizPages.input;
          }
        }

        return { id: item, component, params };
      })
      .filter((item: Page) => item.component !== undefined) as Page[];

    value?.unshift({
      id: 'gender',
      component: QuizPages.gender,
      params: {
        question: 'questions.whtsUrGender',
        answers: [
          { id: 'male', title: 'answers.male' },
          { id: 'female', title: 'answers.female' },
          { id: 'non-binary', title: 'answers.preferNotSay' },
        ],
      },
    });

    value?.push({
      id: 'processing',
      component: QuizPages.processing,
      params: {},
    } as Page);
    setPages(value);
  }, [remoteConfigValues]);

  const onSelect = useCallback(
    (key: string, value: string) => {
      if (key && value) {
        setAnswers((prev) => ({ ...prev, [key]: value }));
      }
      const localStorageCurrentPage = localStorage.getItem('currentPage');
      localStorage.setItem(
        'currentPage',
        localStorageCurrentPage ? (currentPage + 1).toString() : '1'
      );
      setCurrentPage((currentPage) => currentPage + 1);
    },
    [currentPage, setAnswers]
  );

  useEffect(() => {
    localStorage.setItem('quizStorage', JSON.stringify(answers));
  }, [answers]);

  const renderPage = useCallback(() => {
    if (pages && pages?.length && pages[currentPage]?.component) {
      const PageComponent = pages[currentPage].component;
      return (
        <PageComponent
          params={{ id: pages[currentPage].id, ...pages[currentPage].params }}
          onSelect={onSelect}
          setAnswers={setAnswers}
          answers={answers}
          setCurrentPage={setCurrentPage}
        />
      );
    }
    return null;
  }, [currentPage, onSelect, pages, answers]);

  const progressValue = useMemo(() => {
    if (currentPage === 0 || pages?.length === 0) return 0;
    return (100 / pages?.length) * (currentPage + 1);
  }, [currentPage, pages]);

  const messageValues = useMemo(() => {
    return {
      current: currentPage,
      total: pages?.length - 2,
    };
  }, [currentPage, pages]);

  const isLight = useMemo(() => {
    const currentPageId = pages?.[currentPage]?.params?.id;

    if (
      currentPageId === 'currentHeight' ||
      currentPageId === 'currentWeight' ||
      currentPageId === 'targetWeight' ||
      currentPageId === 'currentAge' ||
      currentPageId === 'name' ||
      currentPageId === 'bmiPreview' ||
      currentPageId === 'eventDate'
    ) {
      return false;
    }
    if ((!currentPageId && isMobile) || currentPage === 0) {
      return true;
    }
    if (!currentPageId) {
      return undefined;
    }
    return true;
  }, [currentPage, isMobile, pages]);

  const renderAsSeenOn = useMemo(() => {
    let value = '0px';
    if (isLight) {
      value = '88px';
    }

    if (!pages || !pages[currentPage]) {
      return null;
    }

    if (
      pages[currentPage]?.params.id === 'bmiPreview' ||
      pages[currentPage]?.params.id === 'eventDate'
    ) {
      value = '120px';
    }
    if (pages[currentPage]?.params.id === 'eventDate' && isMobile) {
      value = '40px';
    }
    if (currentPage === 0 && isMobile) {
      value = '42px';
    }
    if (currentPage === 0 && !isMobile) {
      value = '96px';
    }
    if (currentPage !== 0 && isMobile && isLight) {
      value = '40px';
    }
    if (currentPage !== 0 && !isMobile && isLight) {
      value = '88px';
    }
    if (
      (pages[currentPage]?.params.id === 'bmiPreview' ||
        pages[currentPage]?.params.id === 'predictionComparison') &&
      isMobile
    ) {
      return null;
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          paddingLeft: currentPage === 0 && width < 360 ? '20px' : 0,
          paddingRight: currentPage === 0 && width < 360 ? '20px' : 0,
          paddingTop: value,
        }}
      >
        <AsSeenOn isLight={isLight} />
      </div>
    );
  }, [isLight, currentPage, isMobile, width, pages]);

  if (!pages?.length) {
    return (
      <LoaderContainer $isLoaded={Boolean(pages?.length)}>
        <Loader />
      </LoaderContainer>
    );
  }
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      position="relative"
      minHeight="100vh"
    >
      <FlashSale>
        <Typography
          color={colors.sand}
          style={{ fontFamily: 'GeneralSans-Semibold', fontSize: isMobile ? '14px' : '16px' }}
        >
          <span>&#128293;</span>
          {t('titles.flashSale').toUpperCase()}
          <span>&#128293;</span>
        </Typography>
        <Timer>
          00d 00h {minutes}m {seconds}s
        </Timer>
      </FlashSale>
      <Wrapper $isLight={isLight} $currentPage={currentPage}>
        <SubHeader isLight={isLight} />
        <Ads isLight={isLight} />
        {!finishedQuiz ? (
          <Content $currPage={currentPage} $isMobile={isMobile} $isLoaded={Boolean(pages?.length)}>
            {currentPage !== 0 && (
              <Stack>
                <ProgressContainer $isMobile={isMobile}>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() => {
                      setCurrentPage((currentPage) => currentPage - 1);
                    }}
                  >
                    <Svg
                      fill={isLight ? colors.dark : colors.sand}
                      svgImage={<ArrowLeft />}
                      width="24px"
                      height="24px"
                    />
                  </IconButton>
                  <Progress value={progressValue} />
                  <div style={{ width: '24px', height: '24px' }} />
                </ProgressContainer>
                <StepCounter $isLight={isLight}>
                  <Trans
                    i18nKey={'titles.steps'}
                    values={messageValues}
                    components={{ italic: <i />, bold: <strong /> }}
                  />
                </StepCounter>
              </Stack>
            )}
            {renderPage()}
            {renderAsSeenOn}

            {(pages[currentPage]?.params.id === 'bmiPreview' ||
              pages[currentPage]?.params.id === 'predictionComparison') &&
            isMobile ? null : (
              <div style={{ padding: currentPage === 0 ? '0px 20px' : 0 }}>
                <SecureInfo
                  isLight={isLight}
                  currentPage={currentPage}
                  showSecureText={currentPage === 0}
                  secureText="titles.secureByResponse"
                  isMultiple={Boolean(pages?.length && pages[currentPage]?.params?.multiple)}
                />
              </div>
            )}
          </Content>
        ) : (
          <Processing />
        )}
      </Wrapper>
    </Stack>
  );
};

export default HomePage;

const StepCounter = styled.div<{ $isLight?: boolean | undefined }>`
  margin-top: 24px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 14px;
  font-family: 'GeneralSans-Medium';
  color: ${({ $isLight }) => (!$isLight ? colors.sand : colors.accentDark)};
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const FlashSale = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.accentDark};
  flex-direction: row;
  gap: 36px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
  @media (max-width: 320px) {
    flex-direction: column;
    gap: 8px;
  }
`;

const Timer = styled.div`
  font-size: 16px;
  font-family: 'GeneralSans-Semibold';
  color: ${colors.error};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const changeBgColorSandToAccent = keyframes`
  from {
    background-color: ${colors.sand};
    }
  to {
    background-color: ${colors.accent};
    }
  `;
const changeBgColorAccentToSand = keyframes`
  from {
    background-color: ${colors.accent};
    }
  to {
    background-color: ${colors.sand};
    }
  `;

const processingStepBg = keyframes`
  from {
    background-color: ${colors.nature};
    }
  to {
    background-color: ${colors.nature};
    }
  `;

const Wrapper = styled.div<{ $isLight?: boolean; $currentPage: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 40px;
  width: 100%;
  min-height: 100vh;
  flex: 1;
  animation: ${({ $isLight, $currentPage }) =>
    css`
      ${$isLight
        ? changeBgColorAccentToSand
        : $isLight === false
        ? changeBgColorSandToAccent
        : processingStepBg} ${$currentPage !== 0 ? '1s' : '0s'} forwards;
    `};
  @media (max-width: 320px) {
    padding-top: 65px;
  }
`;

const Content = styled.div<{ $currPage: number; $isMobile: boolean; $isLoaded: boolean }>`
  width: 100%;
  max-width: ${({ $currPage }) => ($currPage === 0 ? '100%' : '480px')};
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ $isMobile, $currPage }) => ($isMobile && $currPage !== 0 ? '0 20px' : '0 0px')};
  visibility: ${($isLoaded) => (!$isLoaded ? 'hidden' : 'visible')};
  animation: ${($isLoaded) => (!$isLoaded ? fadeOut : fadeIn)} 0.5s linear;
  transition: visibility 0.5s linear;
`;

const ProgressContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 126px;
  padding-top: 32px;
  padding-bottom: 24px;
  ${({ $isMobile }) =>
    $isMobile &&
    `
    display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     gap: 10px;
     padding-top: 32px;
     padding-bottom: 24px;
  `}
`;

const LoaderContainer = styled.div<{ $isLoaded: boolean }>`
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${colors.sand};
  animation: ${($isLoaded) => ($isLoaded ? fadeOut : fadeIn)} 0.5s linear;
  transition: visibility 0.5s linear;
`;
