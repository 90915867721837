import { Lamp } from '@/assets';
import { colors } from '@/utils/Constants';
import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import CustomTypography from './Typography';
import useLocalStorage from '@/utils/Hooks/useLocalStorage';
import { bmi } from '@/utils/Helper';

type Props = {
  question: string;
  inputValue: string;
  unit: string;
};

const BMICard = ({ question, inputValue, unit }: Props) => {
  const { t } = useTranslation();
  const { answers } = useLocalStorage();

  const content = useMemo(() => {
    let title = '';
    let text = '';
    let bmiCategory = '';
    let action = '';
    let percentage = '';

    if (question === 'currentWeight') {
      const bmiIndex = +bmi(answers, inputValue);
      if (bmiIndex < 18.5) {
        bmiCategory = 'underweight';
      }
      if (bmiIndex >= 18.5 && bmiIndex <= 24.9) {
        bmiCategory = 'normal';
      }
      if (bmiIndex >= 25 && bmiIndex <= 29.9) {
        bmiCategory = 'overweight';
      }
      if (bmiIndex >= 30) {
        bmiCategory = 'obese';
      }
      title = t(`titles.${question}`, { bmi: bmiIndex, bmiCategory });
      text = t(`messages.${bmiCategory}`);
    } else if (question === 'targetWeight') {
      if (
        parseInt(inputValue, 10) > parseInt(answers.currentWeight, 10) ||
        parseInt(inputValue, 10) === parseInt(answers.currentWeight, 10)
      ) {
        action = 'gain';
      } else {
        action = 'lose';
      }
      percentage = (
        ((parseInt(inputValue, 10) - parseInt(answers.currentWeight, 10)) /
          parseInt(answers.currentWeight, 10)) *
        100
      )
        .toFixed(0)
        .replace('-', '');
      title = percentage === '0' 
        ? t('titles.targetWeightNeutral')
        : t(`titles.${question}`, { action, percentage });
      text = t(`messages.${question}`);
    } else {
      title = t(`titles.${question}`);
      text = t(`messages.${question}`);
    }
    return (
      <>
        <Stack flexDirection="row" gap="4px" alignItems="center">
          <img src={Lamp} width="24px" height="24px" />
          <CustomTypography title={title} type="bodyMSemibold" />
        </Stack>
        <CustomTypography title={text} type="bodyMRegular" />
      </>
    );
  }, [question, t, bmi, answers, inputValue, unit]);

  return <Container>{content}</Container>;
};

export default BMICard;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 16px;
  background-color: ${colors.sand};
  border-radius: 16px;
`;
