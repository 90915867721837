import { useState, useLayoutEffect } from 'react';

const useIsPhone = () => {
  const [device, setDevice] = useState(window.innerWidth <= 768 ? 'Mobile' : 'Desktop');

  useLayoutEffect(() => {
    const handleDeviceDetection = () => {
      if (window.innerWidth <= 768) {
        setDevice('Mobile');
      } else {
        setDevice('Desktop');
      }
    };

    window.addEventListener('resize', handleDeviceDetection);

    return () => {
      window.removeEventListener('resize', handleDeviceDetection);
    };
  }, []);
  return device === 'Mobile';
};

export default useIsPhone;
