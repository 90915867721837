import { colors, planCards } from '@/utils/Constants';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import styled from 'styled-components';
import { CustomTypography } from '.';
import CustomButton from './Button';
import useCountdownTimer from '@/utils/Hooks/useCountdownTimer';
import LogoContainer from './Logo';
import PulseWrapper from './PulseWrapper';
import { useMemo } from 'react';
import useWindowSize from '@/utils/Hooks/useWindowSize';
import useIsPhone from '@/utils/Hooks/usePhone';
import { scrollToView } from '@/utils/Helper';

interface Props {
  getPlan: (planId: string) => void;
}

const Header = ({ getPlan }: Props) => {
  const { minutes, seconds } = useCountdownTimer();
  const width = useWindowSize();
  const isMobile = useIsPhone();

  const mobilePriceHeader = useMemo(() => {
    return (
      <PriceReservedContainer>
        <CustomTypography
          title={t('titles.priceReserved')}
          color={colors.sand}
          type="bodySemibold"
        />
        <Stack flexDirection="row" gap="3px" width="50px">
          <CustomTypography title={minutes.toString()} color={colors.error} type="bodySemibold" />
          <CustomTypography title={':'} color={colors.error} type="bodySemibold" />
          <CustomTypography title={seconds.toString()} color={colors.error} type="bodySemibold" />
        </Stack>
      </PriceReservedContainer>
    );
  }, [minutes, seconds]);

  const priceReservedText = useMemo(() => {
    return (
      <>
        <CustomTypography
          title={t('titles.priceReserved')}
          color={colors.sand}
          type="headingM"
          style={{ marginRight: '24px' }}
        />
        <Stack flexDirection="row" gap="3px" mr="32px">
          <CustomTypography
            title={minutes.toString()}
            color={colors.accentDark}
            type="headingL"
            style={{ width: '40px', textAlign: 'right' }}
          />
          <CustomTypography title={':'} color={colors.accentDark} type="headingL" />
          <CustomTypography
            title={seconds.toString()}
            color={colors.accentDark}
            type="headingL"
            style={{ width: '40px' }}
          />
        </Stack>
      </>
    );
  }, [minutes, seconds]);

  return (
    !!width && (
      <Stack style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 100 }}>
        {width <= 850 && mobilePriceHeader}

        <Container>
          <LogoContainer
            width={isMobile ? '40px' : '56px'}
            height={isMobile ? '40px' : '56px'}
            textType="bodyMSemibold"
          />{' '}
          <Stack flexDirection="row" alignItems="center">
            {width >= 850 && priceReservedText}
            <PulseWrapper>
              <CustomButton
                onClick={() => {
                  getPlan(planCards[0].id);
                  scrollToView('plansContainer');
                }}
                name={t('buttons.getMyPlan')}
                style={{ padding: width <= 850 ? '8px 25px' : '20px 75px' }}
                type="filled"
              />
            </PulseWrapper>
          </Stack>
        </Container>
      </Stack>
    )
  );
};

export default Header;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.nature};
  padding: 16px 32px;
  @media (max-width: 850px) {
    padding: 12px 20px;
  }
`;

const PriceReservedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${colors.accentDark};
  padding: 8px 0px;
  gap: 16px;
`;
