import styled from 'styled-components';
import CutOut from './CutOut';

type Props = {
  children: React.ReactNode;
  bgColor: string;
  cutOutColor: string;
  style?: React.CSSProperties;
  isMobile?: boolean;
};

const Card = ({ children, bgColor, cutOutColor, style, isMobile }: Props) => {
  return (
    <Container $bgColor={bgColor} $isMobile={isMobile} style={style}>
      <CutOut bgColor={cutOutColor} />
      {children}
    </Container>
  );
};

export default Card;

const Container = styled.div<{ $bgColor: string; $isMobile?: boolean }>`
  position: relative;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: ${({ $isMobile }) => ($isMobile ? '0px' : '40px')};
  border-bottom-left-radius: ${({ $isMobile }) => ($isMobile ? '0px' : '40px')};
  padding: ${({ $isMobile }) => ($isMobile ? '48px 20px 20px 20px' : '48px 31px')};
  background-color: ${({ $bgColor }) => $bgColor};
`;
