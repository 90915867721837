import Bugsnag from '@bugsnag/js';
import { signInAnonymously, linkWithCredential, EmailAuthProvider, GoogleAuthProvider, getRedirectResult, linkWithRedirect, signOut, FacebookAuthProvider } from 'firebase/auth';
import { httpsCallable } from "firebase/functions";
import { AVATAR_IMAGES } from './Constants';
import { setCollectionDoc } from './FirebaseMiddleware';
import { auth, functions } from './firebaseInit';
import { serverTimestamp } from 'firebase/firestore';

export const transferSubscription = async (targetEmail: string) => {
  const addMessage = await httpsCallable(functions, 'paypal-transferSubscription')({ targetEmail })
  // console.log('transferSubscription success', addMessage.data);  
  if (!(addMessage.data as any)?.success) {
    throw new Error((addMessage.data as any)?.message);    
  }
}

export const logout = async () => {
  await signOut(auth);
}

export const signInAnonymous = async () => {
  try {
    if (auth.currentUser) {
      await logout();
    }
    await signInAnonymously(auth);
    if (auth.currentUser) { // init user doc
      await setCollectionDoc('users', auth.currentUser.uid, {
        createdAt: serverTimestamp(),
      });  
    }
  } catch (error) {
    console.log(error);
    Bugsnag.notify(new Error(error as string));
    throw error;
  }
}

export const linkWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({   
    prompt : "select_account "
  });
  if (auth.currentUser) {
    await linkWithRedirect(auth.currentUser, provider);
  }
}

export const linkWithFacebook = async () => {
  const provider = new FacebookAuthProvider();
  if (auth.currentUser) {
    await linkWithRedirect(auth.currentUser, provider);
  }
}

export const getRedirectRes = async () => {
  return getRedirectResult(auth);
}

export const linkWithEmailPassword = async (email: string, password: string) => {
  try {
    const credential = EmailAuthProvider.credential(email, password);
    if (auth.currentUser) {
      await linkWithCredential(auth.currentUser, credential);
    }
  } catch (error) {
    console.log('linkWithEmailPassword error', (error as any).code);
    Bugsnag.notify(new Error(error as string));
    if ([
      'auth/email-already-in-use', 
      'auth/credential-already-in-use',
      'auth/account-exists-with-different-credential',
      'auth/provider-already-linked'
    ].includes((error as any).code)) {
      await transferSubscription(email);
      return
    }
    throw error;
  }
}

export const getCurrentUser = () => {
  return auth.currentUser
}

export const setUserInfoAfterRegistration = async(userUid: string, firstName: string, lastName: string, answers: { [k: string]: string | string[] }) => {
  const avatars = AVATAR_IMAGES.filter(a => a.randomType === (answers.gender || 'non-binary'))
  await setCollectionDoc('users', userUid || '', {
    firstName,
    lastName,
    ...answers.currentAge && {age: parseInt(answers.currentAge as string, 10)},
    avatarId: avatars[Math.floor(Math.random() * avatars.length)].id,
    measurementSystemMetric: answers.measurementSystemMetric === 'metric',
    ...answers.currentWeight && {weight: parseInt(answers.currentWeight as string, 10)},
  });
}