import React from 'react';
import { styled } from 'styled-components';

type Props = {
  svgImage: React.ReactNode;
  fill?: string;
  width?: string;
  height?: string;
  stroke?: string;
};

const Svg = ({ svgImage, fill, width, height, stroke }: Props) => {
  return (
    <StyledSVG fill={fill} width={width} height={height} stroke={stroke}>
      {svgImage}
    </StyledSVG>
  );
};

export default Svg;

const StyledSVG = styled.svg<{
  fill?: string;
  width?: string;
  height?: string;
  stroke?: string;
}>`
  & path {
    fill: ${({ fill }) => fill};
    stroke: ${({ stroke }) => (stroke ? stroke : 'none')};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }
`;
