import { useState, useEffect } from 'react';

export default function useCountdownTimer() {
  const totalTimeSec = 15 * 60;

  const [remainingTime, setRemainingTime] = useState(totalTimeSec);

  useEffect(() => {
    const interval = setInterval(() => {
      const secondsLeft = remainingTime - 1;
      if (secondsLeft > 0) {
        setRemainingTime(secondsLeft);
      } else {
        setRemainingTime(totalTimeSec);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  const minutes =
    Math.floor(remainingTime / 60).toString().length === 1
      ? `0${Math.floor(remainingTime / 60)}`
      : Math.floor(remainingTime / 60);
  const seconds =
    (remainingTime % 60).toString().length === 1 ? `0${remainingTime % 60}` : remainingTime % 60;
  return { minutes, seconds };
}
