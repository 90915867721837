import { quiz_ManHeavy, quiz_ManSlim, quiz_WomanHeavy, quiz_WomanSlim } from '@/assets';
import * as Assets from '@/assets';
import { keyframes } from 'styled-components';

export const from: Record<string, { [k: string]: string }> = {
  slim: { male: quiz_ManSlim, female: quiz_WomanSlim },
  midSized: { male: quiz_ManSlim, female: quiz_WomanSlim },
  onHeavierSide: { male: quiz_ManHeavy, female: quiz_WomanHeavy },
  overweight: { male: quiz_ManHeavy, female: quiz_WomanHeavy },
};

export const colors = {
  sandDark: '#E9D7BB',
  sandBag: '#FFEBC7',
  sand: '#FFF9EF',
  accent: '#A39ED4',
  accentDark: '#6660A7',
  white: '#FFFFFF',
  nature: '#A1AFA9',
  error: '#FF7171',
  success: '#00C872',
  shadow: '#A09FA3',
  dark: '#272727',
  lightShadow: '#D2D2D2',
  sandAccent: '#EDC7B0',
  orange: 'rgb(255, 80, 41)',
  orangeLight: 'rgb(239, 123, 73)',
  catchySand: '#E7A075',
  catchyAccent: '#7873B5',
  yellow: '#FFC43A',
};

export const ROUTES = {
  HOME: '/',
  PROMOTIONS: '/promotions',
  PAYMENT: 'payment/:planId',
  REGISTRATION: '/registration/:planId',
  GET_OUR_APP: '/get-our-app',
  DOWNLOAD_APP: '/download-app',
  PRIVACY_POLICY: '/legal/privacy-policy',
  TERMS_OF_USE: '/legal/terms-of-use',
  CANCELLATION_POLICY: '/legal/cancellation-policy',
};

export enum REMOTE_CONFIG {
  QUIZ_ORDER = 'quiz_order',
  QUIZ_STEP_RATE = 'quiz_step_rate',
}

export const checkPoints = [
  { title: 'titles.reduceStubborn' },
  { title: 'titles.reduceBack' },
  { title: 'titles.coreStability' },
];

export const processingSteps = [
  { id: 1, title: 'titles.identifyHabits' },
  { id: 2, title: 'titles.measureMetabolic' },
  { id: 3, title: 'titles.matchingExercises' },
  { id: 4, title: 'titles.investigatingCauses' },
  { id: 5, title: 'titles.analyzingLifeStyle' },
];

export const languages = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'es',
    name: 'Español',
  },
];

export const BUTTON_TYPES = {
  filled: {
    backgroundColor: colors.catchySand,
    color: colors.white,
    border: `2px solid transparent`,
  },
  outlined: {
    backgroundColor: colors.white,
    color: colors.sandAccent,
    border: `2px solid ${colors.sandAccent}`,
  },
  accentOutlined: {
    backgroundColor: colors.white,
    color: colors.accentDark,
    border: `2px solid ${colors.accentDark}`,
  },
};

export const planCards = [
  {
    id: 'plan_4m',
    oldPrice: '59.99',
    newPrice: '29.99',
    oldPlanPrice: '2.00',
    newPlanPrice: '0.25',
    duration: '4',
    durationStringKey: 'titles.monthPlan',
    borderColor: colors.success,
    labelTitle: 'titles.bestValue',
    type: 'filled' as keyof typeof BUTTON_TYPES,
    icon: Assets.Flame,
    paypalPlanId: import.meta.env.VITE_PAYPAL_PLAN_ID_4M,
    stripePlanId: import.meta.env.VITE_STRIPE_PLAN_ID_4M,
  },
  {
    id: 'plan_1m',
    oldPrice: '29.99',
    newPrice: '19.99',
    oldPlanPrice: '2.00',
    newPlanPrice: '0.65',
    duration: '1',
    durationStringKey: 'titles.monthPlan',
    borderColor: colors.catchyAccent,
    labelTitle: 'titles.mostPopular',
    type: 'accentOutlined' as keyof typeof BUTTON_TYPES,
    paypalPlanId: import.meta.env.VITE_PAYPAL_PLAN_ID_1M,
    stripePlanId: import.meta.env.VITE_STRIPE_PLAN_ID_1M,
  },
  {
    id: 'plan_7d',
    oldPrice: '9.99',
    newPrice: '6.99',
    oldPlanPrice: '2.00',
    newPlanPrice: '0.99',
    duration: '7',
    durationStringKey: 'titles.dayPlan',
    borderColor: colors.sandAccent,
    type: 'outlined' as keyof typeof BUTTON_TYPES,
    paypalPlanId: import.meta.env.VITE_PAYPAL_PLAN_ID_7D,
    stripePlanId: import.meta.env.VITE_STRIPE_PLAN_ID_7D,
  },
];

export const personalPrograms = [
  {
    title: 'titles.physicalLevel',
  },
  {
    title: 'titles.exercisesDesigned',
  },
  {
    title: 'titles.lowerStress',
  },
  {
    title: 'titles.reducedBack',
  },
  {
    title: 'titles.posture',
  },
  {
    title: 'titles.higherStrength',
  },
];

export const benefits1 = [
  {
    title: 'answers.loseWeight',
    icon: Assets.loseWeight,
    bgColor: colors.sandBag,
  },
  {
    title: 'answers.improvedStrength',
    icon: Assets.increaseMuscleStrength,
    bgColor: colors.sandAccent,
  },
  {
    title: 'answers.lowerStressAn',
    icon: Assets.somewhatOutOfBreath,
    bgColor: colors.accent,
  },
];

export const benefits2 = [
  {
    title: 'answers.longerLifespan',
    icon: Assets.health,
    bgColor: colors.catchyAccent,
  },
  {
    title: 'answers.reducedBackPain',
    icon: Assets.back,
    bgColor: colors.nature,
  },
  {
    title: 'answers.betterPosture',
    icon: Assets.wholeBody,
    bgColor: colors.sandAccent,
  },
];

export const profileCards = [
  {
    bgColor: colors.sand,
    text: 'titles.profileCardText1',
    image: Assets.Susan,
    name: 'Amy W.',
    duration: 'titles.profileCardDuration1',
    labelColor: colors.accent,
    weekColor: colors.sand,
    starColor: colors.accentDark,
  },
  {
    bgColor: colors.accent,
    text: 'titles.profileCardText2',
    image: Assets.Jessica,
    name: 'Linda T.',
    duration: 'titles.profileCardDuration2',
    labelColor: colors.sand,
    weekColor: colors.accentDark,
    starColor: colors.sand,
  },
  {
    bgColor: colors.sandAccent,
    text: 'titles.profileCardText3',
    image: Assets.Maria,
    name: 'John D.',
    duration: 'titles.profileCardDuration3',
    labelColor: colors.accentDark,
    weekColor: colors.sand,
    starColor: colors.accentDark,
  },
];

export const usersCards = [
  {
    name: 'Sarah',
    description:
      'Yoga on Sofa is amazing! The app is super easy to use and fits my schedule perfectly.',
    photo: Assets.usercard1,
  },
  {
    name: 'Michael',
    description: "Yoga on Sofa transformed my routine. It's so convenient to do yoga at home.",
    photo: Assets.usercard2,
  },
  {
    name: 'Emily',
    description: "I love this app! It's convenient and effective. Highly recommend.",
    photo: Assets.usercard3,
  },
  {
    name: 'Daniel',
    description: 'This app is fantastic! It fits perfectly into my day and I feel great.',
    photo: Assets.usercard4,
  },
  {
    name: 'Jessica',
    description: 'Yoga on Sofa is wonderful. I love the in-home classes.',
    photo: Assets.usercard5,
  },
  {
    name: 'Robert',
    description: 'Exceeded my expectations! Super easy to use and fits my routine.',
    photo: Assets.usercard6,
  },
  {
    name: 'Laura',
    description: 'Best yoga app ever! Convenient and high-quality classes.',
    photo: Assets.usercard7,
  },
  {
    name: 'Mark',
    description: 'Superb app! It made my fitness journey so much easier.',
    photo: Assets.usercard8,
  },
  {
    name: 'Amy',
    description: 'Love Yoga on Sofa! The flexibility and comfort are unmatched.',
    photo: Assets.usercard9,
  },
  {
    name: 'Linda',
    description: 'Great app! Easy to use and very effective.',
    photo: Assets.usercard10,
  },
  {
    name: 'John',
    description: 'Perfect for busy people. I love doing yoga at home.',
    photo: Assets.usercard11,
  },
  {
    name: 'Olivia',
    description: 'Fantastic! The app is intuitive and the classes are great.',
    photo: Assets.usercard12,
  },
  {
    name: 'Chris',
    description: 'Amazing app! I feel better and it’s so convenient.',
    photo: Assets.usercard13,
  },
  {
    name: 'Rachel',
    description: 'Yoga on Sofa is awesome. Fits perfectly into my routine.',
    photo: Assets.usercard14,
  },
  {
    name: 'Kevin',
    description: 'Great experience! The app is user-friendly and effective.',
    photo: Assets.usercard15,
  },
  {
    name: 'George',
    description: 'Love it! The classes are perfect and so convenient.',
    photo: Assets.usercard16,
  },
];

export const savingsList = [
  { title: 'titles.weightLose', price: '3,000.00' },
  { title: 'titles.privateYoga', price: '1,600.00' },
  { title: 'titles.backPain', price: '1,200.00' },
  { title: 'titles.gymMembership', price: '500.00' },
  { title: 'titles.meditation', price: '299.00' },
  { title: 'titles.lifespan', price: 'titles.invaluable' },
  { title: 'titles.lessPain', price: 'titles.invaluable' },
];

export const FB_Errors = {
  'auth/user-not-found': 'errors.userNotFound',
  'auth/wrong-password': 'errors.wrongPassword',
  'auth/invalid-login-credentials': 'errors.invalidLoginCredentials',
  'auth/email-already-in-use': 'errors.emailAlreadyInUse',
  'auth/invalid-email': 'errors.invalidEmail',
  'auth/invalid-password': 'errors.invalidPassword',
  'auth/weak-password': 'errors.weakPassword',
  'auth/user-disabled': 'errors.userDisabled',
  'auth/account-exists-with-different-credential': 'errors.accountExistsWithDifferentCredential',
};

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const AVATAR_IMAGES = [
  { id: 'avatar_1', gender: 'male', randomType: 'male' },
  { id: 'avatar_2', gender: 'female', randomType: 'female' },
  { id: 'avatar_3', gender: 'female', randomType: 'female' },
  { id: 'avatar_4', gender: 'male', randomType: 'male' },
  { id: 'avatar_5', gender: 'male', randomType: 'male' },
  { id: 'avatar_6', gender: 'male', randomType: 'non-binary' },
  { id: 'avatar_7', gender: 'female', randomType: 'female' },
  { id: 'avatar_8', gender: 'male', randomType: 'male' },
  { id: 'avatar_9', gender: 'female', randomType: 'non-binary' },
  { id: 'avatar_10', gender: 'female', randomType: 'female' },
  { id: 'avatar_11', gender: 'female', randomType: 'female' },
  { id: 'avatar_12', gender: 'female', randomType: 'female' },
  { id: 'avatar_13', gender: 'male', randomType: 'non-binary' },
];
