import { colors } from '@/utils/Constants';
import { Stack } from '@mui/material';
import CustomTypography from '../Typography';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  icon: string;
  bgColor: string;
};

const BenefitCell = ({ title, icon, bgColor }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      style={{
        padding: 16,
        width: '100%',
        backgroundColor: colors.white,
        borderRadius: '24px',
        border: `1px solid ${colors.sandDark}`,
        gap: '16px',
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        style={{
          width: 64,
          height: 64,
          borderRadius: '24px',
          backgroundColor: bgColor,
        }}
      >
        <img src={icon} />
      </Stack>
      <CustomTypography
        color={colors.dark}
        type="bodySemibold"
        title={t(title)}
        style={{ flex: 1 }}
      />
    </Stack>
  );
};

export default BenefitCell;
