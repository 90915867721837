import { BackgroundIllustrationMobile2, YogaGirl, Iliustration1 } from '@/assets';
import { colors, fadeIn, fadeOut } from '@/utils/Constants';
import { sourceSet } from '@/utils/Helper';
import useWindowSize from '@/utils/Hooks/useWindowSize';
import { styled } from 'styled-components';

const YogaCover = () => {
  const width = useWindowSize();
  return (
    width && (
      <Container $isLoaded={Boolean(width)}>
        {width > 768 ? (
          <img srcSet={sourceSet(Iliustration1)} width="100%" height="260px" />
        ) : (
          <img src={BackgroundIllustrationMobile2} width="100%" height="100%" />
        )}
        <img
          src={YogaGirl}
          srcSet={sourceSet(YogaGirl)}
          alt="YogaGirl"
          style={{
            backgroundColor: colors.sand,
            zIndex: 3,
            position: 'absolute',
            top: width < 768 ? '66%' : '52%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'transparent',
            width: width < 768 ? '24vw' : '180px',
          }}
        />
      </Container>
    )
  );
};

export default YogaCover;

const Container = styled.div<{ $isLoaded: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  visibility: ${($isLoaded) => (!$isLoaded ? 'hidden' : 'visible')};
  animation: ${($isLoaded) => (!$isLoaded ? fadeOut : fadeIn)} 0.5s linear;
  transition: visibility 0.5s linear;
`;
