import { QuizPage } from '@/utils/Types';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from '@/components';
import { colors, from } from '@/utils/Constants';
import { ArrowRight, Weight, quiz_ManMid, quiz_WomanMid } from '@/assets';
import Card from '../Card';
import { styled } from 'styled-components';
import CustomButton from '../Button';
import { QuizQuestions } from '@/utils/QuizConfig';
import 'dayjs/locale/es';
import useIsPhone from '@/utils/Hooks/usePhone';
import SecureInfo from '../SecureInfo';
import SlickSlider from '../SlickCarousel';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import AsSeenOn from '../AsSeenOn';
dayjs.extend(advancedFormat);

const PredictionComparisonPage = ({ answers, onSelect }: QuizPage) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const language = localStorage.getItem('language') || 'en';
  dayjs.locale(language);
  const { t } = useTranslation();
  const isMobile = useIsPhone();

  const currentWeight = parseInt(answers.currentWeight, 10);
  const targetWeight = parseInt(answers.targetWeight, 10);

  let justInTime = '';
  const chartWeights = [];

  const measurement =
    answers.measurementSystemMetric === 'metric' ? t('titles.kg') : t('titles.lbs');
  const diff = Math.abs(currentWeight - targetWeight);
  const weeks = answers.measurementSystemMetric ? diff : Math.round(diff / 2);

  const gainWeight = targetWeight > currentWeight;
  let newTargetWeight = targetWeight;
  const date = dayjs().add(weeks, 'weeks').format('MMMM D, YYYY');

  const targetDate = dayjs().add(weeks, 'weeks');
  let eventWeight = newTargetWeight;

  if (answers.eventDate) {
    const weeksDiff = Math.abs(dayjs().diff(dayjs(answers.eventDate), 'weeks'));
    if (weeksDiff >= weeks) {
      if (answers.upcomingEvent === 'other') {
        justInTime = t('titles.justInTimeOther');
      } else {
        const answer = QuizQuestions['upcomingEvent']['answers'].find(
          (a) => a.id === answers.upcomingEvent
        );
        if (answer) {
          justInTime = t('titles.justInTime', { upcomingEvent: t(answer?.title || '') });
        }
      }
    } else {
      const addToTarget = answers.measurementSystemMetric === 'metric' ? weeksDiff : weeksDiff * 2;
      eventWeight = currentWeight + (gainWeight ? addToTarget : -addToTarget);
    }
  }

  const predictionText = t('titles.wePredict', { newTargetWeight, measurement, date });

  chartWeights.push(!gainWeight ? currentWeight : newTargetWeight);
  const step = diff / 4;
  for (let i = 1; i < 4; i++) {
    if (gainWeight) {
      chartWeights.push(Math.round(newTargetWeight - step * i));
    } else {
      chartWeights.push(Math.round(currentWeight - step * i));
    }
  }
  chartWeights.push(!gainWeight ? newTargetWeight : currentWeight);

  const targetDateFormatted = useMemo(() => {
    if (dayjs().year() !== targetDate.year()) {
      return targetDate.format('MMMM Do, YYYY');
    }
    return targetDate.format('MMMM Do');
  }, [targetDate]);

  const onContinue = () => {
    localStorage.setItem('finishedQuiz', 'true');
    onSelect('', '');
  };

  const upcomingEventCapitalized = useMemo(() => {
    if (answers?.upcomingEvent) {
      const translated = t(`answers.${answers.upcomingEvent}`);
      return translated.charAt(0).toUpperCase() + translated.slice(1);
    }
    return '';
  }, [measurement, answers?.upcomingEvent, t]);

  return (
    <Stack>
      <CustomTypography
        title={predictionText}
        type="headingL"
        style={{ textAlign: 'center', marginBottom: !justInTime ? '40px' : '8px' }}
      />
      {!!justInTime && (
        <CustomTypography
          title={t(justInTime)}
          type="bodyMedium"
          color={colors.dark}
          style={{ textAlign: 'center', marginBottom: '16px' }}
        />
      )}

      <Stack
        flexDirection="row"
        justifyContent="center"
        gap="10px"
        style={{ marginBottom: '24px' }}
      >
        <img src={from[answers.currentBody]?.[answers.gender === 'non-binary' ? 'female' : answers.gender]} width={150} />
        <img src={ArrowRight} alt="ArrowRight" />
        <img src={answers.gender === 'male' ? quiz_ManMid : quiz_WomanMid} width={150} />
      </Stack>
      <Stack style={{ marginBottom: isMobile ? '48px' : '32px' }}>
        <SlickSlider answers={answers} />
      </Stack>
      <Card
        bgColor={colors.nature}
        cutOutColor={colors.sand}
        style={isMobile ? { marginLeft: '-20px', marginRight: '-20px' } : {}}
        isMobile={isMobile}
      >
        <CustomTypography
          title={t('titles.howBodyWillChange')}
          type="headingM"
          color={colors.sand}
          style={{ textAlign: 'center', marginBottom: '8px' }}
        />
        {!!justInTime && (
          <CustomTypography
            title={t(justInTime)}
            type="bodyMedium"
            color={colors.sand}
            style={{ textAlign: 'center', marginBottom: '8px' }}
          />
        )}
        <Stack style={{ marginBottom: '24px', alignItems: 'center' }}>
          {answers.eventDate && answers.upcomingEvent && (
            <Stack
              style={{
                width: 'fit-content',
                backgroundColor: colors.white,
                padding: 8,
                gap: 4,
                borderRadius: '16px',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img src={Weight} width={16} />
              <CustomTypography
                title={upcomingEventCapitalized + ' - ' + eventWeight + ' ' + measurement}
                type="bodyMMedium"
                color={colors.dark}
              />
            </Stack>
          )}
        </Stack>
        <ChartContainer>
          <ChartY>
            <Stack style={{ height: 180, justifyContent: 'space-between', alignItems: 'flex-end' }}>
              {chartWeights.map((w: number, i: number) => (
                <CustomTypography
                  title={w + ' ' + measurement}
                  type="bodyMMedium"
                  color={colors.dark}
                  key={i}
                  style={{ wordBreak: 'unset' }}
                />
              ))}
            </Stack>
          </ChartY>
          <Chart ref={chartRef}>
            <Stack
              flexDirection="row"
              justifyContent="center"
              gap={isMobile ? '10px' : '8px'}
              style={{ height: 180, flex: 1 }}
            >
              {new Array(12).fill(0).map((_, i) => (
                <ChartLine key={`chart${i}`} $isMobile={isMobile}>
                  <Stack
                    style={{
                      height: `${gainWeight ? 100 - (11 - i) * 8.33 : 100 - i * 8.33}%`,
                      borderRadius: 11,
                      backgroundColor: colors.sand,
                    }}
                  />
                </ChartLine>
              ))}
            </Stack>
            <Stack
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 12,
              }}
            >
              <CustomTypography title={t('titles.today')} type="bodyMMedium" color={colors.dark} />
              <CustomTypography
                title={targetDateFormatted}
                type="bodyMMedium"
                color={colors.dark}
              />
            </Stack>
          </Chart>
        </ChartContainer>
        <CustomTypography
          title={t('titles.consultFirst')}
          type="bodySRegular"
          color={colors.sand}
          style={{ textAlign: 'center', lineHeight: '20px', marginBottom: '24px' }}
        />
        <CustomButton
          isLightTheme={false}
          onClick={onContinue}
          name="buttons.continue"
          style={{ width: '100%' }}
        />
        {isMobile && (
          <Stack mt="40px">
            <AsSeenOn isLight={false} />
          </Stack>
        )}
        {isMobile && <SecureInfo isLight={false} />}
      </Card>
    </Stack>
  );
};

export default PredictionComparisonPage;

const ChartContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const Chart = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ChartY = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 23px;
`;

const ChartLine = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  height: 180px;
  width: 21px;
  border-radius: 20px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.25);
  justify-content: flex-end;
  ${({ $isMobile }) =>
    $isMobile &&
    `
  width: 12px;
`}
`;
