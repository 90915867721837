import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { colors } from '@/utils/Constants';
import { styled } from 'styled-components';
import useIsPhone from '@/utils/Hooks/usePhone';

type Props = {
  value: number;
};

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 4,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: colors.sandAccent,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: colors.accentDark,
  },
}));

const ProgressBars = ({ value }: Props) => {
  const isMobile = useIsPhone();

  return (
    <div
      style={
        isMobile
          ? { flexGrow: 0, width: '180px' }
          : {
              flexGrow: 1,
            }
      }
    >
      <BorderLinearProgress variant="determinate" value={value} />
    </div>
  );
};

export default ProgressBars;
