import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { FIREBASE_API } from '@/firebaseConfig';

// Initialize Firebase app
const app = initializeApp(FIREBASE_API);

// Initialize Firebase services
const auth = getAuth(app);
const functions = getFunctions(app);
const db = getFirestore(app);

export { app, auth, functions, db };
