import { RateStar } from '@/assets';
import Svg from '@/components/Svg';
import { ftsToCms, lbsToKgs } from './Measurements';

export const sourceSet = (image: string) => {
  if (image.includes('.png')) {
    return `${image} 1x, ${image.replace('.png', '@2x.png')} 2x, ${image.replace(
      '.png',
      '@3x.png'
    )} 3x, ${image.replace('.png', '@4x.png')} 4x `;
  }
  return '';
};

export const starsRate = (starColor: string) => {
  return Array.from({ length: 5 }, (_, index) => {
    return <Svg key={index} svgImage={<RateStar />} fill={starColor} height="24px" width="24px" />;
  });
};

export const scrollToPosition = (position: number) => {
  window.scrollTo({ top: position, behavior: 'smooth' });
};

export const cc_format = (value: string) => {
  const v = value.replace(/[^0-9]/gi, '').substr(0, 16);
  const parts = [];
  for (let i = 0; i < v.length; i += 4) {
    parts.push(v.substr(i, 4));
  }
  return parts.length > 1 ? parts.join(' ') : value;
};

export const expriy_format = (value: string) => {
  const expdate = value;
  const expDateFormatter =
    expdate.replace(/\//g, '').substring(0, 2) +
    (expdate.length > 2 ? '/' : '') +
    expdate.replace(/\//g, '').substring(2, 4);

  return expDateFormatter;
};

export const bmi = (answers: Record<string, any>, inputValue?: string) => {
  let currentWeight = inputValue || answers.currentWeight;
  let currentHeight = answers.currentHeight;
  if (answers.measurementSystemMetric === 'imperial') {
    currentWeight = parseInt(lbsToKgs[inputValue || answers.currentWeight], 10);
    currentHeight = parseInt(ftsToCms[answers.currentHeight], 10);
  } else {
    currentWeight = parseInt(inputValue || answers.currentWeight, 10);
    currentHeight = parseInt(answers.currentHeight, 10);
  }
  return ((currentWeight / (currentHeight * currentHeight)) * 10000).toFixed(1);
};

export const scrollToView = (id: string) => {
  const plan = document.getElementById(id);
  setTimeout(() => { // https://stackoverflow.com/a/73708851/22561909
    plan?.scrollIntoView({ behavior: 'smooth', block: 'center' });    
  }, 0);
};
