import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app.tsx';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import imagePaths from './imagePaths.json';
import { HelmetProvider } from 'react-helmet-async';

Bugsnag.start({
  apiKey: '9b619f3ce52931e2375475bcadfd6688',
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: '9b619f3ce52931e2375475bcadfd6688' });

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

function preloadImages(paths: string[]) {
  paths.forEach((path) => {
    const link = document.createElement('link');
    link.rel = 'prefetch';
    link.as = 'image';
    link.href = `/${path}`;
    document.head.appendChild(link);
  });
}
if (location.hostname !== 'localhost') {
  preloadImages(imagePaths);
}

localStorage.setItem('checkedTerms', 'true');

ReactDOM.createRoot(document.getElementById('root')!).render(
  ErrorBoundary ? (
    <ErrorBoundary>
      <React.StrictMode>
        <BrowserRouter>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </BrowserRouter>
      </React.StrictMode>
    </ErrorBoundary>
  ) : (
    <React.StrictMode>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </React.StrictMode>
  )
);
