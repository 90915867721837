import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useMemo } from 'react';
import UserCard from './Promotions/UserCard';

interface Props {
  items: {
    name: string;
    description: string;
    photo: string;
  }[];
  autoPlayInterval?: number;
  animationDuration?: number;
  reversed: boolean;
}

const responsive = {
  0: {
    items: 1,
  },
  568: {
    items: 1,
  },
  650: {
    items: 2,
  },

  768: {
    items: 2,
    itemsFit: 'contain',
  },
  800: {
    items: 2,
    itemsFit: 'contain',
  },
  992: {
    items: 3,
    itemsFit: 'contain',
  },
  1024: {
    items: 3,
    itemsFit: 'contain',
  },
  1080: {
    items: 3,
    itemsFit: 'contain',
  },
  1200: {
    items: 3,
    itemsFit: 'contain',
  },
  1320: {
    items: 4,
    itemsFit: 'contain',
  },
  1440: {
    items: 4,
    itemsFit: 'contain',
  },
  1500: {
    items: 4,
    itemsFit: 'contain',
  },
  1600: {
    items: 4,
    itemsFit: 'contain',
  },
};
const CustomCarousel = ({ items, autoPlayInterval, animationDuration, reversed }: Props) => {
  const users = useMemo(() => {
    return items.map((user, index) => {
      return <UserCard key={index} {...user} />;
    });
  }, []);
  return (
    <AliceCarousel
      autoPlay={true}
      autoPlayDirection={reversed ? "rtl" : "ltr"}
      autoPlayInterval={autoPlayInterval || 500}
      disableButtonsControls
      disableDotsControls
      autoPlayStrategy="default"
      items={users}
      infinite
      animationDuration={animationDuration || 3000}
      animationType="fadeout"
      responsive={responsive}
    />
  );
};

export default CustomCarousel;
