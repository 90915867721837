import { colors } from '@/utils/Constants';
import React from 'react';

type Props = {
  title: string;
  type: keyof typeof TYPES;
  color?: string;
  style?: React.CSSProperties;
};

export const TYPES = {
  bigNumber: {
    fontFamily: 'GeneralSans-Medium',
    fontSize: 88,
  },
  smallNumber: {
    fontFamily: 'GeneralSans-Medium',
    fontSize: 56,
  },
  generalSans: {
    fontFamily: 'GeneralSans-Bold',
    fontSize: 56,
  },
  generalSansBigSemibold: {
    fontFamily: 'GeneralSans-Semibold',
    fontSize: 56,
  },
  generalSansMiddleSemibold: {
    fontFamily: 'GeneralSans-Semibold',
    fontSize: 48,
  },
  generalSansSmallSemibold: {
    fontFamily: 'GeneralSans-Semibold',
    fontSize: 32,
  },
  generalSansSemi: {
    fontFamily: 'GeneralSans-Semibold',
    fontSize: 56,
  },
  headingL: {
    fontFamily: 'GeneralSans-Semibold',
    fontSize: 32,
  },
  headingM: {
    fontFamily: 'GeneralSans-Semibold',
    fontSize: 24,
  },
  headingS: {
    fontFamily: 'GeneralSans-Semibold',
    fontSize: 18,
  },
  headingSMedium: {
    fontFamily: 'GeneralSans-Medium',
    fontSize: 18,
  },
  headline: {
    fontFamily: 'GeneralSans-Semibold',
    fontSize: 18,
  },
  bodyRegular: {
    fontFamily: 'GeneralSans-Regular',
    fontSize: 16,
  },
  bodyMedium: {
    fontFamily: 'GeneralSans-Medium',
    fontSize: 16,
  },
  bodySemibold: {
    fontFamily: 'GeneralSans-Semibold',
    fontSize: 16,
  },
  bodyTextLink: {
    fontFamily: 'GeneralSans-Medium',
    fontSize: 16,
  },
  bodyMRegular: {
    fontFamily: 'GeneralSans-Regular',
    fontSize: 14,
  },
  bodyMMedium: {
    fontFamily: 'GeneralSans-Medium',
    fontSize: 14,
  },
  bodyMSemibold: {
    fontFamily: 'GeneralSans-Semibold',
    fontSize: 14,
  },
  bodyMTextLink: {
    fontFamily: 'GeneralSans-Medium',
    fontSize: 14,
  },
  bodySRegular: {
    fontFamily: 'GeneralSans-Regular',
    fontSize: 12,
  },
  bodySMedium: {
    fontFamily: 'GeneralSans-Medium',
    fontSize: 12,
  },
  bodySSemibold: {
    fontFamily: 'GeneralSans-Semibold',
    fontSize: 12,
  },
  bodySTextLink: {
    fontFamily: 'GeneralSans-Medium',
    fontSize: 12,
  },
  bodyXsRegular: {
    fontFamily: 'GeneralSans-Regular',
    fontSize: 10,
  },
  bodyXsMedium: {
    fontFamily: 'GeneralSans-Medium',
    fontSize: 10,
  },
  bodyXsSemibold: {
    fontFamily: 'GeneralSans-Semibold',
    fontSize: 10,
  },
  bodyXsTextLink: {
    fontFamily: 'GeneralSans-Medium',
    fontSize: 10,
  },
};

const CustomTypography = ({ title, type, color, style }: Props) => {
  return (
    <div
      style={{
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
        ...TYPES[type],
        color: color || colors.dark,
        ...style,
      }}
    >
      {title}
    </div>
  );
};

export default CustomTypography;
