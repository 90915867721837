import { colors } from '@/utils/Constants';
import { Answer, Question } from '@/utils/Types';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Circle from './Circle';
import * as Assets from '@/assets';
import { useMemo, useState } from 'react';
import { CustomTypography } from '.';

type Props = {
  item: Answer;
  params: Question & { id: string };
  onSelect: (key: string, value: string) => void;
  selected?: boolean;
  index: number;
  gender?: string;
};

const answerColors = [colors.sandBag, colors.accent, colors.nature, colors.sandAccent];

const QuizItem = ({ item, params, onSelect, selected, index, gender }: Props) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const imageBgColor = useMemo(() => {
    return answerColors[index % answerColors.length];
  }, [index]);

  const image = useMemo(() => {
    let icon: any = null;
    if (typeof item?.icon === 'object') {
      if (gender) {
        icon = item.icon[gender === 'non-binary' ? 'female' : gender];
        if (!icon) {
          icon = item.icon.male;
        }
      }
    } else {
      icon = Assets[item.id as keyof typeof Assets] as string;
    }
    return icon;
  }, [item.id, gender]);

  const sourceSet = useMemo(() => {
    if (image.includes('.png')) {
      return `${image} 1x, ${image.replace('.png', '@2x.png')} 2x, ${image.replace(
        '.png',
        '@3x.png'
      )} 3x, ${image.replace('.png', '@4x.png')} 4x `;
    }
  }, [image]);

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      sx={
        isHover
          ? {
              transition: '0.3s',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }
          : {}
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        padding: 16,
        width: '100%',
        backgroundColor: colors.white,
        borderRadius: '24px',
        border: `1px solid ${colors.sandDark}`,
        cursor: 'pointer',
        gap: '16px',
        WebkitTapHighlightColor: 'transparent',
      }}
      onClick={() => {
        onSelect(params.id, item.id);
        handleMouseLeave();
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        style={{ width: 64, height: 64, borderRadius: '24px', backgroundColor: imageBgColor }}
      >
        <img src={image} srcSet={sourceSet && sourceSet} />
      </Stack>
      <CustomTypography
        color={colors.dark}
        type="bodySemibold"
        title={item?.title ? t(item.title) : ''}
        style={{ flex: 1 }}
      />
      {params?.multiple && (
        <Circle isSelected={selected || false} selectedBgColor={colors.nature} />
      )}
    </Stack>
  );
};

export default QuizItem;
