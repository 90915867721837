import MonthPlan from '@/components/Payment/MonthPlan';
import PaymentForm from '@/components/Payment/PaymentForm';
import { colors } from '@/utils/Constants';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import Loader from '@/components/Loader';
import useIsPhone from '@/utils/Hooks/usePhone';
import CustomDialog from '@/components/Dialog';
import { Stack } from '@mui/material';
import { CustomTypography } from '@/components';
import { useTranslation } from 'react-i18next';
import { trackMetaPixel } from '@/utils/Tracking';

const PaymentPage = () => {
  const { t } = useTranslation();
  const { planId } = useParams();
  const isMobile = useIsPhone();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    trackMetaPixel(true, 'InitiateCheckout');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Container>
        <LeftSide>
          <MonthPlan planId={planId} />
        </LeftSide>
        <RightSide>
          <PaymentForm planId={planId || ''} setLoading={setLoading} />
        </RightSide>
      </Container>

      <CustomDialog
        open={loading}
        slideAnimation={isMobile ? true : false}
        content={
          <Stack gap="40px" alignItems="center">
            <Loader />
            <Stack gap="8px">
              <CustomTypography
                color={colors.dark}
                type={isMobile ? 'headingM' : 'headingL'}
                title={t('titles.justMoment')}
                style={{ textAlign: 'center' }}
              />
              <CustomTypography
                color={colors.dark}
                type="bodyMedium"
                title={t('titles.securingPersonalized')}
                style={{ textAlign: 'center' }}
              />
            </Stack>
          </Stack>
        }
        sx={{
          '& .MuiPaper-root.MuiDialog-paper': isMobile
            ? {
                margin: 0,
                overflow: 'visible',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
              }
            : {
                borderRadius: '24px',
                maxWidth: '700px',
              },
          '& .MuiDialogContent-root': {
            padding: isMobile ? '32px 16px' : '56px 80px',
          },
          '& .MuiDialog-container': isMobile
            ? {
                alignItems: 'flex-end',
              }
            : {},
        }}
      />
    </>
  );
};

export default PaymentPage;

const Container = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  background-color: ${colors.accent};
  width: 50%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: 56px;
  @media (max-width: 768px) {
    display: none;
    width: 100%;
    padding: 24px 20px 32px 20px;
  }
`;

const RightSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: ${colors.sand};
  max-height: 100%;
  @media (max-width: 768px) {
    width: 100%;
    min-height: 100vh;
    justify-content: flex-start;
  }
`;
