import { colors } from '@/utils/Constants';
import { styled } from 'styled-components';
import CutOut from '../CutOut';
import CustomTypography from '../Typography';
import { useTranslation } from 'react-i18next';
import { Clock } from '@/assets';
import { Stack } from '@mui/material';
import Svg from '../Svg';
import { starsRate } from '@/utils/Helper';

type Props = {
  bgColor: string;
  image: string;
  text: string;
  name: string;
  labelColor: string;
  weekColor: string;
  starColor: string;
  duration: string;
};

const ProfileCard = ({ bgColor, text, image, name, labelColor, weekColor, starColor, duration }: Props) => {
  const { t } = useTranslation();

  return (
    <Container $bgColor={bgColor}>
      <CutOut bgColor={colors.nature} />
      <Content>
        <img src={image} style={{ borderRadius: '24px' }} />
        <Stack flexDirection="row" justifyContent="space-between">
          <CustomTypography title={name} type="headingM" style={{ color: colors.dark }} />
          <Label $labelColor={labelColor} color={weekColor}>
            <Svg svgImage={<Clock />} fill={weekColor} height="16px" width="16px" />
            <CustomTypography
              title={t(duration)}
              type="bodyMMedium"
              style={{ color: weekColor }}
            />
          </Label>
        </Stack>
        <Stack flexDirection="row" gap="8px">
          {starsRate(starColor)}
        </Stack>
        <CustomTypography title={t(text)} type="bodyMedium" style={{ color: colors.dark }} />
      </Content>
    </Container>
  );
};

export default ProfileCard;

const Container = styled.div<{ $bgColor: string }>`
  position: relative;
  max-width: 360px;
  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 32px;
  gap: 20px;
  @media (max-width: 768px) {
    padding: 32px 20px;
  }
`;

const Label = styled.div<{ $labelColor: string; color: string }>`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  background-color: ${({ $labelColor }) => $labelColor};
  border-radius: 16px;
  padding: 8px;
  color: ${({ color }) => color};
`;
