import { Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from '@/assets';
import { colors } from '@/utils/Constants';
import TextInput from '../TextInput';
import ExcellentTrustpilot from '../ExcellentTrustpilot';
import { CustomTypography } from '..';
import CustomButton from '../Button';
import SecureInfo from '../SecureInfo';
import Toast from '../Toast';
import useLocalStorage from '@/utils/Hooks/useLocalStorage';
import useIsPhone from '@/utils/Hooks/usePhone';
import { getCurrentUser, signInAnonymous } from '@/utils/AuthMiddleware';
import { setCollectionDoc } from '@/utils/FirebaseMiddleware';
import { serverTimestamp } from 'firebase/firestore';
import { trackMetaPixel } from '@/utils/Tracking';

type Props = {
  closeModal: () => void;
};

const DialogPrimaryContent = ({ closeModal }: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();
  const { measurement, weight, gainLoseWeight, answers } = useLocalStorage();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const onSubmit = async () => {
    if (!email) {
      setError('validation.emailRequired');
      return;
    }
    if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      setError('validation.invalidEmail');
      return;
    }
    localStorage.setItem('email', email);

    if (!getCurrentUser()) {
      // in normal cases, should be signed in already, because user is signed when the popup is opened
      await signInAnonymous();
    }
    const currentUser = getCurrentUser();
    if (currentUser) {
      const finalAnswers = Object.entries(answers)
        .filter(([k, _]) => k !== 'measurementSystemMetric')
        .map(([key, value]) => {
          if (key === 'gender' || key === 'currentAge' || key === 'name' || key === 'eventDate') {
            return { answer: value, question: key };
          }

          const measurementKeys = ['currentHeight', 'currentWeight', 'targetWeight'];
          let answerObj: any = {
            id: value,
          };

          if (measurementKeys.includes(key)) {
            answerObj.answer = value;
            answerObj.measurement = answers.measurementSystemMetric;
            answerObj.question = key;
            delete answerObj.id;
            return answerObj;
          }

          if (Array.isArray(value)) {
            answerObj = value.map((val) => {
              return {
                id: val,
                title: t(`answers.${val}`),
              };
            });

            return {
              answer: answerObj,
              question: key,
            };
          }
          answerObj.title = t(`answers.${value}`);
          return {
            answer: answerObj,
            question: key,
          };
        });

      await setCollectionDoc('users', currentUser.uid, {
        webQuizEmail: email,
        quiz: finalAnswers,
        createdAt: serverTimestamp(),
      });
    }
    
    trackMetaPixel(true, 'CompleteRegistration');
    trackMetaPixel(true, 'Lead');

    closeModal();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim());
    setError('');
  };

  return (
    <Stack style={{ gap: isMobile ? '12px' : '24px', alignItems: 'center' }}>
      <Stack gap="40px" alignItems="center">
        <img src={Logo} alt="logo" width={isMobile ? '56px' : '120px'} />
        <ExcellentTrustpilot isLight={true} border={true} />
        <Stack gap="8px">
          <CustomTypography
            color={colors.dark}
            type={isMobile ? 'headingM' : 'headingL'}
            title={t('titles.tailored')}
            style={{ textAlign: 'center' }}
          />
          <CustomTypography
            color={colors.dark}
            type={isMobile ? 'bodyMMedium' : 'bodyMedium'}
            title={weight === 0
              ? t('titles.couldLoseNeutral')
              : t('titles.couldLose', { gainWeight: gainLoseWeight, weight: Math.abs(weight), measurement })
            }
            style={{ textAlign: 'center' }}
          />
        </Stack>
        <Stack style={{ width: '100%' }}>
          <Stack
            flexDirection={isMobile ? 'column' : 'row'}
            style={{ gap: 16, width: '100%', marginBottom: '15px' }}
          >
            <div style={{ width: '100%' }}>
              <TextInput
                type="email"
                placeholderTitle="titles.emailAddress"
                onChange={handleEmailChange}
                value={email}
                onSubmit={onSubmit}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '20px',
                    borderColor: error ? colors.error : colors.sandDark,
                  },
                  '& .MuiInputBase-input': {
                    color: colors.dark,
                  },
                }}
              />
            </div>
            {isMobile && error && <Toast title={t(error)} />}
            <CustomButton
              onClick={onSubmit}
              name={t('buttons.getMyPlan')}
              style={{ width: isMobile ? '100%' : '270px' }}
            />
          </Stack>
          {!isMobile && error && <Toast title={t(error)} />}
        </Stack>
      </Stack>
      <SecureInfo
        isLight={true}
        style={{ paddingTop: 0, width: '100%', maxWidth: '100%' }}
        showSecureText={true}
        secureText={isMobile ? 'titles.secure' : 'titles.clickAgree'}
      />
    </Stack>
  );
};

export default DialogPrimaryContent;
