import PageNotFoundIllustration from '@/assets/illustrations/PageNotFoundIllustration';
import MotionContainer from '@/components/Motion/MotionContainer';
import { varBounce } from '@/components/Motion/bounce';
import { Typography, Button } from '@mui/material';
import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <MotionContainer animate>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          Sorry, page not found!
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
          sure to check your spelling.
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <PageNotFoundIllustration
          sx={{
            height: 260,
            my: { xs: 5, sm: 10 },
          }}
        />
      </m.div>

      <Button component={RouterLink} to="/" size="large" variant="contained">
        Go to Home
      </Button>
    </MotionContainer>
  );
};

export default NotFoundPage;
