import ReactPlayer from 'react-player';
import { styled } from 'styled-components';

interface Props {
  url: string;
}

const VideoPlayer = ({ url }: Props) => {
  return (
    <PlayerWrapper>
      <ReactPlayer url={url} width="100%" height="100%" className="react-player" controls />
    </PlayerWrapper>
  );
};

export default VideoPlayer;

const PlayerWrapper = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  .react-player {
    border-radius: 20px;
    overflow: hidden;
  }
  video {
    border-radius: 20px;
    overflow: hidden;
  }
`;
