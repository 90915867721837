import { useMemo } from 'react';
import { styled } from 'styled-components';
import CutOut from '../CutOut';
import { colors, profileCards } from '@/utils/Constants';
import CustomTypography from '../Typography';
import { useTranslation } from 'react-i18next';
import ProfileCard from './ProfileCard';
import { Stack } from '@mui/material';
import { FoxLogo } from '@/assets';
import { starsRate } from '@/utils/Helper';
import useIsPhone from '@/utils/Hooks/usePhone';

const Media = () => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();

  const profiles = useMemo(() => {
    return profileCards.map((profile, index) => {
      return <ProfileCard key={index} {...profile} />;
    });
  }, []);

  return (
    <Container style={{ padding: isMobile ? '72px 20px 112px 20px' : '120px 0 160px 0 ' }}>
      <CutOut bgColor={colors.sand} />
      <Content>
        <CustomTypography
          title="93%"
          type="generalSansBigSemibold"
          style={{
            textAlign: 'center',
            fontSize: isMobile ? '96px' : '160px',
            color: colors.sand,
            marginBottom: '24px',
          }}
        />
        <CustomTypography
          title={t('titles.similarProfile')}
          type={isMobile ? 'headingM' : 'generalSansMiddleSemibold'}
          style={{
            textAlign: 'center',
            color: colors.sand,
            marginBottom: isMobile ? '56px' : '120px',
          }}
        />
        <Stack
          gap="24px"
          width="100%"
          flexDirection={isMobile ? 'column' : 'row'}
          pb={isMobile ? '56px' : '120px'}
          sx={{
            '@media (max-width: 1150px)': {
              flexDirection: 'column',
              alignItems: 'center',
            },
          }}
        >
          {profiles}
        </Stack>
        <CustomTypography
          title={t('titles.yogaMedia')}
          type="generalSansSemi"
          style={{
            textAlign: 'center',
            fontSize: '48px',
            color: colors.sand,
            paddingBottom: isMobile ? '56px' : '72px',
          }}
        />
        <Fox>
          <img src={FoxLogo} />
        </Fox>
        <Stack flexDirection="row" gap="8px" mb="32px">
          {starsRate(colors.accentDark)}
        </Stack>
        <CustomTypography
          title={t('titles.comfortMedia')}
          type={isMobile ? 'headingS' : 'headingM'}
          style={{
            textAlign: 'center',
            color: colors.sand,
            maxWidth: '600px',
          }}
        />
      </Content>
    </Container>
  );
};

export default Media;

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  background-color: ${colors.nature};
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1128px;
`;

const Fox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: ${colors.sandDark};
  margin-bottom: 32px;
`;
