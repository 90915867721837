import { Question } from './Types';
import * as Assets from '@/assets';

export const QuizQuestions: Record<string, Question> = {
  goal: {
    question: 'questions.whtsUrGoal',
    answers: [
      {
        id: 'loseWeight',
        title: 'answers.loseWeight',
        // icon: Assets.quiz_Flame,
      },
      {
        id: 'improveFlexibility',
        title: 'answers.improveFlexibility',
        // icon: Assets.quiz_Basketball,
      },
      {
        id: 'toneBootyAndAbs',
        title: 'answers.toneBootyAndAbs',
        // icon: Assets.quiz_Abs,
      },
      {
        id: 'increaseMuscleStrength',
        title: 'answers.increaseMuscleStrength',
        // icon: Assets.quiz_Muscle,
      },
    ],
  },
  flexibility: {
    question: 'questions.whtsUrFlexibility',
    answers: [
      {
        id: 'notFlexible',
        title: 'answers.notFlexible',
        // icon: Assets.quiz_Pose1,
      },
      {
        id: 'almostReach',
        title: 'answers.almostReach',
        // icon: Assets.quiz_Pose2,
      },
      {
        id: 'canTouchFeet',
        title: 'answers.canTouchFeet',
        // icon: Assets.quiz_Pose3,
      },
      {
        id: 'notSure',
        title: 'answers.notSure',
        // icon: Assets.quiz_Pose4,
      },
    ],
  },
  currentBody: {
    question: 'questions.describePhysique',
    answers: [
      {
        id: 'slim',
        title: 'answers.slim',
        icon: { male: Assets.quiz_M_Slim, female: Assets.quiz_F_Slim },
      },
      {
        id: 'midSized',
        title: 'answers.midSized',
        icon: { male: Assets.quiz_M_Mid, female: Assets.quiz_F_Mid },
      },
      {
        id: 'onHeavierSide',
        title: 'answers.onHeavierSide',
        icon: { male: Assets.quiz_M_Heavier, female: Assets.quiz_F_Heavier },
      },
      {
        id: 'overweight',
        title: 'answers.overweight',
        icon: { male: Assets.quiz_M_Overweight, female: Assets.quiz_F_Overweight },
      },
    ],
  },
  targetBody: {
    question: 'questions.envisionPhysique',
    answers: [
      {
        id: 'skinny',
        title: 'answers.skinny',
        icon: { male: Assets.quiz_M_Skinny, female: Assets.quiz_F_Skinny },
      },
      {
        id: 'toned',
        title: 'answers.toned',
        icon: { male: Assets.quiz_M_Toned, female: Assets.quiz_F_Toned },
      },
      {
        id: 'curvy',
        title: 'answers.curvy',
        icon: { male: Assets.quiz_M_Curvy, female: Assets.quiz_F_Curvy },
      },
      {
        id: 'fewSizesSmaller',
        title: 'answers.fewSizesSmaller',
        icon: { male: Assets.quiz_M_FewSizesSmaller, female: Assets.quiz_F_FewSizesSmaller },
      },
    ],
  },
  greatShapeAgo: {
    question: 'questions.lastTopShape',
    answers: [
      {
        id: 'lessThanYearAgo',
        title: 'answers.lessThanYearAgo',
        // icon: Assets.quiz_Cool,
      },
      {
        id: 'oneToTwoYearsAgo',
        title: 'answers.oneToTwoYearsAgo',
        // icon: Assets.quiz_Happy,
      },
      {
        id: 'moreThanThreeYearsAgo',
        title: 'answers.moreThanThreeYearsAgo',
        // icon: Assets.quiz_Sceptic,
      },
      {
        id: 'never',
        title: 'answers.never',
        // icon: Assets.quiz_Sad,
      },
    ],
  },
  triedApp: {
    question: 'questions.prevYogaExperience',
    answers: [
      {
        id: 'practiceRegularly',
        title: 'answers.practiceRegularly',
        // icon: Assets.quiz_Yoga,
      },
      {
        id: 'triedIt',
        title: 'answers.triedIt',
        // icon: Assets.quiz_Mat,
      },
      {
        id: 'neverTried',
        title: 'answers.neverTried',
        // icon: Assets.quiz_Sceptic,
      },
    ],
  },
  exerciseFrequency: {
    question: 'questions.exerciseFrequency',
    answers: [
      {
        id: 'daily',
        title: 'answers.daily',
        // icon: Assets.quiz_Dumbell,
      },
      {
        id: 'fewTimesAWeek',
        title: 'answers.fewTimesAWeek',
        // icon: Assets.quiz_Shoe,
      },
      {
        id: 'fewTimesAMonth',
        title: 'answers.fewTimesAMonth',
        // icon: Assets.quiz_Calendar,
      },
      {
        id: 'almostNever',
        title: 'answers.almostNever',
        // icon: Assets.quiz_ScepticOrange,
      },
    ],
  },
  fitnessExperience: {
    question: 'questions.fitnessJourney',
    answers: [
      {
        id: 'hardToLoseWeight',
        title: 'answers.hardToLoseWeight',
        // icon: Assets.quiz_Angry,
      },
      {
        id: 'gainLoseEasily',
        title: 'answers.gainLoseEasily',
        // icon: Assets.quiz_Smile,
      },
      {
        id: 'troubleGainingWeight',
        title: 'answers.troubleGainingWeight',
        // icon: Assets.quiz_AngryGreen,
      },
    ],
  },
  waterAmount: {
    question: 'questions.waterConsume',
    answers: [
      {
        id: 'onlyTeaCoffee',
        title: 'answers.onlyTeaCoffee',
        // icon: Assets.quiz_Tea,
      },
      {
        id: 'oneTwoGlasses',
        title: 'answers.oneTwoGlasses',
        // icon: Assets.quiz_Drop,
      },
      {
        id: 'twoSixGlasses',
        title: 'answers.twoSixGlasses',
        // icon: Assets.quiz_Glass,
      },
      {
        id: 'moreThanSixGlasses',
        title: 'answers.moreThanSixGlasses',
        // icon: Assets.quiz_Wave,
      },
    ],
  },
  sleepAmount: {
    question: 'questions.sleepPerNight',
    answers: [
      {
        id: 'eightPlusHours',
        title: 'answers.eightPlusHours',
        // icon: Assets.quiz_Sun,
      },
      {
        id: 'sevenEightHours',
        title: 'answers.sevenEightHours',
        // icon: Assets.quiz_Sleeping,
      },
      {
        id: 'sixSevenHours',
        title: 'answers.sixSevenHours',
        // icon: Assets.quiz_Zzz,
      },
      {
        id: 'lessThanSixHours',
        title: 'answers.lessThanSixHours',
        // icon: Assets.quiz_Moon,
      },
    ],
  },
  healthyDiet: {
    question: 'questions.nutritiousDiet',
    answers: [
      {
        id: 'yesAlways',
        title: 'answers.yesAlways',
        // icon: Assets.quiz_Avocado,
      },
      {
        id: 'no',
        title: 'answers.no',
        // icon: Assets.quiz_Hotdog,
      },
      {
        id: 'sometimes',
        title: 'answers.sometimes',
        // icon: Assets.quiz_Chart,
      },
    ],
  },
  targetArea: {
    question: 'questions.firstAimingArea',
    answers: [
      {
        id: 'belly',
        title: 'answers.belly',
        // icon: Assets.quiz_Abs,
      },
      {
        id: 'booty',
        title: 'answers.booty',
        // icon: Assets.quiz_Booty,
      },
      {
        id: 'legs',
        title: 'answers.legs',
        // icon: Assets.quiz_Leg,
      },
      {
        id: 'wholeBody',
        title: 'answers.wholeBody',
        // icon: Assets.quiz_Body,
      },
    ],
  },
  painArea: {
    question: 'questions.discomfortArea',
    multiple: true,
    answers: [
      {
        id: 'back',
        title: 'answers.back',
        // icon: Assets.quiz_Back,
      },
      {
        id: 'knees',
        title: 'answers.knees',
        // icon: Assets.quiz_Knee,
      },
      {
        id: 'ankles',
        title: 'answers.ankles',
        // icon: Assets.quiz_Ankle,
      },
      {
        id: 'wrist',
        title: 'answers.wrist',
        // icon: Assets.quiz_Wrist,
      },
      {
        id: 'shoulders',
        title: 'answers.shoulders',
        // icon: Assets.quiz_Shoulder,
      },
    ],
  },
  fellAfterStairs: {
    question: 'questions.feelAfterWalking',
    answers: [
      {
        id: 'outOfBreathCantTalk',
        title: 'answers.outOfBreathCantTalk',
        // icon: Assets.quiz_Tired,
      },
      {
        id: 'somewhatOutOfBreath',
        title: 'answers.somewhatOutOfBreath',
        // icon: Assets.quiz_CantBreath,
      },
      {
        id: 'fineAfterOneFlight',
        title: 'answers.fineAfterOneFlight',
        // icon: Assets.quiz_Fine,
      },
      {
        id: 'easilyMultipleFlights',
        title: 'answers.easilyMultipleFlights',
        // icon: Assets.quiz_Sunglasses,
      },
    ],
  },
  typicalDay: {
    question: 'questions.describeDay',
    answers: [
      {
        id: 'mostlySitting',
        title: 'answers.mostlySitting',
        // icon: Assets.quiz_Chair,
      },
      {
        id: 'activeBreaks',
        title: 'answers.activeBreaks',
        // icon: Assets.quiz_Soccer,
      },
      {
        id: 'usuallyOnFeet',
        title: 'answers.usuallyOnFeet',
        // icon: Assets.quiz_Walking,
      },
    ],
  },
  energyLevels: {
    question: 'questions.energyLevel',
    answers: [
      {
        id: 'highSteady',
        title: 'answers.highSteady',
        // icon: Assets.quiz_Battery1,
      },
      {
        id: 'draggingBeforeMeals',
        title: 'answers.draggingBeforeMeals',
        // icon: Assets.quiz_Battery2,
      },
      {
        id: 'postLunchSlump',
        title: 'answers.postLunchSlump',
        // icon: Assets.quiz_Battery3,
      },
      {
        id: 'lowTiredDay',
        title: 'answers.lowTiredDay',
        // icon: Assets.quiz_Battery4,
      },
    ],
  },
  badHabits: {
    question: 'questions.badHabits',
    multiple: true,
    answers: [
      {
        id: 'eatLateNight',
        title: 'answers.eatLateNight',
        // icon: Assets.quiz_Moon,
      },
      {
        id: 'cantLiveWithoutSoda',
        title: 'answers.cantLiveWithoutSoda',
        // icon: Assets.quiz_Soda,
      },
      {
        id: 'tooManyCarbs',
        title: 'answers.tooManyCarbs',
        // icon: Assets.quiz_Pasta,
      },
      {
        id: 'cantQuitSugar',
        title: 'answers.cantQuitSugar',
        // icon: Assets.quiz_Sugar,
      },
      {
        id: 'eatTooMuchSalt',
        title: 'answers.eatTooMuchSalt',
        // icon: Assets.quiz_Salt,
      },
    ],
  },
  weightGain: {
    question: 'questions.weightGain',
    multiple: true,
    answers: [
      {
        id: 'slowerMetabolism',
        title: 'answers.slowerMetabolism',
        // icon: Assets.quiz_Hotdog,
      },
      {
        id: 'financialStruggles',
        title: 'answers.financialStruggles',
        // icon: Assets.quiz_Money,
      },
      {
        id: 'marriageRelationship',
        title: 'answers.marriageRelationship',
        // icon: Assets.quiz_Ring,
      },
      {
        id: 'busyWorkFamilyLife',
        title: 'answers.busyWorkFamilyLife',
        // icon: Assets.quiz_Clock,
      },
      {
        id: 'stressMentalHealth',
        title: 'answers.stressMentalHealth',
        // icon: Assets.quiz_Angry,
      },
    ],
  },
  motivation: {
    question: 'questions.motivation',
    answers: [
      {
        id: 'beingStronger',
        title: 'answers.beingStronger',
        // icon: Assets.quiz_Dumbell,
      },
      {
        id: 'improvingHealth',
        title: 'answers.improvingHealth',
        // icon: Assets.quiz_Health,
      },
      {
        id: 'feelingConfident',
        title: 'answers.feelingConfident',
        // icon: Assets.quiz_Fine,
      },
      {
        id: 'lookingMoreAttractive',
        title: 'answers.lookingMoreAttractive',
        // icon: Assets.quiz_Attractive,
      },
      {
        id: 'releasingStress',
        title: 'answers.releasingStress',
        // icon: Assets.quiz_Yoga,
      },
      {
        id: 'havingMoreEnergy',
        title: 'answers.havingMoreEnergy',
        // icon: Assets.quiz_Battery2,
      },
    ],
  },
  upcomingEvent: {
    question: 'questions.upcomingEvent',
    answers: [
      {
        id: 'wedding',
        title: 'answers.wedding',
        // icon: Assets.quiz_Ring,
      },
      {
        id: 'birthday',
        title: 'answers.birthday',
        // icon: Assets.quiz_Cake,
      },
      {
        id: 'anniversary',
        title: 'answers.anniversary',
        // icon: Assets.quiz_Confetti,
      },
      {
        id: 'vacation',
        title: 'answers.vacation',
        // icon: Assets.quiz_Vacation,
      },
      {
        id: 'friendsReunion',
        title: 'answers.friendsReunion',
        // icon: Assets.quiz_Friends,
      },
      {
        id: 'newJobCareer',
        title: 'answers.newJobCareer',
        // icon: Assets.quiz_Suitcase,
      },
      {
        id: 'other',
        title: 'answers.other',
        // icon: Assets.quiz_Fine,
      },
    ],
  },
  feelAfterTargetWeight: {
    question: 'questions.feelAfterTargetWeight',
    answers: [
      {
        id: 'beingProud',
        title: 'answers.beingProud',
        // icon: Assets.quiz_Cool,
      },
      {
        id: 'worryingLess',
        title: 'answers.worryingLess',
        // icon: Assets.quiz_Happy,
      },
      {
        id: 'moreEnergy',
        title: 'answers.moreEnergy',
        // icon: Assets.quiz_Battery3,
      },
      {
        id: 'feelingSexier',
        title: 'answers.feelingSexier',
        // icon: Assets.quiz_Attractive,
      },
      {
        id: 'increasingConfidence',
        title: 'answers.increasingConfidence',
        // icon: Assets.quiz_Muscle,
      },
    ],
  },
  rewardAfterTargetWeight: {
    question: 'questions.rewardAfterTargetWeight',
    answers: [
      {
        id: 'buyingNewClothes',
        title: 'answers.buyingNewClothes',
        // icon: Assets.quiz_Clothes,
      },
      {
        id: 'takingMorePictures',
        title: 'answers.takingMorePictures',
        // icon: Assets.quiz_Picture,
      },
      {
        id: 'travelingNewPlace',
        title: 'answers.travelingNewPlace',
        // icon: Assets.quiz_Vacation,
      },
      {
        id: 'funHangout',
        title: 'answers.funHangout',
        // icon: Assets.quiz_Fun,
      },
      {
        id: 'takingPersonalDay',
        title: 'answers.takingPersonalDay',
        // icon: Assets.quiz_Cool,
      },
      {
        id: 'surprisingMyself',
        title: 'answers.surprisingMyself',
        // icon: Assets.quiz_Gift,
      },
    ],
  },
};
