import { QuizPage } from '@/utils/Types';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomButton from '../Button';
import { colors } from '@/utils/Constants';
import { styled } from 'styled-components';
import CustomTypography from '../Typography';
import useIsPhone from '@/utils/Hooks/usePhone';
import { useEffect, useRef } from 'react';

const InputPage = ({ params, answers, setAnswers, setCurrentPage }: QuizPage) => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswers((prev) => ({
      ...prev,
      [params.id]: e.target.value,
    }));
  };

  const onContinue = () => {
    const localStorageData = localStorage.getItem('quizStorage');
    const localStorageCurrentPage = localStorage.getItem('currentPage');

    localStorage.setItem(
      'quizStorage',
      JSON.stringify({
        ...(localStorageData ? JSON.parse(localStorageData) : {}),
        [params.id]: answers[params.id],
      })
    );
    localStorage.setItem(
      'currentPage',
      localStorageCurrentPage ? (parseInt(localStorageCurrentPage, 10) + 1).toString() : '1'
    );
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <Stack alignItems="center" flex={1} pb={isMobile ? '40px' : '126px'}>
      <CustomTypography
        title={t(`titles.${params.id}`)}
        type="headingL"
        color={colors.sand}
        style={{ marginBottom: '40px', textAlign: 'center' }}
      />

      <TextInput
        type="text"
        ref={inputRef}
        onChange={handleChange}
        value={answers[params.id]}
        placeholder={answers.gender === 'male' ? 'John' : 'Mary'}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && onContinue && answers[params.id]) {
            onContinue();
          }
        }}
      />
      <CustomButton
        isLightTheme={false}
        onClick={onContinue}
        name="buttons.continue"
        disabled={!answers[params.id]}
        style={{ width: '100%', opacity: answers[params.id] ? 1 : 0.5 }}
      />
    </Stack>
  );
};

export default InputPage;

const TextInput = styled.input`
  width: 100%;
  max-width: 480px;
  font-size: 56px;
  margin-bottom: 56px;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid ${colors.accentDark};
  font-family: GeneralSans-Medium;
  text-align: center;
  background-color: transparent;
  color: ${colors.sand};
  -webkit-tap-highlight-color: transparent;
  &::placeholder {
    color: ${colors.sand};
    font-family: GeneralSans-Medium;
    font-size: 56px;
    text-align: center;
    opacity: 0.3;
  }
  &:focus {
    outline: none;
  }
`;
