import { QuizPage } from '@/utils/Types';
import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfoItem from '../InfoItem';
import { colors } from '@/utils/Constants';
import CustomButton from '../Button';
import CustomTypography from '../Typography';
import Line from '../Line';
import { Health, Profile, WatchStatus, Weight, YogaGirl2 } from '@/assets';
import Card from '../Card';
import CakeGraphic from '../CakeGraphic';
import useIsPhone from '@/utils/Hooks/usePhone';
import SecureInfo from '../SecureInfo';
import { bmi } from '@/utils/Helper';
import AsSeenOn from '../AsSeenOn';

const ranges = [
  { from: 0, to: 18.5 },
  { from: 18.5, to: 25 },
  { from: 25, to: 30 },
  { from: 30, to: 40 },
  { from: 40, to: 100 },
];

const BMIPreviewPage = ({ onSelect, answers }: QuizPage) => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();

  const onContinue = () => {
    onSelect('', '');
  };

  const bodyType = ['slim', 'midSized'].includes(answers.currentBody)
    ? t('titles.ectomorph')
    : t('titles.endomorph');

  const lifeStyle = useMemo(() => {
    let lifeStyle = t('titles.sedentary');
    if (answers.typicalDay === 'mostlySitting') {
      lifeStyle = t('titles.sedentary');
    } else if (answers.typicalDay === 'activeBreaks') {
      lifeStyle = t('titles.balanced');
    } else if (answers.typicalDay === 'usuallyOnFeet') {
      lifeStyle = t('titles.active');
    }
    return lifeStyle;
  }, [answers.typicalDay]);

  const fitnessLevel = useMemo(() => {
    let fitnessLevel = t('titles.beginner');
    if (['outOfBreathCantTalk', 'somewhatOutOfBreath'].includes(answers.fellAfterStairs)) {
      fitnessLevel = t('titles.beginner');
    } else if (answers.fellAfterStairs === 'fineAfterOneFlight') {
      fitnessLevel = t('titles.intermediate');
    } else if (answers.fellAfterStairs === 'easilyMultipleFlights') {
      fitnessLevel = t('titles.advanced');
    }
    return fitnessLevel;
  }, [answers.fellAfterStairs]);

  const metabolism = useMemo(() => {
    let metabolism = t('titles.slow');
    if (answers.fitnessExperience === 'hardToLoseWeight') {
      metabolism = t('titles.slow');
    } else if (answers.fitnessExperience === 'gainLoseEasily') {
      metabolism = t('titles.normal');
    } else if (answers.fitnessExperience === 'troubleGainingWeight') {
      metabolism = t('titles.fast');
    }
    return metabolism;
  }, [answers.fitnessExperience]);

  const degrees = useMemo(() => {
    const onePart = 36;
    const bmiVal = parseFloat(bmi(answers));
    let index = ranges.findIndex((r) => bmiVal < r.to) + 1;
    if (index <= 0) {
      index = ranges.length;
    }

    const from = ranges[index - 1].from;
    const to = ranges[index - 1].to;
    const part = (bmiVal - from) / (to - from);
    const degrees = (index - 1) * onePart + part * onePart;

    return degrees - 90;
  }, [bmi]);

  return (
    <Stack>
      <Stack alignItems="center" mb={isMobile ? '56px' : '40px'}>
        <CustomTypography
          title={t(answers.name) + ','}
          color={colors.sand}
          style={{ marginBottom: '8px', textAlign: 'center' }}
          type="headingL"
        />
        <CustomTypography
          title={t('titles.greatPotential')}
          color={colors.sand}
          style={{ marginBottom: '20px', textAlign: 'center' }}
          type="bodyMedium"
        />
        <CustomTypography
          title={t('titles.basedOnData', {
            gender: answers.gender === 'male' ? t('titles.men') : t('titles.women'),
            age: answers.currentAge - (answers.currentAge % 10),
          })}
          color={colors.sand}
          style={{ fontSize: '14px', textAlign: 'center' }}
          type="bodyMedium"
        />
      </Stack>
      <Card
        bgColor={colors.sand}
        cutOutColor={colors.accent}
        style={isMobile ? { marginLeft: '-20px', marginRight: '-20px' } : {}}
        isMobile={isMobile}
      >
        <Stack
          alignItems="center"
          textAlign="center"
          ml="auto"
          mr="auto"
          style={isMobile ? { width: '220px' } : {}}
        >
          <CustomTypography
            title={t('titles.currentWellness')}
            style={{ marginBottom: '16px' }}
            type="headingS"
          />
          <CustomTypography title={bmi(answers)} style={{ marginBottom: '4px' }} type="headingL" />
          <CustomTypography
            title={t('titles.yourBMI')}
            style={{ marginBottom: '32px' }}
            type="bodyMMedium"
          />
          <CakeGraphic style={{ marginBottom: '32px' }} rotationDegree={degrees} />
        </Stack>
        <Line bgColor={colors.sandDark} style={{ marginBottom: '20px' }} />
        <Stack flexDirection="row" style={{ marginBottom: '32px' }}>
          <Stack gap="16px" style={{ flex: 1 }}>
            <InfoItem title={t('titles.bodyType')} value={t(`${bodyType}`)} image={Profile} />
            <InfoItem title={t('titles.lifestyle')} value={lifeStyle} image={Weight} />
            <InfoItem title={t('titles.fitnessLevel')} value={fitnessLevel} image={WatchStatus} />
            <InfoItem title={t('titles.metabolism')} value={metabolism} image={Health} />
          </Stack>
          <img src={YogaGirl2} />
        </Stack>
        <CustomButton
          type="filled"
          onClick={onContinue}
          name="buttons.continue"
          style={{ width: '100%' }}
        />
        {isMobile && (
          <Stack mt="40px">
            <AsSeenOn isLight={true} />
          </Stack>
        )}
        {isMobile && <SecureInfo isLight={true} />}
      </Card>
    </Stack>
  );
};

export default BMIPreviewPage;
