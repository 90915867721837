import React from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
  condition: boolean;
  redirectTo: string;
}

const ProtectRouter = ({ children, redirectTo, condition }: Props) => {
  return condition ? <>{children}</> : <Navigate to={redirectTo} replace />;
};

export default ProtectRouter;
