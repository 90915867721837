import React, { useEffect, useState } from 'react';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import { REMOTE_CONFIG } from '../Constants';
import { RemoteConfigContextType } from '../Types';
import { toast } from 'react-toastify';
import Bugsnag from '@bugsnag/js';
import { app } from '../firebaseInit';

type Props = {
  children: React.ReactNode;
};

export const RemoteConfigContext = React.createContext<RemoteConfigContextType | null>(null);

const RemoteConfigProvider = ({ children }: Props) => {
  const [remoteConfig, setRemoteConfig] = useState<{ quizOrder: []; quizStepRate: [] } | null>(
    null
  );
  const config = getRemoteConfig(app);

  useEffect(() => {
    fetchAndActivate(config)
      .then(() => {
        const quizOrder = getValue(config, REMOTE_CONFIG.QUIZ_ORDER);
        const quizOrderArr = JSON.parse(quizOrder.asString());

        const quizStepRate = getValue(config, REMOTE_CONFIG.QUIZ_STEP_RATE);
        const quizStepRateArr = JSON.parse(quizStepRate.asString());

        setRemoteConfig({ quizOrder: quizOrderArr, quizStepRate: quizStepRateArr });
      })
      .catch((err) => {
        Bugsnag.notify(new Error(err as string));
        toast.error('Failed to fetch remote config' + err);
      });
  }, []);

  return (
    <RemoteConfigContext.Provider value={remoteConfig}>{children}</RemoteConfigContext.Provider>
  );
};

export default RemoteConfigProvider;
