import { Stack } from '@mui/material';
import CustomTypography from './Typography';

type InfoProps = {
  image: any;
  title: string;
  value: string;
  color?: string;
};

const InfoItem = ({ image, title, value, color }: InfoProps) => {
  return (
    <Stack flexDirection="row" gap="10px" mb="16px">
      <img src={image} width="24px" height="24px" />
      <Stack>
        <CustomTypography title={title} type="bodySemibold" />
        <CustomTypography title={value} type="bodyMMedium" color={color} />
      </Stack>
    </Stack>
  );
};

export default InfoItem;
