import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useLocalStorage() {
  const { t } = useTranslation();

  const answersStr = localStorage.getItem('quizStorage');
  const checkTermsStr = localStorage.getItem('checkedTerms');
  const email = localStorage.getItem('email');
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const paid = localStorage.getItem('paid');
  const completedQuiz = localStorage.getItem('completedQuiz');
  const finishedQuiz = localStorage.getItem('finishedQuiz');
  const prevPathname = localStorage.getItem('prevPathname');

  const answers = answersStr ? JSON.parse(answersStr) : {};
  const checkTerms = (checkTermsStr ? JSON.parse(checkTermsStr) : false) as boolean;
  const targetWeight = answers.targetWeight ? parseInt(answers.targetWeight, 10) : 0;
  const currentWeight = answers.currentWeight ? parseInt(answers.currentWeight, 10) : 0;

  const gainWeight = targetWeight > currentWeight;

  const gainLoseWeight = useMemo(() => {
    const translated = gainWeight ? t('titles.gain') : t('titles.lose');
    return translated.charAt(0).toUpperCase() + translated.slice(1);
  }, [gainWeight, t]);

  const weight = useMemo(() => {
    const weight = Math.abs(targetWeight - currentWeight);
    return gainWeight ? weight : -weight;
  }, []);

  const measurement = useMemo(() => {
    const unit = answers.measurementSystemMetric === 'metric' ? t('titles.kg') : t('titles.lbs');
    return unit.charAt(0).toUpperCase() + unit.slice(1);
  }, [answers.measurementSystemMetric, t]);

  return {
    measurement,
    weight,
    gainWeight,
    answers,
    gainLoseWeight,
    checkTerms,
    email,
    paid,
    completedQuiz,
    firstName,
    lastName,
    finishedQuiz,
    prevPathname,
  };
}
