import { Facebook, Google } from '@/assets';
import { CustomTypography } from '@/components';
import CustomButton from '@/components/Button';
import Line from '@/components/Line';
import Wrapper from '@/components/Wrapper';
import TextInput from '@/components/TextInput';
import { ROUTES, colors, planCards } from '@/utils/Constants';
import { Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import useIsPhone from '@/utils/Hooks/usePhone';
import useLocalStorage from '@/utils/Hooks/useLocalStorage';
import { getCurrentUser, getRedirectRes, linkWithEmailPassword, linkWithFacebook, linkWithGoogle, logout, setUserInfoAfterRegistration, transferSubscription } from '@/utils/AuthMiddleware';
import Bugsnag from '@bugsnag/js';
import { setCollectionDoc } from '@/utils/FirebaseMiddleware';

const RegistrationPage = () => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();
  const navigate = useNavigate();
  const { planId } = useParams();
  const { email, firstName, lastName, answers } = useLocalStorage();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const plan = planCards.find((plan) => plan.id === planId);

  const [values, setValues] = useState<{ [k: string]: string }>({
    email: email || '',
    firstName: firstName || answers.name || '',
    lastName: lastName || '',
    password: '',
  });

  const [error, setError] = useState<{ [k: string]: string }>({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  });

  const changeValue = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (error[key]) {
      setError((prev) => ({ ...prev, [key]: '' }));
    }
    setValues({ ...values, [key]: value });
  };

  const validate = () => {
    const errors: { [k: string]: string } = {};
    
    if (!values.email) {
      errors['email'] = 'validation.emailRequired';
    }
    if (!values.firstName) {
      errors['firstName'] = 'validation.firstNameRequired';
    }
    if (!values.lastName) {
      errors['lastName'] = 'validation.lastNameRequired';
    }
    if (!values.password) {
      errors['password'] = 'validation.passwordRequired';
    }
    if (Object.keys(errors).length) {
      return setError(errors);
    }
    return true;
  };

  const socialsList = useMemo(() => {
    return (
      <>
        <SocialCircle key={0} onClick={() => linkWithGoogle()}>
          <img src={Google}/>
        </SocialCircle>
        <SocialCircle key={1} onClick={() => linkWithFacebook()}>
          <img src={Facebook}/>
        </SocialCircle>
      </>
    );
  }, []);

  useEffect(() => {
    getRedirectRes()
      .then(async (result) => {
        if (result) {
          try {
            const currentUser = getCurrentUser();            
            await setUserInfoAfterRegistration(currentUser?.uid || '', values.firstName, values.lastName, answers)  
          } catch (error) {
            console.log(error);
            Bugsnag.notify(new Error(error as string));  
          }
          await onSubscriptionCompleted(); 
        }
      }).catch(async (error) => {
        const email = error.customData.email;
        try {
          await transferSubscription(email);
          await onSubscriptionCompleted();
        } catch (error) {
          console.log('transferSubscription. error',error);
          Bugsnag.notify(new Error(error as string));
          alert((error as any).message);
        }
      });    
  }, []);

  const onContinue = async () => {
    if (!validate()) return;

    try {
      await linkWithEmailPassword(values.email, values.password);
      try { // not necessary, so wrap in another try/catch
        const currentUser = getCurrentUser();
        await setUserInfoAfterRegistration(currentUser?.uid || '', values.firstName, values.lastName, answers)
      } catch (error) {
        console.log(error);
        Bugsnag.notify(new Error(error as string));
      }
      await onSubscriptionCompleted();
    } catch (error) {
      if ((error as any).code === 'auth/weak-password') {
        setError({password: 'errors.weakPassword'});
      } else {
        Bugsnag.notify(new Error(error as string));
        alert((error as any).message)
      }
    }
  };

  const onSubscriptionCompleted = async () => {
    const currentUser = getCurrentUser();  
    if (currentUser?.uid && currentUser?.email) {
      await setCollectionDoc('users', currentUser.uid, {
        webQuizEmail: currentUser.email,
      });  
    }          

    await logout();
    const language = localStorage.getItem('language') || 'en';
    localStorage.clear();
    localStorage.setItem('language', language);
    navigate(ROUTES.GET_OUR_APP);
  }

  return (
    <Wrapper maxWidth="640px" style={{ minHeight: '100vh' }}>
      <Content $isMobile={isMobile}>
        <CustomTypography
          type={isMobile ? 'generalSansSmallSemibold' : 'generalSansMiddleSemibold'}
          title={t('titles.almostThere')}
          style={{
            textAlign: 'center',
            lineHeight: isMobile ? '40px' : '56px',
          }}
        />
        <CustomTypography
          type="bodyMedium"
          title={t('titles.congratulations', {
            plan: t(plan?.durationStringKey || '', { duration: plan?.duration }),
          })}
          style={{
            textAlign: 'center',
          }}
        />
        <SocialContainer>{socialsList}</SocialContainer>
        <FormContainer $isMobile={isMobile}>
          <Stack flexDirection="row" alignItems="center" mb="24px">
            <Line bgColor={colors.sandDark} style={{ flex: 1 }} />
            <CustomTypography
              title={t('titles.registerEmail')}
              type="bodyMedium"
              style={{ paddingLeft: '24px', paddingRight: '24px' }}
            />
            <Line bgColor={colors.sandDark} style={{ flex: 1 }} />
          </Stack>
          <Stack gap="12px">
            <TextInput
              type="text"
              placeholderTitle={t('titles.email')}
              onChange={changeValue('email')}
              value={values['email']}
              error={error['email']}
              inputStyle={{
                borderRadius: '20px',
                backgroundColor: colors.white,
              }}
            />
            <TextInput
              type="text"
              placeholderTitle={t('titles.firstName')}
              onChange={changeValue('firstName')}
              value={values['firstName']}
              error={error['firstName']}
              inputStyle={{
                borderRadius: '20px',
                backgroundColor: colors.white,
              }}
            />
            <TextInput
              type="text"
              placeholderTitle={t('titles.lastName')}
              onChange={changeValue('lastName')}
              value={values['lastName']}
              error={error['lastName']}
              inputStyle={{
                borderRadius: '20px',
                backgroundColor: colors.white,
              }}
            />
            <TextInput
              type="password"
              placeholderTitle={t('titles.password')}
              onChange={changeValue('password')}
              value={values['password']}
              error={error['password']}
              inputStyle={{
                borderRadius: '20px',
                backgroundColor: colors.white,
              }}
            />
          </Stack>
          <CustomButton
            type="filled"
            onClick={onContinue}
            name="buttons.continue"
            style={{ marginTop: '20px' }}
          />
        </FormContainer>
      </Content>
    </Wrapper>
  );
};

export default RegistrationPage;

const Content = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: ${({ $isMobile }) => ($isMobile ? '0 20px 22px 20px' : '19px 0 245px 0')};
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
`;

const SocialCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: ${colors.white};
  cursor: pointer;
`;

const FormContainer = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${({ $isMobile }) => ($isMobile ? '100%' : '320px')};
`;
