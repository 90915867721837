import { colors, planCards } from '@/utils/Constants';
import { styled } from 'styled-components';
import WeightChart from './WeightChart';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '@/utils/Hooks/useLocalStorage';
import CustomTypography from '../Typography';
import CustomButton from '../Button';
import ExcellentTrustpilot from '../ExcellentTrustpilot';
import CutOut from '../CutOut';
import { scrollToView } from '@/utils/Helper';
import PulseWrapper from '../PulseWrapper';
import useIsPhone from '@/utils/Hooks/usePhone';
import VideoPlayer from '../VideoPlayer';

type Props = {
  onGetMyPlan: (id: string) => void;
};

const WhatIsYoga = ({ onGetMyPlan }: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();
  const { measurement, weight, gainLoseWeight } = useLocalStorage();

  return (
    <Container>
      <CutOut bgColor={colors.sand} />
      <Content style={{ paddingTop: isMobile ? '56px' : '96px' }}>
        <CustomTypography
          color={colors.dark}
          type={isMobile ? 'generalSansSmallSemibold' : 'generalSansBigSemibold'}
          title={
            !weight || weight === 0
              ? t('titles.losePersonal3MonthNeutral')
              : t('titles.losePersonal3Month', {
                  gainWeight: gainLoseWeight,
                  weight: Math.abs(weight),
                  measurement,
                })
          }
          style={{
            textAlign: isMobile ? 'left' : 'center',
            lineHeight: isMobile ? '40px' : '64px',
            padding: isMobile ? '0 20px' : '0',
            paddingBottom: isMobile ? '56px' : '95px',
            color: colors.sand,
          }}
        />
      </Content>
      <WeightChart />
      <Content style={{ padding: isMobile ? '56px 20px 0px 20px' : '87px 0px 0px 0px' }}>
        <CustomTypography
          type={isMobile ? 'generalSansSmallSemibold' : 'generalSansMiddleSemibold'}
          title={t('titles.whtIsYoga')}
          style={{
            textAlign: 'center',
            color: colors.sand,
          }}
        />
        <CustomTypography
          type="bodyMedium"
          title={t('titles.sofaYogaDescription')}
          style={{
            textAlign: 'center',
            color: colors.sand,
            marginTop: '32px',
            lineHeight: '24px',
          }}
        />
        <CustomTypography
          type={isMobile ? 'generalSansSmallSemibold' : 'generalSansMiddleSemibold'}
          title={t('titles.seeFirstExercise')}
          style={{
            textAlign: 'center',
            color: colors.sand,
            marginTop: isMobile ? '56px' : '72px',
            marginBottom: isMobile ? '32px' : '40px',
          }}
        />
        <VideoPlayer url="https://firebasestorage.googleapis.com/v0/b/yoga-on-sofa.appspot.com/o/web%2FDownward dog.mp4?alt=media&token=1a34dd72-a7ce-413b-9576-6a9d5e4a2b7d" />
        <CustomTypography
          color={colors.dark}
          type="bodyMedium"
          title={
            !weight || weight === 0
              ? t('titles.firstPersonalizedNeutral')
              : weight > 0
              ? t('titles.firstPersonalizedGain')
              : t('titles.firstPersonalized')
          }
          style={{
            textAlign: 'center',
            color: colors.sand,
            marginTop: '32px',
            lineHeight: '24px',
            marginBottom: isMobile ? '24px' : '20px',
          }}
        />
        <PulseWrapper style={isMobile ? { width: '100%' } : {}}>
          <CustomButton
            name={t('buttons.getMyPlan')}
            onClick={() => {
              onGetMyPlan(planCards[0].id);
              scrollToView('plansContainer');
            }}
            isLightTheme={false}
            style={isMobile ? { width: '100%' } : { width: '400px' }}
          />
        </PulseWrapper>
        <WrapperTrustpilot>
          <ExcellentTrustpilot isLight={false} border={true} />
        </WrapperTrustpilot>
      </Content>
    </Container>
  );
};

export default WhatIsYoga;

const Container = styled.div`
  width: 100%;
  position: relative;
  background-color: ${colors.accent};
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  padding-bottom: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .react-player {
    border-radius: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
`;

const WrapperTrustpilot = styled.div`
  max-width: 640px;
  width: 100%;
  margin-top: 32px;
  @media (max-width: 768px) {
    margin-top: 24px;
  }
`;
