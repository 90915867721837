// @mui
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Box,
  Slide,
} from '@mui/material';
import { DialogProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from './Button';
import { useTranslation } from 'react-i18next';
import { colors } from '@/utils/Constants';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import CutOut from './CutOut';

// ----------------------------------------------------------------------

export interface CustomDialogProps extends Omit<DialogProps, 'title'> {
  title?: React.ReactNode;
  content: any;
  isActionReverse?: boolean;
  action?: React.ReactNode;
  open: boolean;
  cancelBtnText?: string;
  onClose?: VoidFunction;
  onCloseCancel?: VoidFunction;
  isCutOut?: boolean;
  slideAnimation?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialog({
  title,
  content,
  action,
  isActionReverse,
  open,
  onClose,
  onCloseCancel,
  cancelBtnText,
  isCutOut,
  slideAnimation,
  ...other
}: CustomDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onClose}
      onBackdropClick={onCloseCancel || onClose}
      TransitionComponent={slideAnimation ? Transition : undefined}
      {...other}
    >
      {isCutOut && <CutOut size="big" />}
      {(onClose || title) && (
        <DialogTitle justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {title && <Box flexGrow={1}>{title || ''}</Box>}
            <Box>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
      )}

      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      {action && (
        <DialogActions style={{ width: '100%', padding: 0 }}>
          <Stack
            direction={isActionReverse ? 'column-reverse' : 'row'}
            width="100%"
            alignItems="center"
          >
            {onCloseCancel && (
              <CustomButton
                style={{
                  width: '100%',
                  backgroundColor: 'transparent',
                  border: `2px solid ${colors.sandAccent}`,
                  color: colors.sandAccent,
                }}
                onClick={onCloseCancel}
                name={t('buttons.close')}
              />
            )}
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}
