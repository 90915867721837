import { NortonLogo, McAfeeLogo, LockBlack, LockWhite } from '@/assets';
import { colors } from '@/utils/Constants';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import styled, { CSSProperties } from 'styled-components';
import Line from './Line';
import CustomTypography from './Typography';
import Svg from './Svg';
import { ConfigContext } from '@/utils/Contexts/ConfigContext';
import { useContext } from 'react';
import useIsPhone from '@/utils/Hooks/usePhone';

type Props = {
  isLight?: boolean;
  style?: CSSProperties;
  currentPage?: number;
  secureText?: string;
  showSecureText?: boolean;
  isMultiple?: boolean;
};

const SecureInfo = ({
  isLight,
  style,
  currentPage,
  secureText,
  showSecureText,
  isMultiple,
}: Props) => {
  const isMobile = useIsPhone();
  const { legal } = useContext(ConfigContext);

  return (
    <SecureContainer
      style={style}
      $isMultiple={isMultiple}
      $isMobile={isMobile}
      $isGenderPage={currentPage === 0}
    >
      <Stack width="100%" gap="16px">
        {isMobile && <Line bgColor={colors.sandDark} />}
        <Stack
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          gap="8px"
          justifyContent="center"
        >
          <SecureText>
            <img src={isLight ? LockBlack : LockWhite} width="20px" height="20px" />
            <CustomTypography
              title={t('titles.secureInfo')}
              type="bodyMRegular"
              color={isLight ? colors.dark : colors.sand}
              style={{ textAlign: 'center' }}
            />
          </SecureText>
          <Logos>
            <Svg
              svgImage={<NortonLogo />}
              fill={isLight ? colors.shadow : colors.sand}
              height="16px"
              width="69px"
            />
            <Svg
              svgImage={<McAfeeLogo />}
              fill={isLight ? colors.shadow : colors.sand}
              height="16px"
              width="80px"
            />
          </Logos>
        </Stack>
        {showSecureText && (
          <>
            <Line bgColor={colors.sandDark} />
            <div
              style={{
                fontSize: '14px',
                color: isLight ? colors.dark : colors.sand,
                fontFamily: 'GeneralSans-Regular',
                textAlign: 'center',
              }}
            >
              <Trans
                i18nKey={secureText || 'titles.secure'}
                components={
                  {
                    lnk: (
                      <a
                        href={legal.termsUrlWeb}
                        target="_blank"
                        style={{ color: colors.accentDark, marginLeft: '2px', marginRight: '2px' }}
                      ></a>
                    ),
                    lnkPrivacy: (
                      <a
                        href={legal.privacyPolicyUrlWeb}
                        target="_blank"
                        style={{ color: colors.accentDark, marginLeft: '2px', marginRight: '2px' }}
                      ></a>
                    ),
                    lnkCancellation: (
                      <a
                        href={legal.cancellationPolicyUrl}
                        target="_blank"
                        style={{ color: colors.accentDark, marginLeft: '2px', marginRight: '2px' }}
                      ></a>
                    ),
                    privacyPolicy: t('titles.privacyPolicy'),
                    termsAndCondition: t('titles.termsOfUse'),
                    termsOfService: t('titles.termsOfService'),
                    cancellationsPolicy: t('titles.cancellationsPolicy'),
                  } as any
                }
              />
            </div>
          </>
        )}
      </Stack>
    </SecureContainer>
  );
};

export default SecureInfo;

const SecureContainer = styled.div<{
  $isMobile: boolean;
  $isGenderPage?: boolean;
  $isMultiple?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${({ $isMobile, $isGenderPage, $isMultiple }) =>
    $isGenderPage && $isMobile
      ? '16px'
      : !$isGenderPage && !$isMobile
      ? '24px'
      : $isMultiple && !$isMobile
      ? '20px'
      : $isMobile
      ? '16px'
      : '40px'};
  padding-bottom: ${({ $isMultiple }) => ($isMultiple ? '150px' : '24px')};
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
`;

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const SecureText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
