import { useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomDialog from '@/components/Dialog';
import DialogPrimaryContent from '@/components/Dialogs/DialogPrimaryContent';
import { Stack } from '@mui/material';
import { ROUTES, colors, fadeIn, fadeOut } from '@/utils/Constants';
import Header from '@/components/Header';
import YogaCover from '@/components/YogaCover';
import WhatIsYoga from '@/components/Promotions/WhatIsYoga';
import useLocalStorage from '@/utils/Hooks/useLocalStorage';
import PersonalProgram from '@/components/Promotions/PersonalProgram';
import Media from '@/components/Promotions/Media';
import Users from '@/components/Promotions/Users';
import SocialMedia from '@/components/Promotions/SocialMedia';
import Faq from '@/components/Promotions/Faq';
import Footer from '@/components/Promotions/Footer';
import { generatePath, useNavigate } from 'react-router-dom';
import PersonalPlan from '@/components/Promotions/PersonalPlan';
import useIsPhone from '@/utils/Hooks/usePhone';
import { scrollToView } from '@/utils/Helper';
import { getCurrentUser, signInAnonymous } from '@/utils/AuthMiddleware';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import useWindowSize from '@/utils/Hooks/useWindowSize';
import { useLocation } from 'react-router-dom';
import { trackMetaPixel } from '@/utils/Tracking';

const PromotionsPage = () => {
  const navigate = useNavigate();
  const isMobile = useIsPhone();
  const width = useWindowSize();
  const { checkTerms, email, prevPathname, completedQuiz } = useLocalStorage();
  const [openPrimaryModal, setOpenPrimaryModal] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(checkTerms);
  const [errorTerms, setErrorTerms] = useState(false);
  const [shakeCheckbox, setShakeCheckbox] = useState(false);
  const location = useLocation();

  useEffect(() => {
    trackMetaPixel(false, 'PromotionPageVisited');
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (prevPathname === '/') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }, 400);
  }, []);

  useEffect(() => {
    if (!getCurrentUser()) {
      // sign in, in advance
      signInAnonymous();
    }
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      // console.log('onAuthStateChanged user=', user);
      if (completedQuiz && (!user || !email)) {
        setOpenPrimaryModal(true);
      }
    });
  }, [email]);

  const checkAgreement = () => {
    if (errorTerms) setErrorTerms(false);
    setCheckedTerms((prev) => !prev);
    localStorage.setItem('checkedTerms', JSON.stringify(!checkedTerms));
  };

  const onGetMyPlan = (planId: string) => {
    if (!checkedTerms) {
      setErrorTerms(true);
      if (window.innerWidth <= 1024) {
        // phones and tablets, so plans are displayed vertically - scroll to checkbox
        scrollToView('checkbox');
        setTimeout(() => {
          setShakeCheckbox(true);
        }, 800);
        setTimeout(() => {
          setShakeCheckbox(false);
        }, 1400);
      } else {
        // desktop, so plans are displayed horizontally - no need to scroll
        setShakeCheckbox(true);
        setTimeout(() => {
          setShakeCheckbox(false);
        }, 600);
      }
      return;
    }
    localStorage.setItem('prevPathname', location.pathname);
    const url = generatePath(ROUTES.PAYMENT, {
      planId,
    });
    navigate(url);
  };

  return (
    <Container $isLoaded={Boolean(width)}>
      <Header getPlan={onGetMyPlan} />
      <Stack
        pt="100px"
        sx={{
          '@media (max-width: 768px)': {
            paddingBottom: '48px',
          },
        }}
      >
        <YogaCover />
      </Stack>
      <PersonalPlan
        onGetMyPlan={onGetMyPlan}
        checkAgreement={checkAgreement}
        checkedTerms={checkedTerms}
        errorTerms={errorTerms}
        shakeCheckbox={shakeCheckbox}
      />
      <WhatIsYoga onGetMyPlan={onGetMyPlan} />
      <PersonalProgram />
      <Media />
      <Users onGetMyPlan={onGetMyPlan} />
      <SocialMedia onGetMyPlan={onGetMyPlan} />
      <Faq onGetMyPlan={onGetMyPlan} />
      <Footer />
      <CustomDialog
        open={openPrimaryModal}
        slideAnimation={isMobile ? true : false}
        content={<DialogPrimaryContent closeModal={() => setOpenPrimaryModal(false)} />}
        sx={{
          '& .MuiPaper-root.MuiDialog-paper': isMobile
            ? {
                margin: 0,
                overflow: 'visible',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
              }
            : {
                borderRadius: '24px',
                maxWidth: '700px',
              },
          '& .MuiDialogContent-root': {
            padding: isMobile ? '32px 16px' : '56px 80px',
          },
          '& .MuiDialog-container': isMobile
            ? {
                alignItems: 'flex-end',
                backdropFilter: openPrimaryModal ? 'blur(3px)' : 'blur(0px)',
              }
            : {
                backdropFilter: openPrimaryModal ? 'blur(3px)' : 'blur(0px)',
              },
        }}
      />
    </Container>
  );
};

export default PromotionsPage;

const Container = styled.div<{ $isLoaded: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${colors.sand};
  visibility: ${($isLoaded) => (!$isLoaded ? 'hidden' : 'visible')};
  animation: ${($isLoaded) => (!$isLoaded ? fadeOut : fadeIn)} 0.5s linear;
  transition: visibility 0.5s linear;
`;
