import { colors } from '@/utils/Constants';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
    border: solid 5px white;
  }
`;

const StyledSVG = styled.svg`
  transform: rotate(0deg);
  transform-origin: 50% 50%;
  position: absolute;
`;

const StyledCircle = styled.circle<{ progress: number }>`
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-linecap: round
    ${({ progress }) =>
      progress >= 100 &&
      css`
        animation: ${rotate} 2s linear forwards;
      `};
`;

const StyledText = styled.text`
  fill: ${colors.sandAccent};
  font-family: GeneralSans-Semibold;
  font-size: 32px;
`;

const CircularProgressBar: React.FC = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const radius = 85;
  const stroke = 12;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <Stack
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress
        size={180}
        style={{
          color: colors.sandAccent,
          animationDuration: '9s',
          borderRadius: '50%',
          strokeLinecap: 'round',
        }}
      />
      <StyledSVG height={radius * 2} width={radius * 2}>
        <StyledCircle
          stroke={colors.sand}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          progress={progress}
        />
        <StyledText x="50%" y="50%" textAnchor="middle" dy=".3em">
          {progress}%
        </StyledText>
      </StyledSVG>
    </Stack>
  );
};

export default CircularProgressBar;
