import { colors, planCards } from '@/utils/Constants';
import { styled } from 'styled-components';
import CutOut from '../CutOut';
import { Social, SocialMobile } from '@/assets';
import { scrollToView, sourceSet } from '@/utils/Helper';
import { useTranslation } from 'react-i18next';
import CustomButton from '../Button';
import ExcellentTrustpilot from '../ExcellentTrustpilot';
import CustomTypography from '../Typography';
import PulseWrapper from '../PulseWrapper';
import useIsPhone from '@/utils/Hooks/usePhone';

type Props = {
  onGetMyPlan: (id: string) => void;
};

const SocialMedia = ({ onGetMyPlan }: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();

  return (
    <Container>
      <CutOut bgColor={colors.sand} />
      <CustomTypography
        type={isMobile ? 'generalSansSmallSemibold' : 'generalSansMiddleSemibold'}
        title={t('titles.socialMedia')}
        style={{
          textAlign: 'center',
          lineHeight: isMobile ? '40px' : '56px',
          padding: `120px 20px ${isMobile ? '56px' : '96px'} 20px`,
          color: colors.sand,
        }}
      />
      <ImageContainer>
        <img
          src={isMobile ? SocialMobile : Social}
          width="100%"
          srcSet={sourceSet(isMobile ? SocialMobile : Social)}
        />
      </ImageContainer>
      <Content>
        <PulseWrapper style={isMobile ? { width: '100%' } : {}}>
          <CustomButton
            name={t('buttons.getMyPlan')}
            onClick={() => {
              onGetMyPlan(planCards[0].id);
              scrollToView('plansContainer');
            }}
            isLightTheme={false}
            style={isMobile ? { width: '100%' } : { width: '400px' }}
          />
        </PulseWrapper>
        <div style={{ maxWidth: '640px', width: '100%', marginTop: '32px' }}>
          <ExcellentTrustpilot isLight={false} border={true} />
        </div>
      </Content>
    </Container>
  );
};

export default SocialMedia;

const Container = styled.div`
  width: 100%;
  position: relative;
  background-color: ${colors.accent};
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  padding-bottom: 160px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  padding: 72px 0 0 0;
  @media (max-width: 768px) {
    padding: 56px 20px 0 20px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;
