import {
  RectangleChart,
  RectangleChartMobile,
  GraphicGainWeightDesktop,
  GraphicGainWeightMobile,
} from '@/assets';
import { colors } from '@/utils/Constants';
import { useMemo } from 'react';
import { styled } from 'styled-components';
import useLocalStorage from '@/utils/Hooks/useLocalStorage';
import CustomTypography from '../Typography';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import useIsPhone from '@/utils/Hooks/usePhone';

const WeightChart = () => {
  const isMobile = useIsPhone();
  const { measurement, gainWeight, answers } = useLocalStorage();
  const DEFAULT_CURRENT_WEIGHT = 200;
  const DEFAULT_TARGET_WEIGHT = 155;

  const grid = useMemo(() => {
    let lines = [1, 2, 3, 4, 5, 6, 7];
    const windowWidth = window.innerWidth;
    if (windowWidth <= 768 && windowWidth > 500) {
      lines = [1, 2, 3, 4, 5];
    }
    if (windowWidth >= 920) {
      lines = [1, 2, 3, 4, 5, 6, 7];
    }
    if (isMobile) {
      lines = [1, 2, 3, 4, 5, 6, 7];
    }
    const top = () => {
      if (windowWidth >= 920) {
        return 39;
      }
      if (windowWidth <= 768 && windowWidth > 500) {
        return 30;
      }
      if (isMobile) {
        return 35;
      }
      return 30;
    };

    return lines.map((line, index) => (
      <div
        key={line}
        style={{
          height: '1px',
          width: '100%',
          backgroundColor: colors.sand,
          opacity: 0.5,
          position: 'absolute',
          top: index * top(),
          left: 0,
          zIndex: 1,
        }}
      />
    ));
  }, [isMobile, window.innerWidth]);

  const currEventMonth = useMemo(() => {
    return dayjs(answers.eventDate).format('MMMM');
  }, [answers.eventDate]);

  const nextEventMonth = useMemo(() => {
    return dayjs(answers.eventDate).add(3, 'months').format('MMMM');
  }, [answers.eventDate]);

  const graphicImage = useMemo(() => {
    if (isMobile) {
      if (gainWeight) {
        return GraphicGainWeightMobile;
      }
      return RectangleChartMobile;
    } else {
      if (gainWeight) {
        return GraphicGainWeightDesktop;
      }
      return RectangleChart;
    }
  }, [isMobile]);

  return (
    <Container>
      <img src={graphicImage} style={{ zIndex: 2 }} />
      <Stack>{grid}</Stack>
      <VerticalLine
        position={isMobile ? '17%' : '30%'}
        height={gainWeight ? 34 : 100}
        top={gainWeight ? '65.5%' : '0%'}
      >
        <Dot />
        <Label>
          <CustomTypography
            title={answers.currentWeight || DEFAULT_CURRENT_WEIGHT}
            type="headingM"
            color={colors.white}
            style={{ wordBreak: 'unset' }}
          />
          <CustomTypography
            title={measurement.toString()}
            type="headingM"
            color={colors.white}
            style={{ wordBreak: 'unset' }}
          />
        </Label>
        {!isMobile && (
          <MonthTitle position={gainWeight ? '115%' : '105%'}>
            <CustomTypography
              title={currEventMonth}
              type="headingM"
              color={colors.white}
              style={{ wordBreak: 'unset' }}
            />
          </MonthTitle>
        )}
      </VerticalLine>
      <VerticalLine
        position="82%"
        height={!gainWeight ? 34 : 100}
        top={!gainWeight ? '65.5%' : '0%'}
      >
        <Dot />
        <Label $bgColor={colors.nature}>
          <CustomTypography
            title={answers.targetWeight || DEFAULT_TARGET_WEIGHT}
            type="headingM"
            color={colors.white}
            style={{ wordBreak: 'unset' }}
          />
          <CustomTypography
            title={measurement.toString()}
            type="headingM"
            color={colors.white}
            style={{ wordBreak: 'unset' }}
          />
        </Label>
        {!isMobile && (
          <MonthTitle position={!gainWeight ? '115%' : '105%'}>
            <CustomTypography
              title={nextEventMonth}
              type="headingM"
              color={colors.white}
              style={{ wordBreak: 'unset' }}
            />
          </MonthTitle>
        )}
      </VerticalLine>
    </Container>
  );
};

export default WeightChart;

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  border-bottom: 1px solid ${colors.sand};
`;

const VerticalLine = styled.div<{ position: string; height: number; top?: string }>`
  width: 1px;
  height: ${(props) => props.height}%;
  position: absolute;
  left: ${(props) => props.position};
  top: ${(props) => props.top || 0};
  background-color: ${colors.sand};
  z-index: 3;
`;

const Dot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${colors.sand};
  position: absolute;
  top: 99.2%;
  left: -3px;
  z-index: 3;
`;

const Label = styled.div<{ $bgColor?: string }>`
  display: flex;
  flex-direction: row;
  padding: 12px;
  background-color: ${(props) => props.$bgColor || colors.accentDark};
  border: 2px solid ${colors.sand};
  gap: 8px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 43px;
  z-index: 3;
`;

const MonthTitle = styled.div<{ position: string }>`
  position: absolute;
  top: ${(props) => props.position};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;
