import { doc, getDoc, collection, getDocs, setDoc } from 'firebase/firestore';
import Bugsnag from '@bugsnag/js';
import { db } from './firebaseInit';

export const fetchCollectionDoc = async (collectionName: string, docId: string) => {
  const docRef = doc(db, collectionName, docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log('No such document!');
  }
};

export const fetchCollection = async (collectionName: string) => {
  const collectionRef = collection(db, collectionName);
  const collectionSnapshot = await getDocs(collectionRef);
  const collectionData = collectionSnapshot.docs.map((doc) => doc.data());
  return collectionData as any[];
};

export const setCollectionDoc = async (collectionName: string, docId: string, docContent: Record<string, any>) => {
  const docRef = doc(db, collectionName, docId);
  try {
    await setDoc(docRef, docContent, { merge: true }); // Using merge option to update partially
    // console.log(`Document ${docId} in collection ${collectionName} has been created/updated.`);
  } catch (error) {
    console.error('Error writing document: ', error);
    Bugsnag.notify(new Error(error as string));
  }
};