import { CutOutLeft, CutOutMiddle, CutOutRight, Rectangle } from '@/assets';
import Svg from './Svg';
import { colors } from '@/utils/Constants';
import { styled } from 'styled-components';

type Props = {
  bgColor?: string;
  size?: 'small' | 'big';
};

const CutOut = ({ bgColor, size = 'small' }: Props) => {
  if (size === 'small') {
    return (
      <SmallSize>
        <Svg svgImage={<Rectangle />} width="51px" height="11px" fill={bgColor} />
      </SmallSize>
    );
  }
  return (
    <BigSize>
      <img src={CutOutLeft} />
      <EmptyContainer />
      <img src={CutOutMiddle} />
      <EmptyContainer />
      <img src={CutOutRight} />
    </BigSize>
  );
};

export default CutOut;

const SmallSize = styled.div`
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

const BigSize = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -27px;
  left: 0;
  margin-top: 1px;
`;

const EmptyContainer = styled.div`
  background-color: ${colors.sand};
  width: 100%;
  margin: 0 -1px;
`;
