// SVG component
export { default as ArrowLeft } from './svg/arrow-left.svg?react';
export { default as Minus } from './svg/minus.svg?react';
export { default as Plus } from './svg/add.svg?react';
export { default as McAfeeLogo } from './svg/McAfee_logo.svg?react';
export { default as NortonLogo } from './svg/Norton-logo.svg?react';
export { default as Rectangle } from './svg/Rectangle-4.svg?react';
export { default as RateStar } from './svg/rate-star.svg?react';
export { default as Clock } from './svg/CLock.svg?react';
// -------

export const quiz_ManHeavy = '/assets/quiz/bodies/Man-heavy.png';
export const quiz_ManMid = '/assets/quiz/bodies/Man-mid.png';
export const quiz_ManSlim = '/assets/quiz/bodies/Man-slim.png';
export const quiz_WomanHeavy = '/assets/quiz/bodies/Woman-heavy.png';
export const quiz_WomanMid = '/assets/quiz/bodies/Woman-mid.png';
export const quiz_WomanSlim = '/assets/quiz/bodies/Woman-slim.png';

//ICONS
export const LockBlack = '/assets/icons/lockBlack.svg';
export const LockWhite = '/assets/icons/LockWhite.svg';
export const Male = '/assets/icons/Male.svg';
export const Female = '/assets/icons/Female.svg';
export const Checked = '/assets/icons/checked.svg';
export const CircleCheckedWhite = '/assets/icons/circle-checked-white.svg';
export const Profile = '/assets/icons/profile.svg';
export const Weight = '/assets/icons/weight.svg';
export const WatchStatus = '/assets/icons/watch-status.svg';
export const Health = '/assets/icons/health.svg';
export const Muscle = '/assets/icons/Muscle.png';
export const MagicStar = '/assets/icons/magic-star.svg';
export const Calories = '/assets/icons/Calories.svg';
export const Check = '/assets/icons/tick.svg';
export const Gift = '/assets/icons/gift.svg';
export const SandGift = '/assets/icons/sand-gift.svg';
export const Checkbox = '/assets/icons/Checkbox.svg';
export const Visa = '/assets/icons/Visa.svg';
export const Amex = '/assets/icons/Amex.svg';
export const MasterCard = '/assets/icons/Mastercard.svg';
export const PayPal = '/assets/icons/PayPal.svg';
export const CardNumber = '/assets/icons/CardNumber.svg';
export const Google = '/assets/icons/Google.svg';
export const Facebook = '/assets/icons/Facebook.svg';
export const Apple = '/assets/icons/Apple.svg';
export const Lamp = '/assets/icons/lamp-charge.svg';
export const Flame = '/assets/icons/Flame-filled.svg';
export const CheckedAccent = '/assets/icons/checked_accent.svg';
export const CheckedNature = '/assets/icons/checked_nature.svg';

//IMAGES
export const Logo = '/assets/images/logo.svg';
export const Star = '/assets/images/star.svg';
export const StarShape = '/assets/images/starShape.svg';
export const YogaGirl = '/assets/images/yogingGirl.png';
export const YogaGirl3 = '/assets/images/YogaGirl2.png';
export const BackgroundNature = '/assets/images/bg-Illustration.svg';
export const BackgroundNatureMobile = '/assets/images/Background-nature-mobile.svg';
export const BackgroundNatureMobileLight = '/assets/images/natureBackgroundMobile.svg';
export const BackgroundIllustrationMobile = '/assets/images/Background-Illustration-mobile.svg';
export const BackgroundIllustrationMobile2 = '/assets/images/Background-Illustration-mobile-2.svg';
export const Iliustration1 = '/assets/images/Iliustration-1.png';
export const ArrowDown = '/assets/images/arrowDown.svg';
export const ArrowRight = '/assets/images/ArrowRight.svg';
export const en = '/assets/images/uk_flag.svg';
export const es = '/assets/images/es.svg';
export const InfoCell = '/assets/images/InfoCell.svg';
export const YogaGirl2 = '/assets/images/Illustration-5.svg';
export const BmiChartArrow = '/assets/images/bmi-chart-arrow.png';
export const BmiGraphic = '/assets/images/bmiGraphic.png';
export const RectangleChart = '/assets/images/Rectangle-chart.svg';
export const RectangleChartMobile = '/assets/images/RectangleMobile.svg';
export const MobilePhone = '/assets/images/iPhone-11-Pro.png';
export const Susan = '/assets/images/review-amy.jpg';
export const Jessica = '/assets/images/review-linda.jpg';
export const Maria = '/assets/images/review-john.jpg';
export const FoxLogo = '/assets/images/foxLogo.svg';
export const Philip = '/assets/images/Philip.png';
export const John = '/assets/images/John.png';
export const Thomas = '/assets/images/Thomas.png';
export const Adams = '/assets/images/Adams.png';
export const Tynna = '/assets/images/Tynna.png';
export const Emma = '/assets/images/Emma.png';
export const Tim = '/assets/images/Tim.png';
export const Social = '/assets/images/socialMedia.png';
export const SocialMobile = '/assets/images/socialMobile.png';
export const Illustration3 = '/assets/images/Illustration-3.svg';
export const CutOutRight = '/assets/images/cutOut/corner_right.svg';
export const CutOutLeft = '/assets/images/cutOut/corner_left.svg';
export const CutOutMiddle = '/assets/images/cutOut/middle_big.svg';
export const PayPalLogo = '/assets/images/PayPalLogo.svg';
export const GooglePlay = '/assets/images/GooglePlay.svg';
export const AppleStore = '/assets/images/AppleStore.svg';
export const GraphicGainWeightDesktop = '/assets/images/graphicGainWeightDesktop.svg';
export const GraphicGainWeightMobile = '/assets/images/graphicGainWeightMobile.svg';
export const Ads = '/assets/images/ads.svg';
export const AdsWhite = '/assets/images/ads white.svg';

export const usercard1 = '/assets/images/usercard1.jpg';
export const usercard2 = '/assets/images/usercard2.jpg';
export const usercard3 = '/assets/images/usercard3.jpg';
export const usercard4 = '/assets/images/usercard4.jpg';
export const usercard5 = '/assets/images/usercard5.jpg';
export const usercard6 = '/assets/images/usercard6.jpg';
export const usercard7 = '/assets/images/usercard7.jpg';
export const usercard8 = '/assets/images/usercard8.jpg';
export const usercard9 = '/assets/images/usercard9.jpg';
export const usercard10 = '/assets/images/usercard10.jpg';
export const usercard11 = '/assets/images/usercard11.jpg';
export const usercard12 = '/assets/images/usercard12.jpg';
export const usercard13 = '/assets/images/usercard13.jpg';
export const usercard14 = '/assets/images/usercard14.jpg';
export const usercard15 = '/assets/images/usercard15.jpg';
export const usercard16 = '/assets/images/usercard16.jpg';

// QUIZ IMAGES
export const loseWeight = '/assets/images/quiz/Flame.svg';
export const improveFlexibility = '/assets/images/quiz/Basketball.svg';
export const toneBootyAndAbs = '/assets/images/quiz/Abs.svg';
export const increaseMuscleStrength = '/assets/images/quiz/Muscle.svg';
export const notFlexible = '/assets/images/quiz/notFlexible.png';
export const almostReach = '/assets/images/quiz/almostReach.png';
export const canTouchFeet = '/assets/images/quiz/canTouchFeet.png';
export const notSure = '/assets/images/quiz/notSure.png';
export const slim = '/assets/images/quiz/slim.png';
export const midSized = '/assets/images/quiz/midSized.png';
export const onHeavierSide = '/assets/images/quiz/onHeavierSide.png';
export const overweight = '/assets/images/quiz/overweight.png';
export const skinny = '/assets/images/quiz/skinny.png';
export const toned = '/assets/images/quiz/toned.png';
export const curvy = '/assets/images/quiz/curvy.png';
export const fewSizesSmaller = '/assets/images/quiz/fewSizesSmaller.png';
export const lessThanYearAgo = '/assets/images/quiz/lessThanYearAgo.svg';
export const oneToTwoYearsAgo = '/assets/images/quiz/oneToTwoYearsAgo.svg';
export const moreThanThreeYearsAgo = '/assets/images/quiz/moreThanThreeYearsAgo.svg';
export const never = '/assets/images/quiz/never.svg';
export const practiceRegularly = '/assets/images/quiz/practiceRegularly.svg';
export const triedIt = '/assets/images/quiz/triedIt.svg';
export const neverTried = '/assets/images/quiz/neverTried.svg';
export const daily = '/assets/images/quiz/daily.svg';
export const fewTimesAWeek = '/assets/images/quiz/fewTimesAWeek.svg';
export const fewTimesAMonth = '/assets/images/quiz/fewTimesAMonth.svg';
export const almostNever = '/assets/images/quiz/almostNever.svg';
export const hardToLoseWeight = '/assets/images/quiz/hardToLoseWeight.svg';
export const gainLoseEasily = '/assets/images/quiz/gainLoseEasily.svg';
export const troubleGainingWeight = '/assets/images/quiz/troubleGainingWeight.svg';
export const onlyTeaCoffee = '/assets/images/quiz/onlyTeaCoffee.svg';
export const oneTwoGlasses = '/assets/images/quiz/oneTwoGlasses.svg';
export const twoSixGlasses = '/assets/images/quiz/twoSixGlasses.svg';
export const moreThanSixGlasses = '/assets/images/quiz/moreThanSixGlasses.svg';
export const eightPlusHours = '/assets/images/quiz/eightPlusHours.svg';
export const sevenEightHours = '/assets/images/quiz/sevenEightHours.svg';
export const sixSevenHours = '/assets/images/quiz/sixSevenHours.svg';
export const lessThanSixHours = '/assets/images/quiz/lessThanSixHours.svg';
export const yesAlways = '/assets/images/quiz/yesAlways.svg';
export const no = '/assets/images/quiz/no.svg';
export const sometimes = '/assets/images/quiz/sometimes.svg';
export const belly = '/assets/images/quiz/belly.svg';
export const booty = '/assets/images/quiz/booty.svg';
export const legs = '/assets/images/quiz/legs.svg';
export const wholeBody = '/assets/images/quiz/wholeBody.svg';
export const back = '/assets/images/quiz/back.svg';
export const knees = '/assets/images/quiz/knees.svg';
export const ankles = '/assets/images/quiz/ankles.svg';
export const wrist = '/assets/images/quiz/wrist.svg';
export const shoulders = '/assets/images/quiz/shoulders.svg';
export const outOfBreathCantTalk = '/assets/images/quiz/outOfBreathCantTalk.svg';
export const somewhatOutOfBreath = '/assets/images/quiz/somewhatOutOfBreath.svg';
export const fineAfterOneFlight = '/assets/images/quiz/fineAfterOneFlight.svg';
export const easilyMultipleFlights = '/assets/images/quiz/easilyMultipleFlights.svg';
export const mostlySitting = '/assets/images/quiz/mostlySitting.svg';
export const activeBreaks = '/assets/images/quiz/activeBreaks.svg';
export const usuallyOnFeet = '/assets/images/quiz/usuallyOnFeet.svg';
export const highSteady = '/assets/images/quiz/highSteady.svg';
export const draggingBeforeMeals = '/assets/images/quiz/draggingBeforeMeals.svg';
export const postLunchSlump = '/assets/images/quiz/postLunchSlump.svg';
export const lowTiredDay = '/assets/images/quiz/lowTiredDay.svg';
export const eatLateNight = '/assets/images/quiz/eatLateNight.svg';
export const cantLiveWithoutSoda = '/assets/images/quiz/cantLiveWithoutSoda.svg';
export const tooManyCarbs = '/assets/images/quiz/tooManyCarbs.svg';
export const cantQuitSugar = '/assets/images/quiz/cantQuitSugar.svg';
export const eatTooMuchSalt = '/assets/images/quiz/eatTooMuchSalt.svg';
export const slowerMetabolism = '/assets/images/quiz/slowerMetabolism.svg';
export const financialStruggles = '/assets/images/quiz/financialStruggles.svg';
export const marriageRelationship = '/assets/images/quiz/marriageRelationship.svg';
export const busyWorkFamilyLife = '/assets/images/quiz/busyWorkFamilyLife.svg';
export const stressMentalHealth = '/assets/images/quiz/stressMentalHealth.svg';
export const beingStronger = '/assets/images/quiz/beingStronger.svg';
export const improvingHealth = '/assets/images/quiz/improvingHealth.svg';
export const feelingConfident = '/assets/images/quiz/feelingConfident.svg';
export const lookingMoreAttractive = '/assets/images/quiz/lookingMoreAttractive.svg';
export const releasingStress = '/assets/images/quiz/releasingStress.svg';
export const havingMoreEnergy = '/assets/images/quiz/havingMoreEnergy.svg';
export const wedding = '/assets/images/quiz/wedding.svg';
export const birthday = '/assets/images/quiz/birthday.svg';
export const anniversary = '/assets/images/quiz/anniversary.svg';
export const vacation = '/assets/images/quiz/vacation.svg';
export const friendsReunion = '/assets/images/quiz/friendsReunion.svg';
export const newJobCareer = '/assets/images/quiz/newJobCareer.svg';
export const other = '/assets/images/quiz/other.svg';
export const beingProud = '/assets/images/quiz/beingProud.svg';
export const worryingLess = '/assets/images/quiz/worryingLess.svg';
export const moreEnergy = '/assets/images/quiz/moreEnergy.svg';
export const feelingSexier = '/assets/images/quiz/feelingSexier.svg';
export const increasingConfidence = '/assets/images/quiz/increasingConfidence.svg';
export const buyingNewClothes = '/assets/images/quiz/buyingNewClothes.svg';
export const takingMorePictures = '/assets/images/quiz/takingMorePictures.svg';
export const travelingNewPlace = '/assets/images/quiz/travelingNewPlace.svg';
export const funHangout = '/assets/images/quiz/funHangout.svg';
export const takingPersonalDay = '/assets/images/quiz/takingPersonalDay.svg';
export const surprisingMyself = '/assets/images/quiz/surprisingMyself.svg';
export const health = '/assets/images/quiz/Health.svg';

export const quiz_M_Curvy = '/assets/images/bodyTypes/M-Curvy.png';
export const quiz_M_FewSizesSmaller = '/assets/images/bodyTypes/M-Few-sizes-smaller.png';
export const quiz_M_Heavier = '/assets/images/bodyTypes/M-Heavier.png';
export const quiz_M_Mid = '/assets/images/bodyTypes/M-Mid.png';
export const quiz_M_Overweight = '/assets/images/bodyTypes/M-Overweight.png';
export const quiz_M_Skinny = '/assets/images/bodyTypes/M-Skinny.png';
export const quiz_M_Slim = '/assets/images/bodyTypes/M-Slim.png';
export const quiz_M_Toned = '/assets/images/bodyTypes/M-Toned.png';
export const quiz_F_Curvy = '/assets/images/bodyTypes/F-Curvy.png';
export const quiz_F_FewSizesSmaller = '/assets/images/bodyTypes/F-Few-sizes-smaller.png';
export const quiz_F_Heavier = '/assets/images/bodyTypes/F-Heavier.png';
export const quiz_F_Mid = '/assets/images/bodyTypes/F-Mid.png';
export const quiz_F_Overweight = '/assets/images/bodyTypes/F-Overweight.png';
export const quiz_F_Skinny = '/assets/images/bodyTypes/F-Skinny.png';
export const quiz_F_Slim = '/assets/images/bodyTypes/F-Slim.png';
export const quiz_F_Toned = '/assets/images/bodyTypes/F-Toned.png';
