import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Circle from '../Circle';
import { useEffect, useState } from 'react';
import { ROUTES, colors, processingSteps } from '@/utils/Constants';
import FakeLoader from '../FakeLoader';
import { useNavigate } from 'react-router-dom';
import SecureInfo from '../SecureInfo';
import CustomTypography from '../Typography';
import { styled } from 'styled-components';
import useIsPhone from '@/utils/Hooks/usePhone';
import { useLocation } from 'react-router-dom';
import AsSeenOn from '../AsSeenOn';

const ProcessingPage = () => {
  const [checkedSteps, setCheckedSteps] = useState<number[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsPhone();
  const location = useLocation();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (checkedSteps.length === processingSteps.length) {
      localStorage.setItem('completedQuiz', 'true');
      localStorage.setItem('prevPathname', location.pathname);
      localStorage.setItem('checkedTerms', 'true');
      navigate(ROUTES.PROMOTIONS);
      return () => {
        clearInterval(timer);
      };
    }
    timer = setInterval(() => {
      setCheckedSteps((prev) => [...prev, prev.length + 1]);
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, [checkedSteps]);

  return (
    <Container>
      <Content>
        <Stack alignItems="center">
          <FakeLoader />
        </Stack>
        <Stack alignItems="center" mt={isMobile ? '24px' : '40px'} mb={isMobile ? '40px' : '56px'}>
          <CustomTypography
            title={t('titles.pleaseWait')}
            type="headingL"
            color={colors.sand}
            style={{ textAlign: 'center', marginBottom: '8px' }}
          />
          <CustomTypography
            title={t('titles.programProcessing')}
            type="bodyMedium"
            color={colors.sand}
            style={{ textAlign: 'center' }}
          />
        </Stack>
        <Stack style={{ gap: 16, alignItems: 'center' }}>
          {processingSteps.map((step: { id: number; title: string }) => (
            <Stack
              key={step.id}
              style={{
                backgroundColor: checkedSteps.includes(step.id) ? colors.sand : colors.white,
                borderRadius: 24,
                padding: 12,
                gap: 12,
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div>
                <Circle
                  isSelected={checkedSteps.includes(step.id)}
                  isAnimate={checkedSteps.includes(step.id)}
                  selectedBgColor={colors.accent}
                />
              </div>
              <CustomTypography
                title={t(step.title)}
                type="bodyMSemibold"
                color={checkedSteps.includes(step.id) ? colors.dark : colors.shadow}
              />
            </Stack>
          ))}
        </Stack>
        <div
          style={{
            paddingTop: isMobile ? '40px' : '56px',
          }}
        >
          <AsSeenOn isLight={false} />
        </div>
        <SecureInfo isLight={false} showSecureText={false} />
      </Content>
    </Container>
  );
};

export default ProcessingPage;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  width: 100%;
  background-color: ${colors.nature};
  @media (max-width: 768px) {
    padding-top: 40px;
  }
`;

const Content = styled.div`
  width: 480px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 0px 20px 0px 20px;
  }
`;
