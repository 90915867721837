export const trackMetaPixel = (isStandard: boolean, eventName: string, params?: object) => {
  if (import.meta.env.VITE_ENV === 'prod') {
    if (params) {
      fbq(isStandard ? 'track' : 'trackCustom', eventName, params);
    } else {
      fbq(isStandard ? 'track' : 'trackCustom', eventName);
    }
  } else {
    console.log('trackMetaPixel', isStandard ? 'track' : 'trackCustom', eventName, params);
  }
};