import { Star, StarShape } from '@/assets';
import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { CustomTypography } from '.';
import { colors } from '@/utils/Constants';
import useIsPhone from '@/utils/Hooks/usePhone';
import { useMemo } from 'react';

type Props = {
  isLight?: boolean;
  border?: boolean;
  justTrustpilot?: boolean;
};

const ExcellentTrustpilot = ({ isLight, border, justTrustpilot }: Props) => {
  const isMobile = useIsPhone();

  const renderStars = useMemo(() => {
    return Array.from({ length: 5 }, (_, i) => (
      <img src={Star} alt="star" width="24px" height="24px" key={i} />
    ));
  }, []);

  const content = useMemo(() => {
    if (justTrustpilot) {
      return (
        <Stack flexDirection="row" alignItems="center" justifyContent="center">
          <img src={StarShape} alt="StarShape" width="24px" />
          <Typography
            color={isLight ? colors.dark : colors.sand}
            style={{
              fontSize: '18px',
              fontFamily: 'Inter-SemiBold',
              textAlign: 'center',
              marginLeft: '8px',
              marginRight: '12px',
            }}
          >
            {t('titles.trustpilot')}
          </Typography>
          <Stack flexDirection="row" alignItems="center" gap="5px">
            {renderStars}
          </Stack>
        </Stack>
      );
    }
    if (isMobile) {
      return (
        <Stack alignItems="center">
          <Stack flexDirection="row" mb="12px" gap="8px">
            <img src={StarShape} alt="StarShape" width="24px" />
            <Typography
              color={isLight ? colors.dark : colors.sand}
              style={{
                fontSize: '18px',
                fontFamily: 'Inter-SemiBold',
                textAlign: 'center',
              }}
            >
              {t('titles.trustpilot')}
            </Typography>
          </Stack>
          <Stack flexDirection="row" alignItems="center" gap="5px">
            {renderStars}
          </Stack>
          <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="4px" mt="8px">
            <Typography
              color={isLight ? colors.dark : colors.sand}
              style={{ fontSize: '12px', fontFamily: 'Inter-Bold' }}
            >
              {438}
            </Typography>
            <Typography
              color={isLight ? colors.dark : colors.sand}
              style={{
                fontFamily: 'Inter-Regular',
                fontSize: '12px',
                textAlign: 'center',
              }}
            >
              {t('titles.reviewsOn')}
            </Typography>
          </Stack>
        </Stack>
      );
    }
    return (
      <>
        <CustomTypography
          color={isLight ? colors.dark : colors.sand}
          type="headingS"
          title={t('titles.excellent')}
          style={{
            fontSize: '18px',
            fontFamily: 'Roboto-Regular',
            fontWeight: 600,
            maxWidth: '120px',
            textAlign: 'center',
            wordBreak: 'unset',
          }}
        />
        <Stack flexDirection="row" alignItems="center" gap="5px">
          {renderStars}
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap="4px"
          maxWidth="120px"
        >
          <Typography
            color={isLight ? colors.dark : colors.sand}
            style={{ fontSize: '12px', fontFamily: 'Inter-Bold' }}
          >
            {438}
          </Typography>
          <Typography
            color={isLight ? colors.dark : colors.sand}
            style={{
              fontFamily: 'Inter-Regular',
              fontSize: '12px',
              textAlign: 'center',
              whiteSpace: 'pre',
            }}
          >
            {t('titles.reviewsOn')}
          </Typography>
        </Stack>
        <img src={StarShape} alt="StarShape" width="24px" />
        <Typography
          color={isLight ? colors.dark : colors.sand}
          style={{
            fontSize: '18px',
            fontFamily: 'Inter-SemiBold',
            maxWidth: '200px',
            textAlign: 'center',
          }}
        >
          {t('titles.trustpilot')}
        </Typography>
      </>
    );
  }, [isMobile, isLight, renderStars, justTrustpilot]);

  return (
    <Stack
      flexDirection="row"
      style={
        border
          ? {
              borderBottom: `1px solid ${colors.lightShadow}`,
              borderTop: `1px solid ${colors.lightShadow}`,
              padding: justTrustpilot ? '12px 0' : '14px 0',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '30px',
            }
          : { gap: '30px', alignItems: 'center' }
      }
    >
      {content}
    </Stack>
  );
};

export default ExcellentTrustpilot;
