import QuizItem from '../QuizItem';
import { QuizPage } from '@/utils/Types';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import CustomButton from '../Button';
import CustomTypography from '../Typography';
import { colors } from '@/utils/Constants';
import useIsPhone from '@/utils/Hooks/usePhone';
import { styled } from 'styled-components';

const AnswersPage = ({ params, onSelect, answers, setAnswers, setCurrentPage }: QuizPage) => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();

  const select = (key: string, value: string) => {
    if (params.multiple) {
      if (answers[params.id]?.includes(value)) {
        setAnswers((prev) => ({
          ...prev,
          [params.id]: (prev[params.id as keyof typeof prev] as string[]).filter(
            (item) => item !== value
          ),
        }));
        return;
      }
      setAnswers((prev) => ({
        ...prev,
        [params.id]: prev[params.id as keyof typeof prev]
          ? [...(prev[params.id as keyof typeof prev] as string[]), value]
          : [value],
      }));
      return;
    }
    onSelect(key, value);
  };

  const onContinue = () => {
    const localStorageCurrentPage = localStorage.getItem('currentPage');
    const localStorageData = localStorage.getItem('quizStorage');
    localStorage.setItem(
      'quizStorage',
      JSON.stringify({
        ...(localStorageData ? JSON.parse(localStorageData) : {}),
        [params.id]: answers[params.id],
      })
    );
    localStorage.setItem(
      'currentPage',
      localStorageCurrentPage ? (parseInt(localStorageCurrentPage, 10) + 1).toString() : '1'
    );
    setCurrentPage((prev) => prev + 1);
  };

  const onSkip = () => {
    const localStorageCurrentPage = localStorage.getItem('currentPage');
    localStorage.setItem(
      'currentPage',
      localStorageCurrentPage ? (localStorageCurrentPage + 2).toString() : '1'
    );
    setCurrentPage((prev) => prev + 2);
  };

  return (
    <Stack alignItems="center" flex={1}>
      <Stack
        style={{
          marginBottom: isMobile ? '24px' : '40px',
        }}
      >
        <CustomTypography
          title={t(params.question)}
          type="headingL"
          style={{ textAlign: 'center', fontSize: isMobile ? '24px' : '32px' }}
        />
        {params.multiple && (
          <CustomTypography
            title={t('titles.selectAllThatApply')}
            type="bodyMedium"
            style={{ textAlign: 'center', marginTop: '8px' }}
          />
        )}
      </Stack>
      <Stack
        sx={{
          gap: isMobile ? '16px' : '20px',
          width: '100%',
          marginBottom: isMobile ? '24px' : '32px',
        }}
      >
        {params.answers.map((item, index) => (
          <QuizItem
            key={item.id}
            params={params}
            item={item}
            onSelect={select}
            index={index}
            selected={answers[params.id]?.includes(item.id)}
            gender={answers.gender}
          />
        ))}
      </Stack>
      {params.multiple && (
        <FixedWrapper>
          <CustomButton
            type="filled"
            onClick={onContinue}
            name="buttons.continue"
            style={{ maxWidth: '480px', width: '100%' }}
          />
        </FixedWrapper>
      )}
      <div
        style={{
          position: 'fixed',
          bottom: -100,
          background: colors.sand,
          width: '100%',
          height: 100,
          left: 0,
          right: 0,
        }}
      />
      {params.id === 'upcomingEvent' && (
        <CustomButton
          isLightTheme={false}
          onClick={onSkip}
          name="buttons.skip"
          style={{
            backgroundColor: 'transparent',
            border: `2px solid ${colors.accentDark}`,
            color: colors.accentDark,
            width: '100%',
          }}
        />
      )}
    </Stack>
  );
};

export default AnswersPage;

const FixedWrapper = styled(Stack)`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 100;
  background-color: ${colors.sand};
  padding: 32px;
  box-shadow: 0px -2px 15px 0 rgba(0, 0, 0, 0.25);
  @media (max-width: 768px) {
    padding: 20px;
  }
`;
