import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import CustomButton from "../Button";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { t } from "i18next";

type Props = {
  onSubmit?: (stripe: Stripe, elements: StripeElements) => Promise<void>;
};

const StripeCheckoutForm = ({ onSubmit }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    if (!stripe || !elements || !onSubmit) {
      return;
    }
    await onSubmit(stripe, elements);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <CustomButton 
        onClick={() => handleSubmit()}
        name={t('buttons.pay')} 
        style={{ marginTop: '8px', width: '100%' }} 
      />
    </form>
  );
};

export default StripeCheckoutForm;
