import { useMemo } from 'react';
import styled from 'styled-components';
import * as Assets from '@/assets';
import { useTranslation } from 'react-i18next';
import CustomTypography from './Typography';
import useIsPhone from '@/utils/Hooks/usePhone';
import { colors } from '@/utils/Constants';

type Props = {
  isLight?: boolean;
};

const AsSeenOn = ({ isLight }: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsPhone();

  const render = useMemo(() => {
    return (
      <Container $isMobile={isMobile}>
        {!isMobile && (
          <CustomTypography
            title={t('titles.asSeenOn') + ':'}
            type="bodyMRegular"
            color={isLight ? colors.dark : colors.sand}
            style={{ minWidth: '80px' }}
          />
        )}
        <img src={Assets[isLight ? 'Ads' : 'AdsWhite']} alt="Ads" width="100%" height="100%" />
      </Container>
    );
  }, [isMobile, t, isLight]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        gap: '16px',
        maxWidth: '480px',
      }}
    >
      {isMobile && (
        <CustomTypography
          title={t('titles.asSeenOn') + ':'}
          type="bodyMRegular"
          color={isLight ? colors.dark : colors.sand}
        />
      )}
      {render}
    </div>
  );
};

export default AsSeenOn;

const Container = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 30px;
  padding: ${({ $isMobile }) => `4px ${$isMobile ? '0px' : '0px'}`};
`;
