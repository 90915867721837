import { QuizPage, Value } from '@/utils/Types';
import { Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTypography from '../Typography';
import { colors } from '@/utils/Constants';
import CustomButton from '../Button';
import CalendarComponent from '../Calendar';
import useIsPhone from '@/utils/Hooks/usePhone';
import dayjs from 'dayjs';

const EventDatePage = ({ params, onSelect, answers }: QuizPage) => {
  const isMobile = useIsPhone();

  const calendarDate = useMemo(() => {
    const now = new Date();
    return answers?.eventDate
      ? new Date(answers?.eventDate)
      : new Date(now.setDate(now.getDate() + 1));
  }, [answers?.eventDate]);

  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = React.useState<Value>(calendarDate);

  const onContinue = () => {
    if (selectedDate) {
      onSelect(params.id, dayjs(selectedDate?.toString()).format('YYYY-MM-DD'));
    }
  };

  const onSkip = () => {
    onSelect(params.id, '');
  };
  return (
    <Stack>
      <CustomTypography
        title={t('titles.eventDate')}
        type="headingL"
        color={colors.sand}
        style={{ textAlign: 'center', marginBottom: '8px' }}
      />
      <CustomTypography
        title={t('titles.eventDateDesc')}
        type="bodyMedium"
        color={colors.sand}
        style={{ textAlign: 'center', marginBottom: isMobile ? '64px' : '56px' }}
      />
      <CalendarComponent setValue={setSelectedDate} selectedDate={selectedDate} />
      <CustomButton
        isLightTheme={false}
        onClick={onContinue}
        name="buttons.continue"
        disabled={!selectedDate}
        style={{ marginBottom: isMobile ? '20px' : '16px', marginTop: isMobile ? '56px' : '32px' }}
      />
      <CustomButton
        isLightTheme={false}
        onClick={onSkip}
        name="buttons.skip"
        style={{
          backgroundColor: 'transparent',
          border: `2px solid ${colors.sandAccent}`,
          color: colors.sandAccent,
        }}
      />
    </Stack>
  );
};

export default EventDatePage;
