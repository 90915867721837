import { styled } from 'styled-components';

type Props = {
  height: string;
  bgColor: string;
};

const VerticalLine = ({ height, bgColor }: Props) => {
  return <Container height={height} $bgColor={bgColor} />;
};

export default VerticalLine;

const Container = styled.div<{ height: string; $bgColor: string }>`
  width: 1px;
  height: ${({ height }) => height};
  background-color: ${({ $bgColor }) => $bgColor};
`;
