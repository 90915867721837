import { keyframes, styled } from 'styled-components';

interface Props {
  children: React.ReactNode;
  isLight?: boolean;
  style?: React.CSSProperties;
}

const PulseWrapper = ({ children, style, isLight = true }: Props) => {
  return (
    <Container $isLight={isLight} style={style}>
      {children}
    </Container>
  );
};

export default PulseWrapper;

const brightPulse = keyframes`
0% {
  box-shadow: 0 0 0 0 #FFFFFF88;
},
40% {
  box-shadow: 0 0 0 15px #FFFFFF00;
},
100% {
  box-shadow: 0 0 0 0 #FFFFFF00;
}
`;

const darkPulse = keyframes`
0% {
  box-shadow: 0 0 0 0 #828282
},
40% {
  box-shadow: 0 0 0 15px #FFFFFF00;
},
100% {
  box-shadow: 0 0 0 0 #FFFFFF00;
}
`;

const Container = styled.div<{ $isLight?: boolean }>`
  border-radius: 24px;
  animation: ${({ $isLight }) => ($isLight ? brightPulse : darkPulse)} 2s infinite;
`;
